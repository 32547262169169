import Api from "./index";

const ProductApi = {
  /**
   * Gets all the products
   * @returns
   */
  getProducts(params) {
    return Api.getPromise("/products", params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  },

  /**
   * Gets all popular products
   * @returns
   */
  getPopularProducts() {
    return Api.getPromise("/products/popular")
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  },

  /**
   * productName : 'dried-apricot'
   * Gets a product
   * @param {*} productName
   * @returns
   */
  getProduct(productName) {
    return Api.getPromise(`/products/${productName}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error while getting a product: " + err.message);
        return [];
      });
  },

  /**
   * Create product with the information given in param
   * params: { 'name' : 'Dried Apricot', 'category' : 'Dried Fruits' }
   * !! images should be given in an array, including their absolute, example:
   * image_url = [/Users/fft/Desktop/apricot.png, /Users/fft/Desktop/almond.png]
   * @param {*} params
   * @returns
   */
  createProduct(params) {
    return Api.postPromise(`/products`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error while creating a product: " + err.message);
        return null;
      });
  },

  /**
   *
   * params: id: id of the product
   * params: params: { 'id' : <required>, 'name' : <required if url is not provided>, 'url' : <required if name is not provided> }
   * In case a field is not updated, say category, still pass it as a parameter.
   * @param {*} params
   * @returns
   */
  updateProduct(id, params) {
    return Api.putPromise(`/products/${id}`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error while updating a product: " + err.message);
        return null;
      });
  },

  /**
   * params: id: id of the product
   * Deletes the product given with the id
   * @param {*} productName
   */
  deleteProduct(id) {
    return Api.deletePromise(`/products/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error while deleting a product: " + err);
        return null;
      });
  },
};

export default ProductApi;
