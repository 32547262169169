import "../../components/component-css/Features.css";
import photo from "../../assets/food-background-food-concept-with-various-tasty-fresh-ingredients-cooking-italian-food-ingredients-v.webp";
import photo3 from "../../assets/kisspng-silo-agriculture-drawing-barn-clip-art-5aeb65cf00c304.2120032015253764630031.png";

export default function Certificates() {
    return (
        <div id="features" className="features">
            <img className="bg-img" src={photo3} alt="fresh vegetables"></img>
            <div className="row" style={{ marginRight: 0, marginLeft: 0 }}>
                <div
                    className="col-10 "
                    data-aos="fade-up"
                    style={{ margin: "auto" }}
                >
                    <div
                        className="row"
                        style={{ marginRight: 0, marginLeft: 0 }}
                    >
                        <div className="image col-lg-7" data-aos="fade-right">
                            <img
                                src={photo}
                                alt="about foodversy"
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                }}
                            ></img>
                        </div>
                        <div
                            className="col-lg-5"
                            data-aos="fade-left"
                            data-aos-delay="100"
                        >
                            <div
                                className="icon-box mt-5 mt-lg-0"
                                data-aos="zoom-in"
                                data-aos-delay="150"
                            >
                                <i className="bx bx-receipt"></i>
                                <h3>Secure Supply </h3>
                                <p>
                                    Reliable supply chain with pier to pier
                                    logistic solutions
                                </p>
                            </div>
                            <div
                                className="icon-box mt-5"
                                data-aos="zoom-in"
                                data-aos-delay="150"
                            >
                                <i className="bx bx-cube-alt"></i>
                                <h3>One Stop Shop</h3>
                                <p>
                                    Wide variety and high quality of Turkish
                                    food products with all required
                                    international certifications. Wide variety
                                    suplier partners
                                </p>
                            </div>
                            <div
                                className="icon-box mt-5"
                                data-aos="zoom-in"
                                data-aos-delay="150"
                            >
                                <i className="bx bx-images"></i>
                                <h3>Purchasing Power </h3>
                                <p>
                                    Use 27 years of experience with deep market
                                    knowledge and reputation
                                </p>
                            </div>
                            <div
                                className="icon-box mt-5"
                                data-aos="zoom-in"
                                data-aos-delay="150"
                            >
                                <i className="bx bx-shield"></i>
                                <h3>Guaranteed Response </h3>
                                <p>In 24Hrs with highly motivated team</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
