import Api from "./index";

const NewsApi = {
    /**
     * Gets all the news
     * @returns
     */
    getNews(params) {
        return Api.getPromise("/news", params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.error(err);
                return [];
            });
    },

    /**
     * Gets all the news
     * @returns
     */
    getNewsCarousel() {
        return Api.getPromise("/news/carousel")
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.error(err);
                return [];
            });
    },

    /**
     * title : 'dried-apricot'
     * Gets news by its title
     * @param {*} newsName
     * @returns
     */
    getNewsByTitle(title) {
        return Api.getPromise(`/news/${title}`, {})
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting a news: " + err.message);
                return [];
            });
    },

    /**
     * Create news with the information given in param
     * params: { 'title' : 'Dried Apricot',
     *           'img_url' : 'Dried Fruits',    // absolute path of the image
     *           'card_context' : "Something about news",
     *           'context' : "Details of news",
     *           'url' : "original url of news"
     *         }
     * @param {*} params
     * @returns
     */
    createNews(params) {
        return Api.postPromise(`/news`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while creating a news: " + err.message);
                return null;
            });
    },

    /**
     * Updates the news
     * params: { 'id': <id of the news in the database>, }
     * @param {*} params
     */
    updateNews(id, params) {
        return Api.putPromise(`/news/${id}`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while updating the news: " + err.message);
                return null;
            });
    },

    /**
     * params: { 'title' : 'Dried Apricot'}
     * Deletes the news given with the news title
     * @param {*} newsName
     */
    deleteNews(id) {
        return Api.deletePromise(`/news/${id}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while deleting a news: " + err.message);
                return null;
            });
    },
};

export default NewsApi;
