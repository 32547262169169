import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function RelatedProductSelector({
  products = [],
  selectedProductHandler,
  relatedProductsRef,
}) {
  // const [selectedProduct,setSelectedProduct] = useState();
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    relatedProductsRef.current.focus();
    populateProductList();
  }, [products.length]);

  const handleSelectedProduct = () => {
    selectedProductHandler(
      relatedProductsRef.current.select.state.focusedOption.value
    );
  };
  const populateProductList = () => {
    if (products !== undefined)
      products.map(function (product) {
        let temp = productList;
        temp.push({
          value: product,
          label: product.name.charAt(0).toUpperCase() + product.name.slice(1),
        });
        setProductList(temp);
      });
  };
  return (
    <Select
      options={productList}
      onChange={() => handleSelectedProduct()}
      ref={relatedProductsRef}
    ></Select>
  );
}
