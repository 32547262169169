import React, { useState, useEffect, useMemo, useCallback } from "react";

//styling and bootstrap components
import "./ModifyModal.css";
import {
    Form,
    Modal,
    ModalBody,
    Button,
    Row,
    Col,
    Container,
} from "react-bootstrap";

//components
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Select from "react-select";
//api
import ProductApi from "../../../api/productApi";
import CountrySelector from "../../CountrySelector";
import SupplierApi from "../../../api/supplierApi";
import countryList from "react-select-country-list";

/**
 *
 * @param {*} requires the state for modal
 * @param {*} requires the state for being toggled on or of

 * @returns
 */
export default function ModifySupplierModal({
    selectedSupplier,
    isModalOpen,
    toggleModal,
    handleSelectedSupplier,
    onSuccess,
}) {
    const [formData, setFormData] = useState({
        name: "",
        authorized_person: "",
        email_address: "",
        phone: "",
        country: "",
        products: [],
    });
    const [products, setProducts] = useState([]);
    const [id, setID] = useState();
    //useEffect
    useEffect(() => {
        initializeFormData();
    }, [selectedSupplier]);

    useEffect(() => {
        ProductApi.getProducts()
            .then((res) => handleProducts(res.data))
            .catch((err) => alert("An error occured: ", err));
    }, []);

    const closeModal = () => {
        toggleModal();
        clearFormData();
    };

    //handler
    const initializeFormData = useCallback(() => {
        let tempObj = { ...selectedSupplier };

        if (
            selectedSupplier !== undefined &&
            Object.keys(selectedSupplier).length > 0
        ) {
            setFormData(tempObj);
            setID(selectedSupplier._id);
        }
    }, [selectedSupplier]);

    //Maps products to fill react-select component
    const productsToOptions = useMemo(() => {
        if (products === undefined || products === [])
            //if state did not mount yet, do not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
        });
        return options;
    }, [products]);

    const updateSupplierInfo = async (e) => {
        
        if (
            formData._id !== undefined &&
            formData._id !== "" &&
            formData._id !== 0
        ) {
            await SupplierApi.updateSupplier(formData._id, formData)
                .then((res) => {
                    alert("Successfully updated the supplier");
                    if (formData.products.length > 0)
                        SupplierApi.updateSupplierProducts(formData._id, {
                            productIds: formData.products,
                        })
                            .then((res) => {
                                alert(
                                    "Successfully updated the products' supplier list"
                                );
                            })
                            .catch((err) => {
                                console.log(
                                    "An error occured during updating the products' supplier list: " +
                                        err
                                );
                            });
                })
                .catch((err) => {
                    console.log(
                        "An error occured during updating supplier: " + err
                    );
                });
        } else {
            await SupplierApi.createSupplier(formData)
                .then((res) => {
                    
                    alert("Successfully created the supplier");
                    if (formData.products.length > 0)
                        SupplierApi.updateSupplierProducts(res.data._id, {
                            productIds: formData.products,
                        })
                            .then((res) => {
                                alert(
                                    "Successfully updated the products' supplier list"
                                );
                            })
                            .catch((err) => {
                                console.log(
                                    "An error occured during updating the products' supplier list: " +
                                        err
                                );
                            });
                })
                .catch((err) => {
                    console.log(
                        "An error occured during updating supplier: " + err
                    );
                });
        }
        clearFormData();
        toggleModal();
        onSuccess();
    };

    const handleFormData = (field, value) => {
        setFormData((formData) => ({ ...formData, [field]: value }));
    };
    const handleProducts = (e) => {
        setProducts(e);
    };

    const handleSelectedProducts = (e) => {
        let temp = [];
        e.forEach((item) => {
            temp.push(item.value._id);
        });
        handleFormData("products", temp);
    };

    const clearFormData = () => {
        setFormData({});
        handleSelectedSupplier({});
    };
    return (
        <Modal
            show={isModalOpen}
            onHide={toggleModal}
            centered
            backdrop="static"
            dialogClassName="modify-modal-main"
        >
            <ModalHeader
                className="btn-wrapper"
                style={{ display: "flex", alignItems: "center" }}
            >
                {selectedSupplier ? "Modify Customer" : "Add Customer"}
                <Button
                    id="close-btn"
                    className="close-btn"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    X
                </Button>
            </ModalHeader>
            <ModalBody>
                <Container className="modify-modal-main-container">
                    <Form onSubmit={(e) => updateSupplierInfo(e)}>
                        <Row className={""}>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="normal"
                                        defaultValue={
                                            selectedSupplier.name
                                                ? selectedSupplier.name
                                                : ""
                                        }
                                        placeholder="Enter company name"
                                        onChange={(e) => {
                                            handleFormData(
                                                "name",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Authorized Personnel
                                    </Form.Label>
                                    <Form.Control
                                        defaultValue={
                                            selectedSupplier.authorized_person
                                        }
                                        type="normal"
                                        placeholder="Enter staff name"
                                        onChange={(e) => {
                                            handleFormData(
                                                "authorized_person",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        defaultValue={selectedSupplier.email}
                                        placeholder="Enter email"
                                        onChange={(e) => {
                                            handleFormData(
                                                "email",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        defaultValue={selectedSupplier.phone}
                                        type="normal"
                                        placeholder="Enter phone number"
                                        onChange={(e) => {
                                            handleFormData(
                                                "phone",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <CountrySelector
                                        defaultValue={
                                            selectedSupplier.country
                                                ? {
                                                      value: countryList().getValue(
                                                          selectedSupplier.country
                                                      ),
                                                      label: selectedSupplier.country,
                                                  }
                                                : ""
                                        }
                                        handleCountry={(e) => {
                                            handleFormData("country", e);
                                        }}
                                    ></CountrySelector>
                                    {/* <Form.Control
                                        defaultValue={selectedSupplier.country}
                                        type="normal"
                                        placeholder="Enter country name"
                                        onChange={(e) => {
                                            handleFormData(
                                                "country",
                                                e.target.value
                                            );
                                        }}
                                    /> */}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Products</Form.Label>
                                    <Select
                                        defaultValue={
                                            selectedSupplier.products
                                                ? productsToOptions.filter(
                                                      (x) => {
                                                          return selectedSupplier.products.includes(
                                                              x.value._id
                                                          );
                                                      }
                                                  )
                                                : ""
                                        }
                                        options={productsToOptions}
                                        isMulti
                                        onChange={(e) =>
                                            handleSelectedProducts(e)
                                        }
                                    ></Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                justifyContent: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <Button
                                id="admin-submit-button"
                                onClick={(e) => updateSupplierInfo(e)}
                            >
                                Add/Modify
                            </Button>
                        </Row>
                    </Form>
                </Container>
            </ModalBody>
        </Modal>
    );
}
