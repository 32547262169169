import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

import "./NumDisp.css";

export default function NumDisplayCard({ label, numVal }) {
    return (
        <div className="num-disp-wrapper">
            <div className="num-disp-card">
                <p className="num">{numVal}</p>
                <p className="label">{label}</p>
            </div>
        </div>
    );
}
