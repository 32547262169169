import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import createLink from "../../helpers/CreateLink";
import { Link } from "react-router-dom";

import "../component-css/NewsCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import { Row, Col } from "react-bootstrap";
import { scrollToElement } from "../../helpers/ScrollToTop";

function createCards(allNews) {
    let temp = [...allNews];
    if (Array.isArray(allNews))
        return (
            allNews &&
            temp.map(function (item) {
                return (
                    <a
                        href={createLink("/post/", item.title)}
                        className="col-lg-12 col-md-12 align-items-stretch mb-4"
                        data-aos="fade-right"
                        data-aos-delay="100"
                        key={item._id}
                        style={{ textDecoration: "none" }}
                    >
                        <div className="row news-icon-box">
                            <div className="news-image">
                                <span className="image-span">
                                    {item.image_url !== "" ? (
                                        <img
                                            src={item.image_url}
                                            alt={item.title}
                                            loading="lazy"
                                            width={"100%"}
                                            height={"100%"}
                                            style={{ objectFit: "cover" }}
                                        ></img>
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </div>
                            <div className="content-col col-lg-9">
                                <Row>
                                    <Col xs={12}>
                                        <h3>{item.title}</h3>
                                    </Col>
                                    <Col xs={12}>
                                        <p>{item.card_context}</p>
                                    </Col>
                                </Row>
                            </div>
                            <div
                                className="col-lg-1 arrow-right"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    size="4x"
                                ></FontAwesomeIcon>
                            </div>
                        </div>
                    </a>
                );
            })
        );
    else if (allNews !== undefined)
        return (
            <a
                href={createLink("/post/", allNews.title)}
                className="col-lg-4 col-md-6 align-items-stretch mb-4"
                data-aos="zoom-in"
                data-aos-delay="100"
                style={{ textDecoration: "none" }}
            >
                <div className="icon-box">
                    <div className="image">
                        <img
                            src={allNews.image_url}
                            alt={allNews.title}
                            width={"100%"}
                            height={"100%"}
                            style={{ objectFit: "cover" }}
                        ></img>
                    </div>
                    <h4>{allNews.title}</h4>
                    <p>{allNews.card_context}</p>
                </div>
            </a>
        );
    else return <div></div>;
}

export default function CreateNewsCards({ allNews, header, subHeader }) {
    const [page, setPage] = useState(0);
    const reversedNews = [...allNews].reverse();
    console.log(allNews);
    const totalPages =
        allNews.length % 5 === 0
            ? allNews.length / 5
            : Math.floor(allNews.length / 5) + 1;
    return (
        <section id="news-container" className="news-container">
            <div className="container">
                <div className="section-title">
                    <h2>{header}</h2>
                    <p>{subHeader}</p>
                </div>

                <div className="row" id={"news-main-row"}>
                    {createCards(reversedNews.slice(page * 5, page * 5 + 5))}
                </div>
                <div className="row arrow-row">
                    <button
                        className="arrow-btn btn-left-arrow"
                        onClick={() => {
                            setPage((prevPage) =>
                                prevPage > 0 ? prevPage - 1 : prevPage
                            );

                            scrollToElement("news-main-row");
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faAngleLeft}
                            color="white"
                        ></FontAwesomeIcon>
                    </button>
                    <p className="page-count">{page + 1 + "/" + totalPages}</p>
                    <button
                        className="arrow-btn btn-right-arrow"
                        onClick={() => {
                            setPage((prevPage) =>
                                prevPage < totalPages - 1
                                    ? prevPage + 1
                                    : prevPage
                            );
                            scrollToElement("news-main-row");
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faAngleRight}
                            color="white"
                        ></FontAwesomeIcon>
                    </button>
                </div>
            </div>
        </section>
    );
}
