import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./component-css/Post.css";
import { useParams, Link } from "react-router-dom";
import NewsApi from "../api/newsApi";
import "./component-css/Product.css";
import { Helmet } from "react-helmet-async";
import SearchProducts from "./Search/SearchProducts";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import ShareButtons from "../helpers/ShareButtons";
import Author from "./SubComponents/Author";
import HomeNews from "./HomeComponents/HomeNews";
import ImageContainer from "./SubComponents/ImageContainer";
import { getIPAddress } from "../helpers/GetIPAddress";
import ViewCountApi from "../api/viewCountApi";
import ViewCount from "./SubComponents/ViewCount";

import defImage from "../assets/food-background-food-concept-with-various-tasty-fresh-ingredients-cooking-italian-food-ingredients-v.webp";
import useQuery from "../hooks/useQuery";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Post(props) {
    const location = useLocation();
    // Get id from url
    const { title } = useParams();
    console.log(title);
    const [post, setPost] = useState({
        _id: "",
        image_url: "",
        title: title ? title : "",
        context: "",
        tags: [],
    });
    const [ipAddress, setIPAddress] = useState();
    const [viewCount, setViewCount] = useState(0);

    const handleIPAddress = () => {
        getIPAddress()
            .then((res) => setIPAddress(res))
            .catch((err) => {
                console.log("Error occured during fetching IP address: " + err);
            });
    };

    useEffect(() => {
        NewsApi.getNewsByTitle(title)
            .then((res) => {
                setPost(res.data);
            })
            .catch((err) => console.log("Error: ", err));

        handleIPAddress();
    }, [title]);

    useEffect(() => {
        if (post._id === "") return;
        ViewCountApi.updateNewsViewCount(post._id, {
            ip_address: ipAddress,
        });
    }, [ipAddress, post._id]);

    useEffect(() => {
        if (post._id === "") return;
        ViewCountApi.getNewsViewCount(post._id)
            .then((res) => {
                setViewCount(res.data.view_count);
            })
            .catch((err) => {
                console.log("Error while fetching view count: " + err);
            });
    }, [post._id]);

    return (
        <Container fluid>
            <ShareButtons
                url={window.location.href}
                shareImage={post?.image_url}
                shareMessageText={"I found this news on www.foodversy.com !"}
                shareTitle={post?.title}
            ></ShareButtons>
            <Helmet>
                <title>{post?.title}</title>
                <meta name="description" content={post?.context.trim(" ")} />
                <link
                    rel="canonical"
                    href={"https://www.foodversy.com" + location.pathname}
                ></link>
            </Helmet>
            <Container fluid>
                <div className="main-body">
                    <Row>
                        <Col lg={8} xl={8}>
                            <Row id="top-image-row">
                                <Col>
                                    <ImageContainer
                                        imgUrl={
                                            post.image_url
                                                ? post.image_url
                                                : defImage
                                        }
                                        alt={post.title}
                                        objectFit={"cover"}
                                        maxHeight={"500px"}
                                    ></ImageContainer>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} xl={4} className="author-col">
                            <Author
                                author_name={"Furkan Akkurt"}
                                author_title={"CTO"}
                                image_url={
                                    "https://res.cloudinary.com/da8slr0ic/image/upload/v1628504855/foodsfromturkey/h2jncyafjjq4fmmgrsfx.jpg"
                                }
                            ></Author>
                        </Col>
                    </Row>
                    <Row id="middle-row">
                        <Col lg={8} xl={8}></Col>
                        <Col lg={8} xl={8}>
                            <Row id="Body">
                                <ViewCount viewCount={viewCount}></ViewCount>
                                <h1 id="Title"> {post?.title}</h1>
                                <h2
                                    className={"subtitle"}
                                    style={{ fontWeight: "bold" }}
                                >
                                    {post?.card_context}
                                </h2>
                                <br></br>
                                <hp style={{ whiteSpace: "break-spaces" }}>
                                    {post?.context}
                                </hp>
                                <h5 id="ReferencesId">Source:</h5>
                                <a
                                    style={{ wordBreak: "break-all" }}
                                    href={post.source_url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {post?.source_url}
                                </a>
                            </Row>
                            <Row id="tags">
                                {post?.tags.map(function (tag) {
                                    return (
                                        <Link
                                            id="tag"
                                            key={`key_${tag}`}
                                            to={"/search/" + tag}
                                        >
                                            {tag}
                                        </Link>
                                    );
                                })}
                            </Row>
                            <Row id="Body">
                                <SearchProducts
                                    positiveTitle="Would You Be Interested In These Products?"
                                    queryString={post.tags[0]}
                                    displayNoResult={false}
                                    maxCardInRow={2}
                                ></SearchProducts>
                            </Row>
                        </Col>
                        <Col lg={4} xl={4} className="related-news-col">
                            <Row id="Related news">
                                <HomeNews title={"Recent News"}></HomeNews>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <FacebookShareButton
                            url={window.location.href}
                            quote={
                                "I'm sharing this news with you: " + post.title
                            }
                            hashtag={"#" + post.tags[0]}
                        ></FacebookShareButton>
                        <TwitterShareButton
                            url={window.location.href}
                            title={post.title}
                            related="@FoodFromTurkey"
                        ></TwitterShareButton>
                    </Row>
                </div>
            </Container>
        </Container>
    );
}
