import React, { useEffect, useRef, useState, useMemo } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    InputGroup,
    FormControl,
} from "react-bootstrap";
import "../../component-css/FileInput.css";
import "./MailControl.css";

import DataTableBase from "../../SubComponents/DataTable/DataTable";
import CustomerInfoApi from "../../../api/customerInfoApi";
import ExcelImportModal from "../Subcomponents/Excel Import Modal/ExcelImportModal";
import Toolbar from "../../SubComponents/DataTable/Toolbar";
import ModifyCustomerModal from "./ModifyModal";
import { ArrayHelper } from "../../../helpers/ArrayHelper";
import MailApi from "../../../api/mailApi";
import Select from "react-select";
import CountrySelector from "../../CountrySelector";
import useProducts from "../../../hooks/useProducts";
import useCategories from "../../../hooks/useCategories";
import MailCreator from "../Subcomponents/MailCreator/MailCreator";

//Create Column Model
const columns = [
    {
        name: "ID",
        selector: (row) => row._id,
        reorder: true,
        omit: true,
    },
    {
        selector: (row) => row.company_name,
        name: "Company Name",
        maxWidth: "350px",
        sortable: true,
        reorder: true,
    },
    {
        selector: (row) => row.email_address,
        maxWidth: "350px",
        name: "Mail Address",
        sortable: true,
        reorder: true,
    },
    {
        selector: (row) => row.name,
        name: "Staff Name",
        maxWidth: "500px",
        reorder: true,
    },
    {
        selector: (row) => (row.received_intro_mail ? "true" : "false"),
        name: "Has Intro Mail Received?",
        maxWidth: "400px",
        reorder: true,
    },
];

/**
 * A toolbar for the data table
 * requires filter data handler to pass values for filtering
 * @param {*} handleFilterData
 * @returns
 */
function ToolbarFilterRow({
    handleSelectedProduct,
    handleSelectedCategory,
    handleFilterData,
    productsToOptions,
    categoriesToOptions,
}) {
    return (
        <Container fluid>
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col xs={12}>Filter By:</Col>
                <Col lg={3} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Company Name"
                            id="company_name"
                            aria-label="Username"
                            onChange={(e) => {
                                handleFilterData({
                                    id: e.target.id,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </Col>
                <Col lg={3} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Email Address"
                            id="email_address"
                            aria-label="Email Address"
                            onChange={(e) => {
                                handleFilterData({
                                    id: e.target.id,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </Col>
                <Col lg={3} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Name"
                            id="name"
                            aria-label="Name"
                            onChange={(e) => {
                                handleFilterData({
                                    id: e.target.id,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </Col>
            </Row>
            <Row style={{ paddingTop: "1rem" }}>
                <Col xs={12} lg={6}>
                    <Select
                        options={categoriesToOptions}
                        placeholder="Category"
                        onChange={(e) => {
                            if (e === null || undefined)
                                handleSelectedCategory(undefined);
                            else handleSelectedCategory(e.value.name);
                        }}
                        isClearable={true}
                    ></Select>
                </Col>
                <Col xs={12} lg={6}>
                    <Select
                        options={productsToOptions}
                        placeholder="Product"
                        onChange={(e) => {
                            if (e === null) {
                                handleSelectedProduct(undefined);
                            } else if (e.value === undefined)
                                handleSelectedProduct(undefined);
                            else handleSelectedProduct(e.value._id);
                        }}
                        isClearable={true}
                    ></Select>
                </Col>
                <Col xs={12} lg={6}>
                    <CountrySelector
                        placeholder="Country"
                        handleCountry={(e) =>
                            handleFilterData({
                                id: "country",
                                value: e,
                            })
                        }
                        isClearable={true}
                    ></CountrySelector>
                </Col>
            </Row>
        </Container>
    );
}

export default function MailControl() {
    const [customers, setCustomers] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [filterData, setFilterData] = useState({});
    const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
    const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);
    const [isSendModalOpen, setIsSendModalOpen] = useState(false);
    const [excelFile, setExcelFile] = useState();
    const [pending, setPending] = React.useState(true);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const categories = useCategories();
    const products = useProducts(selectedCategory);

    useEffect(() => {
        //Fetch customer list info here
        getAllCustomers();
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (selectedCategory === undefined || selectedCategory === "")
                deleteFilterDataField("interested_products");
            else
                handleFilterData({
                    id: "interested_products",
                    value: products,
                });
        }, 100);
        return () => clearTimeout(timeout);
    }, [selectedCategory, products]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (selectedProduct !== undefined && selectedProduct !== "") {
                handleFilterData({
                    id: "interested_products",
                    value: selectedProduct,
                });
            } else if (
                selectedCategory !== undefined &&
                selectedCategory !== ""
            ) {
                handleFilterData({
                    id: "interested_products",
                    value: products,
                });
            } else deleteFilterDataField("interested_products");
        }, 100);

        return () => clearTimeout(timeout);
    }, [selectedProduct]);

    useEffect(() => {}, [selectedProduct]);

    useEffect(() => {
        handleGridData();
    }, [filterData, customers]);

    //fetch data
    const getAllCustomers = () => {
        CustomerInfoApi.getAllCustomerInfo()
            .then((res) => {
                handleCustomers(res.data);
            })
            .catch((err) => {
                alert(
                    "An error occured during getting the customer data: " + err
                );
            });
    };

    //Maps products to fill react-select component
    const productsToOptions = useMemo(() => {
        if (products === undefined || products.length === 0)
            //if state did not mount yet, do not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
        });
        return options;
    }, [products]);

    const categoriesToOptions = useMemo(() => {
        if (categories === undefined || categories.length === 0)
            //if state did not mount yet, do not continue
            return;
        let options = []; //array for react-select
        categories.map(function (category) {
            let lbl =
                category.name.charAt(0).toUpperCase() + category.name.slice(1);
            options = [...options, { value: category, label: lbl }];
        });
        return options;
    }, [categories]);

    //row Events for table
    const rowEvents = {
        //sets the selected customer to the double clicked row data
        //selecting customers by the checkbox returns an array
        //but double click returns only the clicked row object instead of array
        onDoubleClick: (row, event) => {
            handleSelectedCustomer(row);
            toggleModifyModal();
        },
    };

    //excel upload function
    const uploadExcel = () => {
        CustomerInfoApi.importCSV(excelFile)
            .then((res) => {
                getAllCustomers();
                alert(res.message);
            })
            .catch((err) => {
                alert("An error occured during upload: " + err);
            });
    };

    /**
     * Sends an introduction mail to all selected customers in data table
     */
    const sendIntroMail = (recipientCode) => {
        MailApi.sendIntroMail(
            recipientCode
                ? {
                      customer_information_array: selectedCustomer,
                      recipient_code: recipientCode,
                  }
                : {
                      customer_information_array: selectedCustomer,
                      recipient_code: 0,
                  }
        )
            .then((res) => {
                alert(res.message);
            })
            .catch((err) => {
                alert("An error occured during sending the mail: " + err);
            });
    };

    //handlers
    /**
     * send a value object to add to filter data
     * @param {*} newValue
     */
    const handleFilterData = (newValue) => {
        setFilterData({ ...filterData, [newValue.id]: newValue.value });
    };

    const handleGridData = () => {
        const timeout = setTimeout(() => {
            setGridData(ArrayHelper.filterObjectArray(customers, filterData));
            setPending(false);
        }, 100);
        return () => clearTimeout(timeout);
    };
    const deleteFilterDataField = (fieldName) => {
        let temp = filterData;
        delete temp[fieldName];
        setFilterData({ ...temp });
    };

    const handleSelectedCategory = (e) => {
        setSelectedCategory(e);
    };

    const handleSelectedProduct = (e) => {
        setSelectedProduct(e);
    };

    const handleCustomers = (e) => {
        setCustomers(e);
    };

    const onSelectedRowsChange = (e) => {
        handleSelectedCustomer(e.selectedRows);
    };
    const handleSelectedCustomer = (e) => {
        setSelectedCustomer(e);
    };
    const handleAddCustomer = () => {
        toggleModifyModal();
    };
    const handleDeleteCustomer = () => {
        try {
            deleteCustomers();
        } catch (err) {
            console.log("Error occured during delete event: " + err);
        }
    };

    const deleteCustomers = async () => {
        if (
            Array.isArray(selectedCustomer) &&
            selectedCustomer !== undefined &&
            selectedCustomer.length !== 0
        ) {
            if (
                window.confirm(
                    `Are you sure about deleting ${selectedCustomer.length} records?`
                )
            ) {
                await selectedCustomer.forEach((item) => {
                    CustomerInfoApi.deleteCustomer(item._id)
                        .then((res) => {})
                        .catch((err) => {
                            "Error occured during delete operation";
                        });
                });
                getAllCustomers();
                alert("Selected customers are deleted successfully");
                handleSelectedCustomer({});
            }
        } else if (
            selectedCustomer !== undefined &&
            Object.keys(selectedCustomer).length !== 0
        ) {
            await CustomerInfoApi.deleteCustomer(selectedCustomer._id)
                .then((res) => {
                    getAllCustomers();
                })
                .catch((err) => {
                    "Error occured during delete operation";
                });

            getAllCustomers();
            alert("Selected customers are deleted successfully");
            handleSelectedCustomer({});
        } else {
            alert("No customer selected");
            return;
        }
    };
    const toggleExcelModal = () => {
        isExcelModalOpen === true
            ? setIsExcelModalOpen(false)
            : setIsExcelModalOpen(true);
    };

    const toggleMailModal = () => {
        isSendModalOpen === true
            ? setIsSendModalOpen(false)
            : setIsSendModalOpen(true);
    };

    /**
     * Opens and closes the modify modal
     */
    const toggleModifyModal = () => {
        isModifyModalOpen === true
            ? setIsModifyModalOpen(false)
            : setIsModifyModalOpen(true);
    };
    const handleExcelInput = (e) => {
        setExcelFile(e);
    };
    return (
        <>
            <ExcelImportModal
                excelFile={excelFile}
                isModalOpen={isExcelModalOpen}
                toggleModal={toggleExcelModal}
                handleExcelInput={handleExcelInput}
                uploadExcel={uploadExcel}
            ></ExcelImportModal>
            <ModifyCustomerModal
                selectedCustomer={selectedCustomer}
                isModalOpen={isModifyModalOpen}
                toggleModal={toggleModifyModal}
                handleSelectedCustomer={handleSelectedCustomer}
                onSuccess={getAllCustomers}
            ></ModifyCustomerModal>
            <Container fluid style={{ padding: "20px" }}>
                <Row>
                    <Col xs={12}>
                        <h3>Send Introduction Mail</h3>
                    </Col>

                    <Col xs={12}>
                        <DataTableBase
                            toolbar={
                                <Toolbar
                                    title="Mail List"
                                    handleAdd={handleAddCustomer}
                                    handleDelete={handleDeleteCustomer}
                                    filterRowElements={
                                        <ToolbarFilterRow
                                            handleSelectedCategory={
                                                handleSelectedCategory
                                            }
                                            handleFilterData={handleFilterData}
                                            handleSelectedProduct={
                                                handleSelectedProduct
                                            }
                                            deleteFilterDataField={
                                                deleteFilterDataField
                                            }
                                            productsToOptions={
                                                productsToOptions
                                            }
                                            categoriesToOptions={
                                                categoriesToOptions
                                            }
                                        />
                                    }
                                ></Toolbar>
                            }
                            columns={columns}
                            data={gridData}
                            header="Mail List"
                            selectableRows
                            onSelectedRowsChange={onSelectedRowsChange}
                            onRowDoubleClicked={rowEvents.onDoubleClick}
                            progressPending={pending}
                        ></DataTableBase>
                    </Col>
                    <Col xs={12}>
                        <Row
                            style={{
                                flexDirection: "row",
                                justifyContent: "center",
                            }}
                        >
                            <Col xs={12} md={4}>
                                <Button
                                    id="admin-submit-button"
                                    className={
                                        window.innerWidth > 400
                                            ? "ml-auto"
                                            : "mr-auto ml-auto"
                                    }
                                    style={{
                                        display: "flex",
                                        marginTop: "2rem",
                                    }}
                                    onClick={() => {
                                        toggleMailModal();
                                    }}
                                >
                                    Send Mail
                                </Button>
                            </Col>

                            <Col xs={12} md={4}>
                                <Button
                                    className={
                                        window.innerWidth > 400
                                            ? "mr-auto"
                                            : "mr-auto ml-auto"
                                    }
                                    id="admin-submit-button"
                                    style={{
                                        display: "flex",
                                        marginTop: "2rem",
                                    }}
                                    onClick={toggleExcelModal}
                                >
                                    Import Mail Addresses
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <MailCreator
                            toggleModal={toggleMailModal}
                            open={isSendModalOpen}
                            selectedCustomer={selectedCustomer}
                            handleSelectedCustomer={handleCustomers}
                        ></MailCreator>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
