import Api from './index';

const ParentCategoryApi = {

    /**
     * Returns all the parent categories
     * @returns 
     */
    getParentCategories(params) {
        return Api.getPromise(`/parent-categories`, params).then(res => {
            return res;
        }).catch(err => {
            console.log('Error while getting categories by parent category: ' + err);
            return [];
        })
    },

    /**
     * Returns the subcategories whose parent category is given in params
     * Param should be given as url, words seperated by hyphen 
     * @param {*} parentCategoryUrl e.g.: 'special', or 'example-parent'
     * @returns 
     */
    getCategoryByParentCategory(parentCategoryUrl) {
        return Api.getPromise(`/parent-categories/${parentCategoryUrl}`).then(res => {
            return res;
        }).catch(err => {
            console.log('Error while getting categories by parent category: ' + err);
            return [];
        })
    },

    /**
     * Creates a parent in the database
     * @param {*} params { parentCategoryName }
     * @returns 
     */
    createParentCategory(parentCategoryName) {
        const params = {
            name : parentCategoryName,
        };
        return Api.postPromise(`/parent-categories`, params).then(res => {
            return res;
        }).catch(err => {
            console.log('Error while creating a parent category: ' + err.message);
            return [];
        });
    },
}

export default ParentCategoryApi;