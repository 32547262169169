import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "../component-css/Post.css";
import { useParams, Link } from "react-router-dom";
import NewsApi from "../../api/newsApi";
import "../component-css/Author.css";
import { faAlignCenter } from "@fortawesome/free-solid-svg-icons";

export default function Author({ author_name, image_url, author_title }) {
    return (
        <Row
            className="author-main-row"
            style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: "0.5rem",
            }}
        >
            <Col
                className="img-box"
                xs={6}
                lg={12}
                style={{ objectFit: "cover" }}
            >
                <img
                    className="image"
                    src={image_url}
                    alt="news-author"
                    style={{ borderRadius: "100%" }}
                    width={"80%"}
                ></img>
            </Col>
            <Col className="title-col" xs={6} lg={12}>
                <div className="title-row">
                    <p className="editor">Editor:</p>
                </div>
                <div className="title-row">
                    <p className="author_name">{author_name}</p>
                </div>
                <div className="title-row">
                    <p className="author_title">{author_title}</p>
                </div>
            </Col>
        </Row>
    );
}
