import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Children, useEffect, useState } from "react";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import { GenerateID } from "../../helpers/GenerateID";
import "./Panel.css";
export default function Panel({
    id = GenerateID.randId(),
    title,
    className,
    divider = false,
    collapsible = true,
    disabled,
    isOpen = true,
    ...others
}) {
    const [open, setOpen] = useState(collapsible ? isOpen : true);

    useEffect(() => {
        collapsible ? setOpen(isOpen) : setOpen(true);
    }, [isOpen, collapsible]);

    const collapsePanel = () => {
        setOpen(open ? false : true);
        const collapseIcon = document.getElementById(id + "collapse-icon");

        if (collapseIcon && collapseIcon.classList.contains("angle-up")) {
            collapseIcon.classList.remove("angle-up");
            collapseIcon.classList.add("angle-down");
        } else {
            collapseIcon.classList.remove("angle-down");
            collapseIcon.classList.add("angle-up");
        }
    };
    return (
        <div
            className={"panel-wrapper" + (disabled ? " disabled" : "")}
            hidden={others.hidden}
        >
            <div id={id} className={"panel " + (className ? className : "")}>
                <Row>
                    <Row className="panel-title-row">
                        <Col xs={10} className="panel-title-col">
                            <h4>{title}</h4>
                        </Col>
                        {collapsible ? (
                            <Col xs={2} className="panel-title-col">
                                <FontAwesomeIcon
                                    id={id + "collapse-icon"}
                                    className={
                                        "icon " +
                                        (open ? "angle-up" : "angle-down")
                                    }
                                    icon={faAngleDown}
                                    onClick={() => {
                                        collapsePanel();
                                    }}
                                ></FontAwesomeIcon>
                            </Col>
                        ) : (
                            ""
                        )}
                    </Row>
                </Row>
                <Collapse in={open} timeout={2000}>
                    <Row id={id + "-panel-body"}>{others.children}</Row>
                </Collapse>
            </div>
        </div>
    );
}
