import React, { useState, useEffect, useMemo } from "react";

//Components
import DataTableBase from "../../SubComponents/DataTable/DataTable";
import ModifySupplierModal from "./ModifySupplierModal";
import Toolbar from "../../SubComponents/DataTable/Toolbar";
import Select from "react-select";

//styling
import { Col, Row, Container, InputGroup, FormControl } from "react-bootstrap";

//helpers
import { ArrayHelper } from "../../../helpers/ArrayHelper";
import SupplierApi from "../../../api/supplierApi";

//
import ProductApi from "../../../api/productApi";
import { Delete } from "@material-ui/icons";

const columns = [
    {
        name: "Supplier ID",
        fieldName: "_id",
        selector: (row) => row._id,
        omit: true,
    },
    {
        name: "Supplier Company Name",
        fieldName: "name",
        selector: (row) => row.name,
    },
    {
        name: "Authorized Person",
        fieldName: "authorized_person",
        selector: (row) => row.authorized_person,
    },
    {
        name: "Email Address",
        fieldName: "email",
        selector: (row) => row.email,
    },
    {
        name: "Supplier Phone No",
        fieldName: "phone",
        selector: (row) => row.phone,
    },
    {
        name: "Country",
        fieldName: "country",
        selector: (row) => row.country,
    },
];

/**
 * A toolbar for the data table
 * requires filter data handler to pass values for filtering
 * @param {*} handleFilterData
 * @returns
 */
function ToolbarFilterRow({
    handleFilterData,
    productsToOptions,
    deleteFilterDataField,
}) {
    return (
        <Container fluid>
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col xs={12}>Filter By:</Col>
                <Col lg={3} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Company Name"
                            id="name"
                            aria-label="Username"
                            onChange={(e) => {
                                handleFilterData({
                                    id: e.target.id,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </Col>
                <Col lg={3} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Email Address"
                            id="email"
                            aria-label="Email Address"
                            onChange={(e) => {
                                handleFilterData({
                                    id: e.target.id,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </Col>

                <Col xs={12} lg={6}></Col>
                <Col xs={12} lg={6}>
                    <Select
                        options={productsToOptions}
                        placeholder="Product"
                        onChange={(e) => {
                            if (e === null) deleteFilterDataField("products");
                            else if (e.value === undefined)
                                deleteFilterDataField("products");
                            else
                                handleFilterData({
                                    id: "products",
                                    value: e.value._id,
                                });
                        }}
                        isClearable={true}
                    ></Select>
                </Col>
            </Row>
        </Container>
    );
}

export default function SupplierPanel() {
    const [initialData, setInitialData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({
        authorized_person: "",
        name: "",
        phone: "",
        country: "",
        email: "",
    });
    const [filterData, setFilterData] = useState({});
    const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
    const [pending, setPending] = React.useState(true);
    const [products, setProducts] = useState([]);
    const [id, setID] = useState();

    useEffect(() => {
        getInitialData();
        ProductApi.getProducts()
            .then((res) => handleProducts(res.data))
            .catch((err) => alert("An error occured: ", err));
    }, []);

    const handleProducts = (e) => {
        setProducts(e);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setGridData(ArrayHelper.filterObjectArray(initialData, filterData));
            setPending(false);
        }, 100);
        return () => clearTimeout(timeout);
    }, [filterData, initialData]);

    const getInitialData = () => {
        SupplierApi.getAllSuppliers()
            .then((res) => {
                setInitialData(res.data);
                console.log(res.data[0]);
            })
            .catch((err) => {
                alert("An error occured during fetching supplier data: " + err);
            });
    };

    //Maps products to fill react-select component
    const productsToOptions = useMemo(() => {
        if (products === undefined || products === [])
            //if state did not mount yet, do not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
        });
        return options;
    }, [products]);

    //row events for table
    const rowEvents = {
        //sets the selected supplier to the double clicked row data
        //selecting supplier by the checkbox returns an array
        //but double click returns only the clicked row object instead of array
        onDoubleClick: (row, event) => {
            handleSelectedSupplier(row);
            toggleModifyModal();
        },
    };

    //handlers
    /**
     * send a value object to add to filter data
     * @param {*} newValue
     */
    const handleFilterData = (newValue) => {
        setFilterData({ ...filterData, [newValue.id]: newValue.value });
    };

    /**
     * Opens and closes the modify modal
     */
    const toggleModifyModal = () => {
        isModifyModalOpen === true
            ? setIsModifyModalOpen(false)
            : setIsModifyModalOpen(true);
    };

    const onSelectedRowsChange = (e) => {
        handleSelectedSupplier(e.selectedRows);
    };
    const handleSelectedSupplier = (e) => {
        setSelectedSupplier(e);
    };

    const deleteFilterDataField = (fieldName) => {
        let temp = filterData;
        delete temp[fieldName];
        setFilterData({ ...temp });
    };

    const deleteAll = () => {
        let flag = true;
        selectedSupplier.forEach((item) => {
            if (flag === false) return;
            SupplierApi.deleteSupplier(item._id)
                .then(() => {
                    flag = true;
                })
                .catch((err) => {
                    flag = false;
                    alert("An error happened during deleting: " + err);
                });
        });
        return flag;
    };

    const deleteSelectedSuppliers = async () => {
        if (selectedSupplier[0] === undefined) {
            alert("No supplier selected");
            return;
        }

        const isSuccessful = await deleteAll();
        if (isSuccessful) {
            alert("Successfully deleted all selected suppliers");
            getInitialData();
        }
    };

    return (
        <>
            <ModifySupplierModal
                selectedSupplier={selectedSupplier}
                isModalOpen={isModifyModalOpen}
                toggleModal={toggleModifyModal}
                handleSelectedSupplier={handleSelectedSupplier}
                onSuccess={getInitialData}
            ></ModifySupplierModal>
            <Container fluid>
                <Row>
                    <Col>
                        <DataTableBase
                            toolbar={
                                <Toolbar
                                    title={"Supplier List"}
                                    handleAdd={toggleModifyModal}
                                    handleDelete={deleteSelectedSuppliers}
                                    filterRowElements={
                                        <ToolbarFilterRow
                                            handleFilterData={handleFilterData}
                                            deleteFilterDataField={
                                                deleteFilterDataField
                                            }
                                            productsToOptions={
                                                productsToOptions
                                            }
                                        ></ToolbarFilterRow>
                                    }
                                ></Toolbar>
                            }
                            columns={columns}
                            selectableRows={true}
                            data={gridData}
                            onSelectedRowsChange={onSelectedRowsChange}
                            onRowDoubleClicked={rowEvents.onDoubleClick}
                            progressPending={pending}
                        ></DataTableBase>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
