import React, { useEffect, useState, useRef } from "react";
import "../component-css/Request.css";
// import CountrySelector from './CountrySelector';

import { Form, Container, Col, Button, Spinner } from "react-bootstrap";
// import { addAbortSignal } from 'stream';

import NewsApi from "../../api/newsApi";
import Select from "react-select";

export default function DeleteNews() {
    //States
    // const [checked, setChecked] =  useState(1)
    const [news, setnews] = useState();

    //Refs
    const titleRef = useRef();

    //useEffect
    useEffect(() => {
        NewsApi.getNews()
            .then((res) => setnews(res.data))
            .catch((err) => console.log("Error: ", err));
        titleRef.current.focus();
    }, []);

    //Maps news to fill react-select component
    const newsToOptions = () => {
        if (news === undefined)
            //if state does not mounted yet, does not continue
            return;
        let options = []; //array for react-select
        news.map(function (post) {
            let lbl = post.title.charAt(0).toUpperCase() + post.title.slice(1);
            options = [...options, { value: post, label: lbl }];
            return true;
        });
        return options;
    };
    if (!news) <Spinner />;
    //delte post from database
    const deletepost = () => {
        let data = {
            id: titleRef.current.state.value.value._id,
        };

        NewsApi.deleteNews(data.id)
            .then((res) => {
                alert("News deleted!");
                window.location.reload();
            })
            .catch((err) => console.log("ERROR: ", err));
    };

    const newselection = () => {
        return (
            <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Choose post</Form.Label>
                    <Select
                        options={newsToOptions()}
                        defaultValue={newsToOptions[0]}
                        ref={titleRef}
                    ></Select>
                </Form.Group>
            </Form.Row>
        );
    };
    return (
        <Container>
            <Form classtitle="req-form">
                {newselection()}
                <Button
                    id="admin-submit-button"
                    onClick={() => {
                        deletepost();
                    }}
                >
                    delete item
                </Button>
            </Form>
        </Container>
    );
}
