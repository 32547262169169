import React, { useState, useEffect } from "react";

import "../component-css/Home.css";
import "../component-css/News.css";
import "../component-css/SearchLanding.css";

import { Container, Row, Col } from "react-bootstrap";
import SearchLandingNews from "./SearchLandingNews";
import SearchProducts from "./SearchProducts";
import { Helmet } from "react-helmet-async";
import StringHelper from "../../helpers/StringHelper";
import { useParams } from "react-router-dom";
import Jumbotron from "../SubComponents/Jumbotron";
export default function SearchLanding({
    selectedProductHandler,
    selectedNewsHandler,
}) {
    const queryString = useParams();
    return (
        <Container fluid>
            <Helmet>
                <title>
                    Search for{" "}
                    {StringHelper.upperCharacter(queryString.query, 0)}
                </title>
            </Helmet>
            <Row className="justify-content-md-center">
                <Col style={{ padding: "0" }}>
                    <Jumbotron
                        header="Find your needs easily"
                        subheader={"We brougth the best results for you"}
                        content="With using search you can find the products or the news that we provide"
                        image_url={
                            "https://i.pinimg.com/736x/c8/ec/fd/c8ecfd2d5fac8e852172ff2c3f9f9bad.jpg"
                        }
                    ></Jumbotron>
                </Col>
            </Row>
            <Container className="search-res-container">
                <Row id="cards-row">
                    <SearchProducts
                        queryString={queryString.query}
                        selectedProductHandler={selectedProductHandler}
                        positiveTitle={"Products"}
                        negativeTitle={"Could not find any product"}
                    ></SearchProducts>
                </Row>
                <Row stlye={{ border: "2px", borderColor: "black" }}></Row>
                <Row id="cards-row">
                    <SearchLandingNews
                        queryString={queryString.query}
                        selectedNewsHandler={selectedNewsHandler}
                        positiveTitle={"News"}
                        negativeTitle={"Could not find any news"}
                    ></SearchLandingNews>
                </Row>
            </Container>
        </Container>
    );
}
