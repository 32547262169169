import React, { useState, useEffect, useRef } from "react";
import "./NewNavbar.css";
import logo from "../../assets/mock-logo.png";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Navbar({ searchBarOpener }) {
    const [menuStatus, setMenuStatus] = useState("closed");

    window.onresize = () => {
        if (window.innerWidth < 1336) {
            var navbar = document.querySelector(".link-col-container");
            navbar?.classList?.add("closed");
        }
    };
    // //useEffects
    // useEffect(() => {
    //     if (window.screen.width < 1336) {
    //         var navbar = document.querySelector(".link-col-container");
    //         navbar.classList.toggle("closed");
    //     }
    // }, []);

    const openSearchBar = () => {
        searchBarOpener(true);
    };

    const navbarRef = useRef();

    //#region  Event Listeners
    window.onload = function () {
        var navbar = document.querySelector(".link-col-container");
        navbar?.classList?.add("closed");
    };

    window.addEventListener("scroll", function () {
        var navbar = this.document.querySelector(".navbar-container");
        navbar?.classList?.toggle("sticky", this.window.scrollY > 0);
        var logo_image = this.document.querySelector(".image");
        logo_image?.classList?.toggle("small", this.window.scrollY > 0);
    });
    //#endregion

    //Hiding link buttons on navbar with changing class
    //The list is closed until menu button is clicked
    const openMenu = () => {
        if (menuStatus === "open") setMenuStatus("closed");
        else setMenuStatus("open");
    };

    return (
        <Container className="navbar-container" fluid>
            <div className="main-nav-row row" data-aos="fade-in">
                <Col xl={3} xs={10} className="logo-col">
                    <Link to="/home">
                        <img
                            className="image"
                            src={logo}
                            alt="Foodverys Logo"
                        ></img>
                    </Link>
                </Col>
                <Col
                    lg={1}
                    xl={1}
                    xs={2}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "auto",
                    }}
                >
                    <button
                        className="hamburger"
                        onClick={() => {
                            openMenu();
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faBars}
                            size="2x"
                        ></FontAwesomeIcon>
                    </button>
                </Col>
                <Col xs={12} className={"link-col-container " + menuStatus}>
                    <Row className="link-row">
                        <Col className="link-col">
                            <Link to="/all-products">
                                <h5>All Products</h5>
                            </Link>
                        </Col>
                        <Col
                            className="dropdown link-col"
                            style={{ color: "var(--fixed-primary)" }}
                        >
                            <h5 style={{ margin: 0 }}>Categories</h5>
                            <div className="dropdown-content">
                                <Link to="/main-categories/main">
                                    <p>Main Categories</p>
                                </Link>
                                <Link to="/main-categories/specials">
                                    <p>Special Categories</p>
                                </Link>
                                <Link to="/main-categories/retail">
                                    <p>Retail Categories</p>
                                </Link>
                                <Link to="/main-categories/organic">
                                    <p>Organic Categories</p>
                                </Link>
                                <Link to="/main-categories/machinery">
                                    <p>Machinery Categories</p>
                                </Link>
                                <Link to="/main-categories/pet">
                                    <p>Pet Food Categories</p>
                                </Link>
                                <Link to="/main-categories/agriculture">
                                    <p>Agriculture Categories</p>
                                </Link>
                            </div>
                        </Col>
                        <Col className="link-col">
                            {" "}
                            <Link to="/news">
                                <h5>News</h5>
                            </Link>
                        </Col>
                        <Col className="link-col">
                            {" "}
                            <Link to="/aboutus">
                                <h5>About</h5>
                            </Link>
                        </Col>
                        {/* <Col   className="link-col">
                            {" "}
                            <Link to="/aboutUs">
                                <h5>About Us</h5>
                            </Link>
                        </Col> */}

                        <Col className="search-col">
                            <Row className="link-row">
                                <Col>
                                    <Link
                                        to="#"
                                        onClick={() => {
                                            openSearchBar();
                                        }}
                                    >
                                        <h5>
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                size="lg"
                                            />
                                        </h5>
                                    </Link>
                                </Col>
                                <Col>
                                    <Link to="/supplier">
                                        <h5>
                                            <FontAwesomeIcon
                                                icon={faUser}
                                                size="lg"
                                            />
                                        </h5>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="link-col">
                            <Link to="/requestForm">
                                <h5>Request Quote</h5>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Container>
    );
}
