import React from "react";
import { useState } from "react";
import useCategories from "../../hooks/useCategories";
import { useMemo } from "react";
import DataTableBase from "../SubComponents/DataTable/DataTable";
import useProducts from "../../hooks/useProducts";
import Toolbar from "../SubComponents/DataTable/Toolbar";
import Select from "react-select";
import { useHistory } from "react-router-dom";

//style
import "./AllProductsTable.css";
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";

//helpers
import StringHelper from "../../helpers/StringHelper";
import { ArrayHelper } from "../../helpers/ArrayHelper";
import { useEffect } from "react";

const column_model = [
    {
        name: "ID",
        selector: (row) => row._id,
        reorder: true,
        omit: true,
    },
    {
        selector: (row) => row.name,
        name: "Product Name",
        maxWidth: "350px",
        sortable: true,
        reorder: true,
    },
    {
        selector: (row) => row.category,
        maxWidth: "350px",
        name: "Category",
        sortable: true,
        reorder: true,
    },
    {
        selector: (row) => row.status,
        maxWidth: "350px",
        name: "Status",
        sortable: true,
        reorder: true,
    },
];

/**
 * A toolbar for the data table
 * requires filter data handler to pass values for filtering
 * @param {*} handleFilterData
 * @returns
 */
function ToolbarFilterRow({ handleFilterData, productsToOptions }) {
    const categories = useCategories();
    const categoriesToOptions = useMemo(() => {
        if (categories === undefined || categories === [])
            //if state did not mount yet, do not continue
            return;
        let options = []; //array for react-select
        categories.map(function (category) {
            let lbl =
                category.name.charAt(0).toUpperCase() + category.name.slice(1);
            options = [...options, { value: category, label: lbl }];
        });
        return options;
    }, [categories]);

    return (
        <Container fluid style={{ paddingBlock: "1rem" }}>
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col xs={12}>Filter By:</Col>
                <Col lg={4} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Product"
                            id="name"
                            aria-label="name"
                            onChange={(e) => {
                                handleFilterData({
                                    id: e.target.id,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </Col>
                <Col lg={4} md={6}>
                    <InputGroup>
                        <Select
                            className="category-select"
                            options={categoriesToOptions}
                            placeholder="Category"
                            id="category"
                            defaultValue={undefined}
                            onChange={(e) => {
                                if (e === null || undefined)
                                    handleFilterData({
                                        id: "category",
                                        value: undefined,
                                    });
                                else
                                    handleFilterData({
                                        id: "category",
                                        value: e.value.name,
                                    });
                            }}
                            isClearable={true}
                        ></Select>
                    </InputGroup>
                </Col>
                <Col lg={4} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Status"
                            id="status"
                            aria-label="status"
                            onChange={(e) => {
                                handleFilterData({
                                    id: e.target.id,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    );
}

export default function ProductsTable() {
    const products = useProducts();

    const header = "Products List";
    const history = useHistory();
    const [pending, setPending] = useState(false);
    const [filterData, setFilterData] = useState();
    const [gridData, setGridData] = useState(products);

    useEffect(() => {
        filterProducts();
    }, [filterData, products]);

    const filterProducts = () => {
        setGridData(ArrayHelper.filterObjectArray(products, filterData));
    };

    /**
     * send a value object to add to filter data
     * @param {*} newValue
     */
    const handleFilterData = (newValue) => {
        if (newValue.value === undefined || newValue.value === "") {
            let temp = filterData;
            delete temp[newValue.id];
            setFilterData({ ...temp });
        } else setFilterData({ ...filterData, [newValue.id]: newValue.value });
    };

    const toolbar = () => {
        return (
            <Toolbar
                title={header}
                filterRowElements={
                    <ToolbarFilterRow
                        handleFilterData={handleFilterData}
                    ></ToolbarFilterRow>
                }
            ></Toolbar>
        );
    };

    const row_events = {
        onRowDoubleClicked: (row, event) => {
            history.push(StringHelper.createLink("/product/" + row.name));
        },
    };

    return (
        <>
            <Container>
                <DataTableBase
                    toolbar={toolbar()}
                    data={gridData}
                    columns={column_model}
                    onRowDoubleClicked={row_events.onRowDoubleClicked}
                    progressPending={pending}
                ></DataTableBase>
            </Container>
        </>
    );
}
