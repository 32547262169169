import { useState, useEffect, useRef } from "react";
import CategoryApi from "../api/categoryApi";
import ImageApi from "../api/imageApi";
import { Button, Container, Row, Col } from "react-bootstrap";
import Searchbar from "./Search/SearchBar";
import ImageContainer from "./SubComponents/ImageContainer";
import ProductsTable from "./AllProducts/AllProductsTable";
import "./component-css/AllProducts.css";
import { Helmet } from "react-helmet-async";
export default function AllProductsPage(props) {
    return (
        <>
            <Helmet>
                <title>
                    Explore Our Extensive Range of Turkish Foods - Foodversy
                </title>
                <meta
                    name="google-site-verification"
                    content="mnhwhxoX-fHyWoCiV5atQAo1yStpJ11i_njTMKp10hE"
                />
                <link
                    rel="canonical"
                    href={"https://www.foodversy.com/all-products"}
                ></link>
                <meta
                    name="description"
                    content="Discover and search through Foodversy's comprehensive selection of Turkish foods. Find the perfect ingredients and products for your business needs in our extensive catalog"
                />
            </Helmet>
            <Container fluid className="all-products-main-container">
                <div className="prod-table-wrapper">
                    <Row>
                        <Col className="text-col">
                            <Row
                                style={{
                                    paddingBlock: "2rem",
                                    color: "var(--fixed-text-white)",
                                }}
                            >
                                <Col xs={12}>
                                    <h1>
                                        Turkish Agricultural Goods: Wholesale
                                        Marketplace of Fine Products from
                                        Turkiye
                                    </h1>
                                </Col>
                                <Col xs={12}>
                                    <h2>
                                        You may see all the products we provide
                                        here
                                    </h2>
                                </Col>
                                <Col xs={12}>
                                    <p>
                                        Welcome to our wholesale marketplace,
                                        dedicated to showcasing an extensive
                                        selection of high-quality agricultural
                                        goods from Turkiye. This page serves as
                                        a hub for bulk buyers, distributors, and
                                        businesses seeking to source premium
                                        products directly from Turkiye's
                                        agricultural sector.
                                    </p>
                                </Col>
                                <Col xs={12}>
                                    <p>
                                        Explore our carefully curated table,
                                        featuring a diverse range of
                                        agricultural offerings cultivated in the
                                        fertile lands of Turkiye. From grains,
                                        pulses, and spices to fresh produce,
                                        dairy products, oils, and more, we have
                                        assembled a wide array of products to
                                        cater to the needs of wholesalers and
                                        retailers alike.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} xl={6}>
                            <ProductsTable></ProductsTable>;
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
}
