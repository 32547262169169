import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "./DataTable.css";
import { Button } from "react-bootstrap";
import { defaultTheme } from "./TableStyle";
import Toolbar from "./Toolbar";

// const columns = [
//   {
//     name: "Title",
//     selector: (row) => row.title,
//     sortable: true,
//   },
//   {
//     name: "Year",
//     selector: (row) => row.year,
//   },
// ];

// const dummyData = [
//   {
//     id: 1,
//     title: "Beetlejuice",
//     year: "1988",
//   },
//   {
//     id: 2,
//     title: "Ghostbusters",
//     year: "1984",
//   },
// ];
const paginationComponentOptions = {
    rowsPerPageText: "For each page",
    rangeSeparatorText: "per",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
};

export default function DataTableBase({
    toolbar,
    header = "New Table",
    className = "",
    columns,
    data,
    selectableRows = false,
    onSelectedRowsChange = () => {},
    onRowClicked,
    onRowDoubleClicked,
    pointerOnHover,
    progressPending,
    ...props
}) {
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {}, [selectedRows]);

    return (
        <div className="table-wrapper">
            {toolbar}
            <DataTable
                className={"data-table-main " + className}
                columns={columns}
                data={data}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="1000px"
                selectableRows={selectableRows}
                paginationComponentOptions={paginationComponentOptions}
                customStyles={defaultTheme}
                conditionalRowStyles={{}}
                highlightOnHover
                selectableRowsHighlight
                onSelectedRowsChange={onSelectedRowsChange}
                onRowClicked={onRowClicked}
                onRowDoubleClicked={onRowDoubleClicked}
                pointerOnHover={pointerOnHover}
                progressPending={progressPending}
                {...props}
            ></DataTable>
        </div>
    );
}
