import React, { useState, useMemo, useEffect } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
export default function CountrySelector({
    handleCountry,
    defaultValue,
    ...other
}) {
    const [value, setValue] = useState("");
    const options = useMemo(() => countryList().getData(), []);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const changeHandler = (value) => {
        setValue(value);
        if (value !== null) handleCountry(value.label);
        else handleCountry("");
    };

    return (
        <Select
            className="country-select-box"
            options={options}
            value={value}
            onChange={changeHandler}
            {...other}
        />
    );
}
