import Api from "./index";

const CategoryApi = {
    /**
     * Gets all the categories
     * @returns
     */
    getCategories(params) {
        return Api.getPromise(`/categories`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting categories: " + err);
                return [];
            });
    },

    /**
     * Gets the products whose category is given in params
     * Category is a subcategory, not parent
     * Url should be given in the form of lower case word(s), seperated by hyphen
     * @param {*} categoryUrl e.g.: 'dried-fruits'
     * @returns
     */
    getProductByCategory(categoryUrl) {
        return Api.getPromise(`/categories/${categoryUrl}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while getting products by categories: " + err
                );
                return [];
            });
    },

    getCategoryByTitle(title) {
        debugger;
        return Api.getPromise(`/category-by-title`, { name: title })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while getting products by categories: " + err
                );
                return [];
            });
    },

    /**
     * Creates category in the database
     * @param {*} params { 'name', 'parent_category' }
     * @returns
     */
    createCategory(params) {
        return Api.postPromise(`/categories`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while creating a category: " + err.message);
                return null;
            });
    },

    /**
     * Updates the category
     * params: id
     * params: { 'name', 'parentCategory' }
     * @param {*} params
     * @returns
     */
    updateCategory(id, params) {
        return Api.putPromise(`/categories/${id}`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while updating a category: " + err.message);
                return null;
            });
    },

    /**
     * Deletes the category from the database whose id is given as a param
     * @param {*} id
     * @returns
     */
    deleteCategory(id) {
        return Api.deletePromise(`/categories/${id}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while deleting a category: " + err.message);
                return null;
            });
    },
};

export default CategoryApi;
