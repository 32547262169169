import Api from "./index";

const ImageApi = {
    /**
     * Gets all the images
     * @returns
     */
    getImages() {
        return Api.getPromise(`/images`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting images: " + err);
                return [];
            });
    },

    /**
     * Gets the image whose url is given in params
     * Url should be given in the form of lower case word(s), seperated by hyphen
     * @param {*} imageUrl e.g.: 'dried-fruits'
     * @returns
     */
    getImage(imageUrl) {
        return Api.getPromise(`/images/${imageUrl}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting image: " + err.message);
                return [];
            });
    },

    /**
     * Upload image(s) to the database
     * @param {*} params { 'images': [{'name', 'absolute_path_or_public_url}, {}, {},...] }
     * @returns
     */
    uploadImage(params) {
        return Api.postPromise(`/images`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while uploading image(s): " + err.message);
                return null;
            });
    },
};

export default ImageApi;
