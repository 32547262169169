import axios from "axios";

//fetch the IP address using the given API and returns that
//pass state handler as a callback to update given state
export const getIPAddress = async () => {
	
  const getData = async () => {
    let ipAddress
    
    await axios.get(
      "https://api.ipify.org?format=json"
    ).then((response) => {
      ipAddress = response.data.ip;
    }).catch((error) => {
      console.error("Problem fetching my IP", error);
    });

    return ipAddress;
  };

  return getData();
}