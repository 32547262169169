import Api from "./index";

const CustomerInfoApi = {
    /**
     * Gets all the customers' info
     * @returns
     */
    getAllCustomerInfo(params) {
        return Api.getPromise(`/customers`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting customer infos: " + err);
                return [];
            });
    },

    /**
     * Gets the customer info with the given id
     * params: id
     * @param {*} params
     * @returns
     */
    getCustomerInfoById(id, params) {
        return Api.getPromise(`/customers/${id}`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while getting customer with id: " + err.message
                );
                return null;
            });
    },

    /**
     * Creates a new customer information
     * params: { name, email_address, company_name, is_subscribed }
     * @param {*} params
     * @returns
     */
    createCustomer(params) {
        return Api.postPromise(`/customers/`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while creating customer: " + err.message);
                return null;
            });
    },

    /**
     * Modify a customer information
     * id: id
     * params: { name, email_address, company_name, is_subscribed }
     * @param {*} id
     * @param {*} params
     * @returns
     */
    modifyCustomer(id, params) {
        return Api.putPromise(`/customers/${id}`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while modifying customer: " + err.message);
                return null;
            });
    },

    /**
     * Deletes the customer from the database whose id is given as a param
     * @param {*} id
     * @returns
     */
    deleteCustomer(id) {
        return Api.deletePromise(`/customers/${id}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while deleting a customer: " + err.message);
                return null;
            });
    },

    /**
     * Imports customer from the uploaded CSV file
     * @param {*} file
     * @returns
     */
    importCSV(file) {
        const formData = new FormData();
        formData.append("file", file[0]);
        return Api.postPromise(`/file/customerInformation/csv/`, formData)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while importing customers from CSV: " + err.message
                );
                return null;
            });
    },
};

export default CustomerInfoApi;
