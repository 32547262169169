import React, { useEffect, useState, useRef } from "react";
import "../component-css/Request.css";

import { Form, Container, Col, Button, Spinner } from "react-bootstrap";
// import { addAbortSignal } from 'stream';
// import CategoryApi from '../api/categoryApi';
import Select from "react-select";
import CategoryApi from "../../api/categoryApi";

export default function DeleteItem() {
    //States
    // const [checked, setChecked] =  useState(1)
    const [categories, setCategories] = useState();

    //Refs
    const nameRef = useRef();

    //useEffect
    useEffect(() => {
        CategoryApi.getCategories()
            .then((res) => setCategories(res.data))
            .catch((err) => console.log("Error: ", err));

        nameRef.current.focus();
    }, []);

    //Maps products to fill react-select component
    const categoriesToOptions = () => {
        if (categories === undefined)
            //if state does not mounted yet, does not continue
            return;
        let options = []; //array for react-select
        categories.map(function (category) {
            let lbl =
                category.name.charAt(0).toUpperCase() + category.name.slice(1);
            options = [...options, { value: category, label: lbl }];
            return true;
        });
        return options;
    };
    if (!categories) <Spinner />;
    //delte product from database
    const deleteProduct = () => {
        let data = {
            id: nameRef.current.state.value.value._id,
        };

        CategoryApi.deleteCategory(data)
            .then((res) => {
                if (res !== null) alert("Item is deleted");
            })
            .catch((err) => console.log("ERROR: ", err));
    };

    const productSelection = () => {
        return (
            <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Choose Category</Form.Label>
                    <Select
                        options={categoriesToOptions()}
                        defaultValue={categoriesToOptions[0]}
                        ref={nameRef}
                    ></Select>
                </Form.Group>
            </Form.Row>
        );
    };
    return (
        <Container>
            <Form className="req-form">
                {productSelection()}
                <Button
                    id="admin-submit-button"
                    onClick={() => {
                        deleteProduct();
                    }}
                >
                    delete item
                </Button>
            </Form>
        </Container>
    );
}
