import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Route, Switch,Link} from 'react-router-dom';
import "./component-css/NewsCard.css";
import NewsApi from "../api/newsApi";
import { Helmet } from "react-helmet-async";
import CreateNewsCards from "./Cards/CreateNewsCards";
import Jumbotron from "./SubComponents/Jumbotron";

export default function News({ selectedNewsHandler }) {
    const [news, setNews] = useState([]);

    const selectNews = (e) => {
        selectedNewsHandler(e);
    };

    useEffect(() => {
        NewsApi.getNews()
            .then((res) => {
                setNews(res.data);
            })
            .catch((err) => {
                console.log("Error: ", err);
            });
    }, []);

    if (news !== undefined)
        return (
            <div>
                <Helmet>
                    <title>
                        Latest Industry News & Insights - Foodversy's Blog
                    </title>
                    <meta
                        name="google-site-verification"
                        content="mnhwhxoX-fHyWoCiV5atQAo1yStpJ11i_njTMKp10hE"
                    />
                    <link
                        rel="canonical"
                        href={"https://www.foodversy.com/news"}
                    ></link>
                    <meta
                        name="description"
                        content="Stay updated with the latest trends in Turkish cuisine and the food trading industry. Read our insightful blog posts to stay ahead in the market with Foodversy."
                    />
                </Helmet>
                <Jumbotron
                    header={"News"}
                    subheader={"Latest news about Turkish and global market"}
                    content={`It is very difficult to trade in the ever-changing world conditions. 
          It is necessary to follow the news regularly. 
          Here is the latest market news we have chosen for you.
          `}
                    image_url={
                        "https://res.cloudinary.com/da8slr0ic/image/upload/v1630391688/foodsfromturkey/uc9nh5ijlswkt50ch0yh.jpg"
                    }
                ></Jumbotron>
                {news && news.length > 0 ? (
                    <CreateNewsCards allNews={[...news].reverse()} />
                ) : (
                    ""
                )}
            </div>
        );
    else return <div></div>;
}
