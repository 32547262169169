import React, { useEffect, useRef, useState } from "react";
import { Container, Form, Button, Col } from "react-bootstrap";
import NewsApi from "../../api/newsApi";
import ImageUpload from "../../helpers/PhotoUploader/PhotoUploader";
import TagCreator from "./Subcomponents/TagCreator/TagCreator";
import { clearTitle } from "../../helpers/CreateLink";
export default function AddNews() {
    //States
    const [validated, setValidated] = useState(false);
    const [imgList, setImageList] = useState([]);
    const [tags, setTags] = useState([]);

    //UseEffects
    useEffect(() => {
        titleRef.current.focus();
    }, []);

    useEffect(() => {
        if (imgList.length > 1) imgList.shift();
    }, [imgList.length]);

    //Refs
    const titleRef = useRef();
    const contextRef = useRef();
    const card_contextRef = useRef();
    const urlRef = useRef();
    const imgUrlRef = useRef();

    //Handlers
    const handleImageList = (e) => {
        setImageList(e);
    };

    const handleTags = (newTagsList) => {
        setTags(newTagsList);
    };

    //Sends news to the database by the input references
    //Does not continue when required fields are empty
    function addNews(event) {
        let data = {
            title: clearTitle(titleRef.current.value),
            context: contextRef.current.value,
            card_context: card_contextRef.current.value,
            source_url: urlRef.current.value,
            image_url: imgList[0],
            tags: tags,
        };

        debugger;
        //Validation step
        const form = event.currentTarget;
        console.log(form.checkValidity());
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(true);
            NewsApi.createNews(data)
                .then(async (res) => {
                    await alert("News is created");
                    window.location.reload();
                })
                .catch((err) => console.log("Error: ", +err));
        }
    }

    return (
        <Container>
            <Form validated={validated} className="news-form">
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridTitle">
                        <Form.Label>News Title</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Title"
                            ref={titleRef}
                        />
                        <Form.Control.Feedback>
                            Looks good!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridText">
                        <Form.Label>Card Content</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            rows={4}
                            placeholder="Text"
                            ref={card_contextRef}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridText">
                        <Form.Label>Content</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            rows={10}
                            placeholder="Text"
                            ref={contextRef}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridFile">
                        <ImageUpload
                            parentImageList={imgList}
                            setParentImageList={handleImageList}
                        ></ImageUpload>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    {/* <Form.Group as={Col} controlId="formGridTag">
            <Form.Label>Tags</Form.Label>
            <Form.Control
              placeholder="Please do not leave space after comma"
              ref={tagRef}
            />
          </Form.Group> */}
                    <TagCreator
                        handleTags={handleTags}
                        existingTags={tags}
                    ></TagCreator>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridURL">
                        <Form.Label>News Source URL</Form.Label>
                        <Form.Control
                            required
                            placeholder="News' Source URL"
                            ref={urlRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridTitle">
                        <Form.Label>News Image URL</Form.Label>
                        <Form.Control
                            disabled
                            required
                            placeholder=" Image URL"
                            ref={imgUrlRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Button id="admin-submit-button" onClick={addNews}>
                    Add Item
                </Button>
            </Form>
        </Container>
    );
}
