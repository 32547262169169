import React, { useEffect, useState, useRef } from "react";
import "../component-css/Request.css";
// import CountrySelector from './CountrySelector';

import { Form, Container, Col, Button, Spinner } from "react-bootstrap";
// import { addAbortSignal } from 'stream';
// import CategoryApi from '../api/categoryApi';
import ProductApi from "../../api/productApi";
import Select from "react-select";

export default function DeleteItem() {
    //States
    // const [checked, setChecked] =  useState(1)
    const [products, setProducts] = useState();
    const [selectedProduct, setSelectedProduct] = useState({});
    //Refs
    const nameRef = useRef();
    // const categoryRef = useRef();
    // const selectRef = useRef();
    // const radioRef = useRef();
    // const tagRef = useRef();
    // const relatedProductsRef = useRef();

    //useEffect
    useEffect(() => {
        ProductApi.getProducts()
            .then((res) => setProducts(res.data))
            .catch((err) => console.log("Error: ", err));

        nameRef.current.focus();
    }, []);

    //Maps products to fill react-select component
    const productsToOptions = () => {
        if (products === undefined)
            //if state does not mounted yet, does not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
            return true;
        });
        return options;
    };
    if (!products) <Spinner />;
    //delte product from database
    const deleteProduct = () => {
        let data = {
            id: selectedProduct.value._id,
        };

        ProductApi.deleteProduct(data.id)
            .then((res) => {
                if (res !== null) alert("Item is deleted");
                ProductApi.getProducts()
                    .then((res) => setProducts(res.data))
                    .catch((err) => console.log("Error: ", err));
                setSelectedProduct({});
            })
            .catch((err) => console.log("ERROR: ", err));
    };

    const productSelection = () => {
        return (
            <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Choose Product</Form.Label>
                    <Select
                        options={productsToOptions()}
                        defaultValue={productsToOptions[0]}
                        value={selectedProduct}
                        onChange={(e) => {
                            setSelectedProduct(e);
                        }}
                        ref={nameRef}
                    ></Select>
                </Form.Group>
            </Form.Row>
        );
    };
    return (
        <Container>
            <Form className="req-form">
                {productSelection()}
                <Button
                    id="admin-submit-button"
                    onClick={() => {
                        deleteProduct();
                    }}
                >
                    delete item
                </Button>
            </Form>
        </Container>
    );
}
