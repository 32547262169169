import React, { useEffect, useState } from "react";

import "../component-css/SearchProducts.css";
import ProductCards from "../ProductCards";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import Aos from "aos";
import SearchApi from "../../api/searchApi";
import SearchLandingNews from "./SearchLandingNews";
import { Helmet } from "react-helmet-async";
import Header from "../SubComponents/Header";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";

export default function SearchProducts({
    queryString,
    positiveTitle = "",
    negativeTitle = "",
}) {
    const [results, setResults] = useState([]);
    const params = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (queryString === undefined)
            SearchApi.searchProduct(params.query)
                .then((res) => {
                    setResults(res.data ? res.data : []);
                    setLoading(false);
                })
                .catch((err) => console.log("Error: ", err));
        else
            SearchApi.searchProduct(queryString)
                .then((res) => {
                    setResults(res.data ? res.data : []);
                    setLoading(false);
                })
                .catch((err) => console.log("Error: ", err));
    }, [queryString]);

    function bringResults() {
        if (loading)
            return (
                <span style={{ textAlign: "center" }}>
                    <Spinner animation="border" role="status" />
                    <h5>Loading</h5>
                </span>
            );
        else if (results.length > 0)
            return (
                <Row id="cards-row">
                    <Col>
                        <ProductCards
                            header={positiveTitle}
                            productList={results}
                        ></ProductCards>
                    </Col>
                </Row>
            );
        else if (results.length <= 0) {
            return (
                <Row id="cards-row">
                    <Col lg={12} xl={12}>
                        <Header
                            header={negativeTitle}
                            icon={negativeTitle !== "" ? faCannabis : ""}
                        ></Header>
                    </Col>
                    <Col>
                        <ProductCards productList={results}></ProductCards>
                    </Col>
                </Row>
            );
        }
    }
    return <Container id="Popular">{bringResults()}</Container>;
}
