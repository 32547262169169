import React, { useEffect, useState, useRef, useMemo } from "react";
import "../component-css/Request.css";
import CountrySelector from "../CountrySelector";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import {
    Form,
    Container,
    Col,
    Row,
    Button,
    Spinner,
    Modal,
    Alert,
    FormCheck,
} from "react-bootstrap";
import MailApi from "../../api/mailApi";
// import RequestApi from "../api/requestApi";
import ProductApi from "../../api/productApi";
import { useParams } from "react-router-dom";
import SupplierSub from "../../constants/SubscriptionTypes";
//import CustomerInfoApi from "../api/customerInfoApi";
// import { addAbortSignal } from 'stream';

const subscribtionMessage =
    "I would like to receive e-mails regarding to the news, products and notifications";

export default function SupplierForm({ ref }) {
    const subscriptionType = useParams().type;
    const [validated, setValidated] = useState(false);
    const [products, setProducts] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        authorizedPerson: "",
        email: "",
        phone: "",
        products: [],
        country: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [country, setCountry] = useState("");

    //Refs
    const messageRef = useRef();
    const emailRef = useRef();
    const nameRef = useRef();
    const phoneRef = useRef();
    const quantityRef = useRef();
    const noticationsCheckRef = useRef();

    //useEffect
    useEffect(() => {
        ProductApi.getProducts()
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) =>
                alert("An error happended during fetching products: " + err)
            );
        console.log(subscriptionType);
    }, []);

    useEffect(() => {}, [country, formData]);

    //handlers

    const handleFormData = (key, value) => {
        setFormData((formData) => ({ ...formData, [key]: value }));
    };

    const handleCountry = (value) => {
        setCountry(value);
        setFormData((formData) => ({ ...formData, country: value }));
    };

    const sendMail = async (event) => {
        setLoading(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(true);
        }
        event.preventDefault();
        try {
            await MailApi.sendSupplierMail(formData)
                .then((res) => {
                    setLoading(false);
                    alert(
                        "Application sent successfully, you may check your mailbox for confirmation mail"
                    );
                })
                .catch((err) => alert("Error occured: " + err));
        } catch {
            alert(
                "An error happended during sending the application, please try again"
            );
        }
    };

    //Maps products to fill react-select component
    //Create options again only when products change
    const options = useMemo(() => {
        if (products === undefined || products === [])
            //if state did not mount yet, do not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
        });
        return options;
    }, [products]);

    return (
        <Container ref={ref} className="req-container" data-aos="fade-up">
            <div className="row mt-4">
                <div className="col-lg-10">
                    <div className="tp-section-wrap">
                        <span>
                            <i className="flaticon-grass"></i>
                        </span>
                        <h3 className="tp-section-title">
                            Would you like to become a supplier? <br /> Sign up
                            now!
                        </h3>
                    </div>
                </div>
            </div>
            <Row>
                <Col className="req_col" lg={3} md={3}>
                    <div className="icon-box mt-5">
                        <h4>Supplier Pack:</h4>
                        <h3>{SupplierSub[parseInt(subscriptionType)].name} </h3>
                        <ul style={{ padding: "1rem" }}>
                            <li>
                                <p>Lorem ipsum dolor sit amet</p>
                            </li>
                            <li>
                                <p>Lorem ipsum dolor sit amet</p>
                            </li>
                            <li>
                                <p>Lorem ipsum dolor sit amet</p>
                            </li>
                            <li>
                                <p>Lorem ipsum dolor sit amet</p>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col className="form-col" lg={9} md={9}>
                    <Form
                        validation={validated}
                        onSubmit={sendMail}
                        className="req-form"
                    >
                        <Form.Row>
                            <Col lg={12} md={12}>
                                <Form.Group
                                    as={Col}
                                    controlId="formGridCompanyName"
                                >
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter company name"
                                        ref={nameRef}
                                        onChange={(e) => {
                                            handleFormData(
                                                "name",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Authorized Person </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter authorized person's name"
                                        onChange={(e) => {
                                            handleFormData(
                                                "authorizedPerson",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Phone</Form.Label>
                                    {/* <Form.Control  type="text" placeholder="Enter Phone (Optional)" ref = {phoneRef} /> */}
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        value={phoneNumber}
                                        onChange={(e) => {
                                            setPhoneNumber(e);
                                            handleFormData("phone", e);
                                        }}
                                        ref={phoneRef}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Enter email"
                                        ref={emailRef}
                                        onChange={(e) => {
                                            handleFormData(
                                                "email",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group
                                    as={Col}
                                    controlId="formGridCountry"
                                >
                                    <Form.Label>Country</Form.Label>
                                    <CountrySelector
                                        handleCountry={handleCountry}
                                    ></CountrySelector>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group
                                    as={Col}
                                    controlId="formProductSelect"
                                >
                                    <Form.Label>Choose Product</Form.Label>
                                    <Select
                                        isMulti
                                        //gives the selected option's value onChange
                                        onChange={(product) => {
                                            let temp = [];
                                            product.forEach((item) => {
                                                temp.push(item.value.name);
                                            });
                                            console.log(temp);
                                            handleFormData("products", temp);
                                        }}
                                        options={options}
                                    ></Select>
                                </Form.Group>
                            </Col>

                            <Col lg={12} md={12}>
                                <Form.Group
                                    as={Col}
                                    controlId="formBasicTextArea"
                                >
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        required
                                        as="textarea"
                                        placeholder="Please enter your request"
                                        rows={5}
                                        ref={messageRef}
                                        onChange={(e) => {
                                            handleFormData(
                                                "message",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col lg={12} md={12}>
                                <Form.Group
                                    as={Col}
                                    controlId="formNotificationCheck"
                                >
                                    <FormCheck
                                        label={subscribtionMessage}
                                        ref={noticationsCheckRef}
                                    ></FormCheck>
                                </Form.Group>
                            </Col> */}
                        </Form.Row>

                        <Button id="submit-button" type="submit">
                            {loading ? (
                                <span>
                                    <h4>Pending Request</h4>{" "}
                                    <Spinner
                                        animation="border"
                                        role="status"
                                    ></Spinner>
                                </span>
                            ) : (
                                "Send Supplier Application"
                            )}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}
