import React, { useRef, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import UserApi from "../../api/userApi";
// import './component-css/AdminLogin'

export default function AdminLogin({ authHandler }) {
    //States
    const [tokenValid, setTokenValid] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        let data = {
            email: emailRef.current.value,
            password: passwordRef.current.value,
        };

        UserApi.loginUser(data)
            .then((res) => {
                if (res.token) {
                    localStorage.setItem("token", res.token);
                    setTokenValid(true);

                    // Reload the page
                    window.location.reload();
                } else {
                    alert(res.message);
                }
            })
            .catch((err) => {
                console.log("Error while logging in: ", err);
            });
    };
    //Refs
    const emailRef = useRef();
    const passwordRef = useRef();

    // const handleLogin = (e,token) => {
    //    if(e)
    //      localStorage.setItem('token',token);
    // }

    return (
        <Form onSubmit={handleSubmit}>
            <h3>Sign In</h3>

            <Form.Row className="form-group">
                <Form.Label>Username</Form.Label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Enter username"
                    ref={emailRef}
                />
            </Form.Row>

            <Form.Row className="form-group">
                <Form.Label>Password</Form.Label>
                <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    ref={passwordRef}
                />
            </Form.Row>
            <Button type="submit" className="btn btn-primary btn-block">
                {!tokenValid ? (
                    "Login"
                ) : (
                    <span>
                        <h4>Logging in...</h4>{" "}
                        <Spinner animation="border" role="status"></Spinner>
                    </span>
                )}
            </Button>
            <p className="forgot-password text-center">
                {/* <a href="#">   Forgot password?</a> */}
            </p>
        </Form>
    );
}
