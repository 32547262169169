import "../../components/component-css/Certificates.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import Header from "../SubComponents/Header";
export default function Certificates() {
    const certificates = [
        {
            name: "Quality",
            logo: "",
            c1: "ISO",
            c2: "BRC",
            c3: "IFS",
            c4: "FSSC",
        },
        {
            name: "Status",
            logo: "",
            c1: "Organic (bio)",
            c2: "Halal",
            c3: "Kosher",
            c4: "Vegan",
        },
        {
            name: "Sustainability",
            logo: "",
            c1: "UTZ",
            c2: "Global Gap",
            c3: "FSA",
            c4: "Fair Wild",
        },
    ];
    const generateCards = (cardItems) => {
        if (Array.isArray(cardItems)) {
            return cardItems.map(function (item, ind) {
                return (
                    <div
                        key={"certificate-" + ind}
                        className="col-lg-4 col-md-6 mb-2 align-items-stretch"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                    >
                        <div className="icon-box">
                            <div className="icon">
                                <i>
                                    <FontAwesomeIcon icon={faCertificate} />
                                </i>
                            </div>
                            <h4>{item.name}</h4>
                            <p>{item.c1}</p>
                            <p>{item.c2}</p>
                            <p>{item.c3}</p>
                            <p>{item.c4}</p>
                        </div>
                    </div>
                );
            });
        }
    };

    return (
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">
                <Header
                    header="Certificates"
                    icon={faCannabis}
                    subheader="Certificates should be taken into account when it comes to food
            import and export. Check out our certificates."
                ></Header>
                <div className="row">{generateCards(certificates)}</div>
            </div>
        </section>
    );
}
