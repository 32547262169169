import React, { useRef, useState, useEffect } from "react";

//import bootstrap components
import { InputGroup, Button, Form, Row } from "react-bootstrap";

//import custom styling
import "./TagCreator.css";

/**
 * Adds tags to tag list and sends them to parent
 * requires handler to mutate parent state for tags
 * @returns
 */
export default function TagCreator({ handleTags, existingTags = [] }) {
    const [tags, setTags] = useState(existingTags);

    useEffect(() => {
        handleTags(tags);
    }, [tags.length]);

    useEffect(() => {
        setTags(existingTags);
    }, [existingTags]);
    //Ref for input value
    const tagRef = useRef();
    const addTagToList = (e) => {
        e.preventDefault();
        const newTagValue = tagRef.current.value.toLowerCase();
        tags.includes(newTagValue) !== true
            ? setTags([...tags, newTagValue])
            : alert("Tag already exists");
    };

    const removeFromTags = (item) => {
        const selectedTag = item;
        const remainingArr = tags.filter(
            (arrayItem) => arrayItem !== selectedTag
        );
        setTags(remainingArr);
    };

    // to trigger event when the input is entered
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            addTagToList(event);
        }
    };

    return (
        <div className="tag-form">
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Lets type the tag and press enter or click button!"
                    aria-label="Example text with button addon"
                    aria-describedby="basic-addon1"
                    ref={tagRef}
                    onKeyDown={handleKeyDown}
                />
                <Button
                    id="admin-submit-button"
                    type="submit"
                    onClick={(e) => {
                        addTagToList(e);
                    }}
                >
                    Add Tag
                </Button>
            </InputGroup>

            <Row className="tags">
                {tags && tags.length > 0
                    ? tags.map((item, index) => {
                          return (
                              <Button
                                  id="tag"
                                  key={"tag-" + index}
                                  onClick={() => {
                                      removeFromTags(item);
                                  }}
                              >
                                  {item}
                              </Button>
                          );
                      })
                    : ""}
            </Row>
        </div>
    );
}
