import Api from ".";

const SupplierApi = {
    /**
     * Returns all the suppliers
     */
    getAllSuppliers: () => {
        return Api.getPromise(`/suppliers`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                alert(
                    "An error occured during getting the suppliers: " +
                        err.message
                );
            });
    },

    /**
     * Returns supplier with the given id
     * @param id
     */
    getSupplierById: (id) => {
        return Api.getPromise(`/suppliers/${id}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                alert(
                    "An error occured during getting the supplier: " +
                        err.message
                );
            });
    },

    /**
     * Creates a supplier with the provided supplier info and returns its _id
     * @param data : {
     * name : String,
     * authorized_person: String,
     * email : String,
     * phone: String,
     * country: String,
     * products: Array} data
     */
    createSupplier: (data) => {
        return Api.postPromise(`/suppliers`, data)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                alert("An error occured: " + err.message);
            });
    },

    /**
     * Updates the supplier with the given id with new values
     * @param data : {
     * name : String,
     * authorized_person: String,
     * email : String,
     * phone: String,
     * country: String,
     * products: Array} data
     */
    updateSupplier: (id, data) => {
        return Api.putPromise(`/suppliers/${id}`, data)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                alert("An error occured during update: " + err.message);
            });
    },

    /**
     * Deletes the supplier with given id
     * @param id

     */
    deleteSupplier: (id) => {
        return Api.deletePromise(`/suppliers/${id}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                alert("An error occured during deletion: " + err.message);
            });
    },

    /**
     * Adds the given supplier id to the suppliers list of the given products
     * @param id
     * @param data : {productIDs: Array}
     */
    updateSupplierProducts: (id, data) => {
        return Api.putPromise(`/suppliers/${id}/products`, data)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                alert(
                    "Could not update the products of supplier: " + err.message
                );
            });
    },
};

export default SupplierApi;
