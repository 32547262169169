import Api from "./index";

const MailApi = {
    /**
     * Send mail
     * params: { name,
     *           phone,
     *           email,
     *           additional,
     *           country,
     *         }
     * @param {*} params
     * @returns
     */
    sendMail(params) {
        return Api.postPromise(`/mail`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while creating a mail: " + err.message);
                return null;
            });
    },

    /**
     * Send supplier request mail
     * params: { name,
     *           authorized_person,
     *           phone,
     *           email,
     *           country,
     *           products [],
     *           message
     *         }
     * @param {*} params
     * @returns
     */
    sendSupplierMail(params) {
        return Api.postPromise(`/mail/supplier-request`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while creating a supplier request mail: " +
                        err.message
                );
                return null;
            });
    },

    /**
     * Send mail
     * params: { address }
     *
     * @param {*} params
     * @returns
     */

    saveMail(params) {
        return Api.postPromise(`/saveMail`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while saving a mail address: " + err.message
                );
                return null;
            });
    },

    sendIntroMail(params) {
        return Api.postPromise(`/mail/intro-mail`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while sending an introduction mail: " + err.message
                );
                return null;
            });
    },

    sendHerbsMail(params) {
        return Api.postPromise(`/mail/herbs`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while sending an introduction mail: " + err.message
                );
                return null;
            });
    },
    sendDefaultMail(params) {
        return Api.postPromise(`/mail/default-mail`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "Error while sending a custom mail: " + err.message
                );
                return null;
            });
    },
};

export default MailApi;
