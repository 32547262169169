import React, { useEffect, useState, useRef } from "react";
import "./component-css/Request.css";
import CountrySelector from "./CountrySelector";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import {
    Form,
    Container,
    Col,
    Row,
    Button,
    Spinner,
    Modal,
    Alert,
    FormCheck,
} from "react-bootstrap";
import MailApi from "../api/mailApi";
import RequestApi from "../api/requestApi";
import ProductApi from "../api/productApi";
import CustomerInfoApi from "../api/customerInfoApi";
import { Helmet } from "react-helmet-async";
// import { addAbortSignal } from 'stream';

const subscribtionMessage =
    "I would like to receive e-mails regarding to the news, products and notifications";

export default function RequestForm({ productName, image_url, ref }) {
    const [validated, setValidated] = useState(false);
    const [products, setProducts] = useState([]);
    const [formData, setFormData] = useState({
        productName: productName ? productName : "",
        name: "",
        email: "",
        phone: "",
        additional: "",
        country: "",
        imageUrl: image_url,
        quantity: "",
    });
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [country, setCountry] = useState("");

    //Refs
    const messageRef = useRef();
    const emailRef = useRef();
    const nameRef = useRef();
    const phoneRef = useRef();
    const quantityRef = useRef();
    const noticationsCheckRef = useRef();

    //useEffect
    useEffect(() => {
        ProductApi.getProducts()
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) =>
                alert("An error happended during fetching products: " + err)
            );
    }, []);

    useEffect(() => {}, [country, formData]);

    //handlers
    const handleFormData = (key, value) => {
        setFormData((formData) => ({ ...formData, [key]: value }));
    };

    const handleCountry = (value) => {
        setCountry(value);
        setFormData((formData) => ({ ...formData, country: value }));
    };

    const sendMail = async (event) => {
        setLoading(true);
        // let data = {
        //     name: nameRef.current.value,
        //     phone: phoneNumber,
        //     email: emailRef.current.value,
        //     additional: messageRef.current.value,
        //     country: country,
        //     productName: formData.productName,
        //     quantity: quantityRef.current.value,
        //     imageUrl: image_url !== undefined ? image_url : "",
        // };
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(true);
        }
        event.preventDefault();
        let requestData = {
            product_name: formData.productName,
            email: formData.email,
            country: country,
            message: formData.additional,
            quantity: formData.quantity,
        };
        try {
            await RequestApi.createRequest(requestData)
                .then((res) => {})
                .catch((err) => alert("Error occured: " + err));

            await MailApi.sendMail(formData)
                .then((res) => {
                    if (res !== null) {
                        setLoading(false);
                        alert("Request successfuly sent!");
                    }
                })
                .catch((err) => console.log("Error: ", err));
        } catch {}

        if (noticationsCheckRef.current.checked === true) {
            CustomerInfoApi.createCustomer({
                name: nameRef.current.value,
                company_name: "-",
                email_address: emailRef.current.value,
            })
                .then((res) => {
                    alert(res.message);
                })
                .catch((err) => {
                    alert("An error occured during joining mail list: " + err);
                });
        }
    };

    //Maps products to fill react-select component
    const productsToOptions = () => {
        if (products === undefined || products.length === 0)
            //if state does not mounted yet, does not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
        });

        return options;
    };
    return (
        <>
            <Container ref={ref} className="req-container" data-aos="fade-up">
                <div className="row mt-4">
                    <div className="col-lg-10">
                        <div className="tp-section-wrap">
                            <span>
                                <i className="flaticon-grass"></i>
                            </span>
                            <h3 className="tp-section-title">
                                If You Have Any Requests, Don’t Hesitate Contact
                                Us{" "}
                            </h3>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col className="req_col" lg={3} md={3}>
                        <div className="icon-box mt-5">
                            <h3>Guaranteed Response </h3>
                            <p>
                                In 24Hrs our highly motivated team will contact
                                you about your requests
                            </p>
                        </div>
                        <div className="icon-box mt-5">
                            <i className="bx bx-shield"></i>
                            <h3>Secure Supply </h3>
                            <p>
                                Reliable supply chain with peer to peer logistic
                                solutions
                            </p>
                        </div>
                    </Col>
                    <Col className="form-col" lg={9} md={9}>
                        <Form
                            validation={validated.toString()}
                            onSubmit={sendMail}
                            className="req-form"
                        >
                            <Form.Row>
                                <Col lg={6} md={6}>
                                    <Form.Group
                                        as={Col}
                                        controlId="formGridEmail"
                                    >
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter Name"
                                            ref={nameRef}
                                            onChange={(e) => {
                                                handleFormData(
                                                    "name",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6} md={6}>
                                    <Form.Group
                                        as={Col}
                                        controlId="formGridEmail"
                                    >
                                        <Form.Label>Phone</Form.Label>
                                        {/* <Form.Control  type="text" placeholder="Enter Phone (Optional)" ref = {phoneRef} /> */}
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            value={phoneNumber}
                                            onChange={(e) => {
                                                setPhoneNumber(e);
                                                handleFormData("phone", e);
                                            }}
                                            ref={phoneRef}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6} md={6}>
                                    <Form.Group
                                        as={Col}
                                        controlId="formGridEmail"
                                    >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder="Enter email"
                                            ref={emailRef}
                                            onChange={(e) => {
                                                handleFormData(
                                                    "email",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6} md={6}>
                                    <Form.Group
                                        as={Col}
                                        controlId="formGridCountry"
                                    >
                                        <Form.Label>Country</Form.Label>
                                        <CountrySelector
                                            handleCountry={handleCountry}
                                        ></CountrySelector>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} md={6}>
                                    <Form.Group
                                        as={Col}
                                        controlId="formProductSelect"
                                    >
                                        <Form.Label>Choose Product</Form.Label>
                                        <Select
                                            //gives the selected option's value onChange
                                            onChange={(product) => {
                                                handleFormData(
                                                    "productName",
                                                    product.value.name
                                                );
                                                handleFormData(
                                                    "imageUrl",
                                                    product.value.image_url[0]
                                                );
                                            }}
                                            options={productsToOptions()}
                                            defaultValue={{
                                                option: productName,
                                                label: productName,
                                            }}
                                        ></Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={6} md={6}>
                                    <Form.Group
                                        as={Col}
                                        controlId="formGridQuantity"
                                    >
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control
                                            required
                                            type="normal"
                                            placeholder="Enter quantity"
                                            ref={quantityRef}
                                            onChange={(e) => {
                                                handleFormData(
                                                    "quantity",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={12} md={12}>
                                    <Form.Group
                                        as={Col}
                                        controlId="formBasicTextArea"
                                    >
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            required
                                            as="textarea"
                                            placeholder="Please enter your request"
                                            rows={5}
                                            ref={messageRef}
                                            onChange={(e) => {
                                                handleFormData(
                                                    "additional",
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={12} md={12}>
                                    <Form.Group
                                        as={Col}
                                        controlId="formNotificationCheck"
                                    >
                                        <FormCheck
                                            label={subscribtionMessage}
                                            ref={noticationsCheckRef}
                                        ></FormCheck>
                                    </Form.Group>
                                </Col>
                            </Form.Row>

                            <Button id="submit-button" type="submit">
                                {loading ? (
                                    <span>
                                        <h4>Pending Request</h4>{" "}
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        ></Spinner>
                                    </span>
                                ) : (
                                    "Request Quote"
                                )}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
