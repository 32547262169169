import React, { useEffect, useState, useRef } from "react";
import "../component-css/Request.css";

import { Form, Container, Col, Button } from "react-bootstrap";

import NewsApi from "../../api/newsApi";

import Select from "react-select";
import ImageUpload from "../../helpers/PhotoUploader/PhotoUploader";
import TagCreator from "./Subcomponents/TagCreator/TagCreator";

export default function ModifyNews() {
    //States
    const [posts, setPosts] = useState();
    const [imgList, setImgList] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedPost, setSelectedPost] = useState({
        title: "",
        card_context: "",
        image_url: "",
        context: "",
        url: "",
        source_url: "",
        tags: [],
    });

    //Refs
    const titleRef = useRef();
    const card_contextRef = useRef();
    const image_urlRef = useRef();
    const contextRef = useRef();
    const source_urlRef = useRef();
    const tagRef = useRef();

    //UseEffects
    useEffect(() => {
        NewsApi.getNews()
            .then((res) => setPosts(res.data))
            .catch((err) => console.log("Error: ", err));
    }, []);

    useEffect(() => {
        if (imgList.length > 1) imgList.shift();
    }, [imgList.length]);

    useEffect(() => {
        fillAreas();
    }, [selectedPost.title]);

    //Handlers
    const handleImageList = (e) => {
        setImgList(e);
    };
    const handleTags = (newTagsList) => {
        setTags(newTagsList);
    };

    const productsToOptions = () => {
        if (posts === undefined)
            //if state did not mount yet, does not continue
            return;
        let options = []; //array for react-select
        posts.map(function (posts) {
            let lbl =
                posts.title.charAt(0).toUpperCase() + posts.title.slice(1);
            options = [...options, { value: posts, label: lbl }];
        });
        return options;
    };

    const modifyNews = () => {
        const data = {
            title: titleRef.current.value,
            card_context: card_contextRef.current.value,
            context: contextRef.current.value,
            source_url: source_urlRef.current.value,
            image_url: imgList[0],
            tags: tags,
        };

        // promise'lerde sıkıntı olduğu için id doğru olmasına rağmen bulamıyor
        // promise parametre yollarken {data:{}} seklinde yolluyor Akkurta sor
        NewsApi.updateNews(selectedPost._id, data)
            .then(async (res) => {
                await alert("News is modified");
                window.location.reload();
            })
            .catch((err) => console.log("Error: ", err));
    };

    const fillAreas = () => {
        if (selectedPost.name === "") return;

        setImgList([selectedPost.image_url]);
        // assign values of refs
        titleRef.current.value = selectedPost.title;
        card_contextRef.current.value = selectedPost.card_context;
        contextRef.current.value = selectedPost.context;
        source_urlRef.current.value = selectedPost.source_url;
        image_urlRef.current.value = selectedPost.image_url;
        setTags(selectedPost.tags);
    };

    return (
        <Container>
            <Form className="req-form">
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridProductsSelect">
                        <Form.Label>Name Select</Form.Label>
                        <Select
                            options={productsToOptions()}
                            onChange={(e) => {
                                setSelectedPost(e.value);
                            }}
                        />
                    </Form.Group>
                </Form.Row>

                <h3> Properties to Change </h3>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridProducts">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            required
                            placeholder="Product title"
                            ref={titleRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridProducts">
                        <Form.Label>Card Context</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            placeholder="card_context"
                            rows={5}
                            ref={card_contextRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridProducts">
                        <Form.Label>Context</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            placeholder="context"
                            rows={5}
                            ref={contextRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridImageUploader">
                        <Form.Label>Image Uploader</Form.Label>
                        <br />
                        <ImageUpload
                            parentImageList={imgList}
                            setParentImageList={handleImageList}
                        ></ImageUpload>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    {/* <Form.Group as={Col} controlId="formGridTag">
            <Form.Label>Tags</Form.Label>
            <Form.Control
              placeholder="Please do not leave space after comma"
              ref={tagRef}
            />
          </Form.Group> */}
                    <TagCreator
                        handleTags={handleTags}
                        existingTags={tags}
                    ></TagCreator>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridProducts">
                        <Form.Label>Source_url</Form.Label>
                        <Form.Control
                            required
                            placeholder="source_url"
                            ref={source_urlRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridProducts">
                        <Form.Label>Image_url</Form.Label>
                        <Form.Control
                            disabled
                            required
                            placeholder="image_url"
                            ref={image_urlRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Button id="admin-submit-button" onClick={modifyNews}>
                    Modify Item
                </Button>
            </Form>
        </Container>
    );
}
