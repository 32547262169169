import axios from "axios";
import Links from "../constants/Links";

const Api = {
    getPromise(endpoint, params, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        const prms = params ? { params: params } : {};

        return new Promise((resolve, reject) => {
            axios
                .get(baseUrl + endpoint, prms)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    },

    postPromise(endpoint, body, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        body = body || {};

        return new Promise((resolve, reject) => {
            axios
                .post(baseUrl + endpoint, body)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    },

    putPromise(endpoint, body, baseUrl, config) {
        baseUrl = baseUrl || Links.BASE_URL;
        body = body || {};

        return new Promise((resolve, reject) => {
            axios
                .put(baseUrl + endpoint, body, config)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    },

    deletePromise(endpoint, body, baseUrl) {
        baseUrl = baseUrl || Links.BASE_URL;
        body = body || {};

        return new Promise((resolve, reject) => {
            axios
                .delete(baseUrl + endpoint, { data: body })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        });
    },
};

export default Api;
