import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductApi from "../../api/productApi";
export default function ProductCard({
    productName,
    productObject,
    maxCardInRow = 3,
}) {
    const [productInfo, setProductInfo] = useState();
    const [cssClass, setCssClass] = useState();
    //Check if the card is created with product name or product object
    useEffect(() => {
        if (productName !== undefined)
            ProductApi.getProduct(createProductUrl(productName))
                .then((res) => {
                    setProductInfo(res.data);
                })
                .catch((err) => {
                    console.log("Error: " + err);
                });
        else {
            setProductInfo(productObject);
        }
        setCssClass(createCssClass());
    }, [productName, productObject]);

    //creates product url with productName
    function createProductUrl(productName) {
        let url = productName.toLowerCase().replace(/ /g, "-");
        return url;
    }

    function createCssClass() {
        var colWidthLg;
        var colWidthMd;
        if (Number.isInteger(maxCardInRow)) {
            colWidthLg = 12 / maxCardInRow;
            colWidthMd = 12 / (maxCardInRow + 1);
        } else return "col-lg-4 col-md-6";

        return (
            "col-xl-" +
            colWidthLg.toString() +
            " col-lg-" +
            colWidthLg.toString() +
            " col-md-" +
            colWidthMd.toString() +
            " "
        );
    }

    //if productInfo does not have any data, then does not create the card
    if (productInfo !== undefined)
        return (
            <div className="wrapper-div">
                <Link
                    to={"/product/" + createProductUrl(productInfo.name)}
                    data-aos="zoom-in"
                    data-aos-delay="100"
                    style={{ textDecoration: "none" }}
                >
                    <div className="icon-box border-animation">
                        <div className="image">
                            {productInfo.image_url[0] ? (
                                <img
                                    src={productInfo.image_url[0]}
                                    alt={
                                        productInfo.image_url[0] !== undefined
                                            ? productInfo.name
                                            : ""
                                    }
                                    loading="lazy"
                                    width={"100%"}
                                    height={"100%"}
                                    style={{
                                        objectFit: "cover",
                                        borderRadius: "100%",
                                    }}
                                ></img>
                            ) : (
                                ""
                            )}
                        </div>
                        <h4>{productInfo.name}</h4>
                        {/* <p>{productInfo.name}</p> */}
                    </div>
                </Link>
            </div>
        );
    else return "";
}
