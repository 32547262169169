import React, { useEffect, useState } from "react";
import Hero from "./HeroSection";
import Features from "./Features";
import Certificates from "./Certificates";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import "../component-css/Home.css";
import { Container, Row } from "react-bootstrap";
import ProductCards from "../ProductCards";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import HomeNews from "./HomeNews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";
import Header from "../SubComponents/Header";
import { useRef } from "react";
import LatestRequests from "./LatestReqs";
import PartnershipDisplay from "../Partnership/Partnership";
import FairsCarousel from "../Partnership/FairsCarousel";

export default function Home({
    products,
    popularProducts,
    selectedNewsHandler,
    selectedProductHandler,
    toAboutUs = false, // for sliding directly to About us Section
}) {
    useEffect(() => {
        {
            scrollToReqForm();
        }
    }, [toAboutUs]);
    const aboutUsRef = useRef();
    const scrollToReqForm = () => {
        if (toAboutUs) {
            const element = aboutUsRef.current;
            const headerOffset = 120;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <div>
            <Helmet>
                <title>
                    Your Gateway to Global Trade: Import and Export Company -
                    FoodVersy
                </title>
                <meta
                    name="google-site-verification"
                    content="mnhwhxoX-fHyWoCiV5atQAo1yStpJ11i_njTMKp10hE"
                />
                <link
                    rel="canonical"
                    href={"https://www.foodversy.com/home"}
                ></link>
                <meta
                    name="description"
                    content="Explore the world of international trade with FoodVersy. We specialize in import and export services, connecting you to a wide range of products and markets. Discover top exports from Turkiye, India, New Zealand, and more. Join us to promote exports, boost your business, and find the best partners in trade."
                />
            </Helmet>
            <Hero />

            <Container id="HomeContainer" fluid>
                <Container id="Popular" fluid style={{ marginTop: "8rem" }}>
                    <Row id="Cartsrow" style={{ display: "block" }}>
                        <section id="services" className="services">
                            <div className="container">
                                <div className="row">
                                    <ProductCards
                                        header={"Most Popular Products"}
                                        subHeader="Agriculture was a family business not too long ago. Nowadays, automation, scientific advances and better
                  transportation have allowed for industrialization."
                                        productList={popularProducts}
                                    ></ProductCards>
                                </div>
                            </div>
                        </section>
                    </Row>
                </Container>
            </Container>

            <Features />
            <LatestRequests></LatestRequests>
            <div ref={aboutUsRef}></div>
            <AboutUs />

            <div style={{ marginTop: "5rem" }}>
                <Certificates />
            </div>
            <FairsCarousel
                header={"Upcoming Expositions"}
                subHeader={
                    "We inform you about the upcoming expositions to keep you up to date about all the improvments in the agriculture and food industry"
                }
            />
            <HomeNews></HomeNews>
            <ContactUs></ContactUs>
        </div>
    );
}
