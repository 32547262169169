const parent_categories = [
    { value: "Main", label: "Main" },
    { value: "Specials", label: "Special" },
    { value: "Retail", label: "Retail" },
    { value: "Organic", label: "Organic" },
    { value: "Machinery", label: "Machinery" },
    { value: "Pet", label: "Pet Food" },
    { value: "Agriculture", label: "Agriculture" },
];

const parent_category_info = {
    Main: {
        header: "Main Categories",
        subheader: "From Seeds to Dairy Products, We've Got You Covered",
        content: `As a leading B2B agricultural supplier, we offer a comprehensive range of products to meet all your needs. 
         Whether you're looking for seeds, fertilizers, or livestock feed, we have everything you need to keep your operation running smoothly.
         Our easy-to-use platform makes it simple to find what you're looking for, and our team of experts is always available to answer any questions you may have. 
         Choose us as your one-stop shop for all agricultural products and let us help you grow.`,
    },
    Specials: {
        header: "Special Categories",
        subheader: "Bringing Unique Products from Turkiye",
        content: `Discover the world of specialty crops, where you can find products which are difficult to find.
         From heirloom vegetables to rare spices, our selection is offers a wide variety of products for you.`,
    },

    Retail: {
        header: "Retail Categories",
        subheader: "Your Trusted Source for Retail Agricultural Products",
        content: `As a leading supplier of retail agricultural products, we understand the importance of providing fresh and high-quality products to your customers.
         From fruits and vegetables to meat and dairy, our products are sourced from the best local producers and meet the highest standards of quality. 
         With a wide range of options and reliable delivery, we make it easy for you to offer your customers the best products available. 
         Choose us as your trusted source for retail agricultural products and let us help you grow your business.`,
    },
    Organic: {
        header: "Organic Product Categories",
        subheader: "Discover the Best Organic Products in Turkey",
        content: `Looking for high quality organic products for your customers? 
        Look no further! Our selection of organic products offers all the goodness of nature, free from harmful chemicals and preservatives.
        From wholesome food to natural skincare, we have everything you need to embrace organic living. Experience the benefits for yourself and make a positive change in the world. 
        Choose nature's best with our organic produc`,
    },
    Machinery: {
        header: "Machinery Categories",
        subheader: "Empowering Agriculture with Cutting-Edge Machinery",
        content: `Farming has never been easier with our state-of-the-art agricultural machinery. 
        Whether you're a small-scale farmer or a large agribusiness, our cutting-edge solutions will help you increase efficiency, reduce costs and boost productivity.
         From tractors to harvest equipment, our products are designed to meet the needs of modern agriculture. 
         Join us in revolutionizing the way we farm and empower your business with the latest in agricultural machinery.`,
    },
    Pet: {
        header: "Pet Food Categories",
        subheader: "Supplying pet food around the world",
        content: `We provide high quality pet food for a large variety of different kinds. Checkout our product portfolio for pet food`,
    },
    Agriculture: {
        header: "Agriculture Categories",
        subheader: "Cultivating Knowledge: Explore the World of Agriculture",
        content:
            "Our Agriculture section is dedicated to providing you with comprehensive information and resources related to the fascinating world of farming, cultivation, and agricultural practices. Whether you're an experienced farmer, a gardening enthusiast, or simply interested in learning more about sustainable food production, this is the perfect place for you.",
    },
};
export default parent_categories;
export { parent_category_info };
