import React, { useCallback, useEffect, useState } from "react";
import { ArrayHelper } from "../helpers/ArrayHelper";
import StringHelper from "../helpers/StringHelper";
import FairApi from "../api/fairApi";

const dummyFairs = [
    {
        _id: 123123,
        name: "Lorem Ipsum",
        about: "",
        start_date: StringHelper.formatISODate(
            new Date().toISOString(),
            "date",
            "-"
        ),
        end_date: StringHelper.formatISODate(
            new Date().toISOString(),
            "date",
            "-"
        ),
        image_name: "Asdasdasda",
        image_url: "https://www.xtrafondos.com/wallpapers/es-batman-9331.jpg",
        card_context:
            "Consectetur ullamco ut et sit in est duis ad aliqua culpa dolore excepteur.",
        content: "",

        country: "Turkey",
    },
    {
        _id: 123124,
        name: "Lorem Ipsum Dolor",
        about: "",
        start_date: new Date().toISOString(),
        end_date: new Date().toISOString(),
        image_name: "",
        card_context: "Et est aute nostrud dolor culpa nulla et incididunt.",
        content: "",
        country: "Kenya",
    },
    {
        _id: 123125,
        name: "Hasanus",
        about: "",
        start_date: new Date().toISOString(),
        end_date: new Date().toISOString(),
        image_name: "Hasanus image",
        card_context: "Et est aute nostrud dolor culpa nulla et incididunt.",
        content: "",

        country: "Kenya",
    },
];

function useAsync(asyncFn, onSuccess) {
    useEffect(() => {
        let isActive = true;
        asyncFn().then((data) => {
            if (isActive) onSuccess(data);
        });
        return () => {
            isActive = false;
        };
    }, [asyncFn, onSuccess]);
}

/**
 * A custom hook to return all the fairs
 * It also applies filtering if a filter object is provided
 * @param {*} filter object
 * @returns fairs state
 */
export default function useFairs(filter) {
    const [fairs, setFairs] = useState([]);
    const [filteredFairs, setFilteredFairs] = useState([]);
    const [error, setError] = useState(null);

    const filterFairs = useCallback(() => {
        // Once the endpoint is ready, the fairs data will be fetched here
        setFilteredFairs(ArrayHelper.filterObjectArray(fairs, filter));
    }, [filter, fairs]);

    const reload = () => {
        getFairs();
    };

    const getFairs = () => {
        FairApi.getFairs()
            .then((res) => {
                setFairs(res.data);
            })
            .catch((err) => {
                setError(err);
            });
    };

    useEffect(() => {
        getFairs();
    }, []);

    useEffect(() => {
        filterFairs();
    }, [filterFairs]);

    return { fairs: filteredFairs, reload: reload, error };
}
