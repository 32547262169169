import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Fairs.css";
import { Helmet } from "react-helmet-async";

import { FacebookShareButton, TwitterShareButton } from "react-share";
import ShareButtons from "../../helpers/ShareButtons";

import Author from "../SubComponents/Author";
import ImageContainer from "../SubComponents/ImageContainer";
import { getIPAddress } from "../../helpers/GetIPAddress";
import ViewCountApi from "../../api/viewCountApi";

import defImage from "../../assets/food-background-food-concept-with-various-tasty-fresh-ingredients-cooking-italian-food-ingredients-v.webp";
import useQuery from "../../hooks/useQuery";
import FairApi from "../../api/fairApi";

export default function Fairfair(props) {
    const query = useQuery();
    const [fair, setFair] = useState({
        _id: "",
        name: "",
        firm: "",
        email_address: "",
        about: "",
        start_date: "",
        end_date: "",
        country: "",
        image_name: "",
        image_url: "",
        content_type: "",
        card_context: "",
        content: "",
        url: "",
    });

    useEffect(() => {
        FairApi.getFairById(query.get("key"))
            .then((res) => {
                console.log(res);
                setFair(res.data);
            })
            .catch((err) => {
                alert(`Error during fetching the fair info: ${err}`);
            });
    }, [query]);

    return (
        <Container fluid>
            <ShareButtons
                url={window.location.href}
                shareImage={fair.image_url}
                shareMessageText={"I found this news on www.foodversy.com !"}
                shareTitle={fair.name}
            ></ShareButtons>
            <Helmet>
                <title>{fair.name}</title>
                <meta
                    name="description"
                    content={fair?.card_context?.trim(" ")}
                />
            </Helmet>
            <Container fluid>
                <div className="main-body">
                    <Row>
                        <Col lg={6} xl={6}>
                            <Row id="top-image-row">
                                <Col>
                                    <ImageContainer
                                        imgUrl={
                                            fair.image_url
                                                ? fair.image_url
                                                : defImage
                                        }
                                        alt={fair.name}
                                        objectFit={"cover"}
                                        height={"90vh"}
                                    ></ImageContainer>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} xl={6}>
                            <Row id="Body" style={{ marginTop: "5%" }}>
                                {/* <ViewCount viewCount={viewCount}></ViewCount> */}
                                <h1 id="Title"> {fair.name}</h1>
                                <h2 style={{ fontWeight: "bold" }}>
                                    {fair.card_context}
                                </h2>
                                <br></br>
                                <h5 style={{ whiteSpace: "break-spaces" }}>
                                    {fair.content}
                                </h5>
                                <h5 id="ReferencesId">Source:</h5>
                                <a
                                    style={{ wordBreak: "break-all" }}
                                    href={fair.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {fair.url}
                                </a>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <FacebookShareButton
                            url={window.location.href}
                            quote={
                                "I'm sharing this fair with you: " + fair.name
                            }
                        ></FacebookShareButton>
                        <TwitterShareButton
                            url={window.location.href}
                            title={fair.name}
                            related="@Foodversy"
                        ></TwitterShareButton>
                    </Row>
                </div>
            </Container>
        </Container>
    );
}
