import React, { useEffect, useState } from "react";
import "../component-css/AdminPanel.css";

import AddItem from "./AddItem";
import DeleteItem from "./DeleteItem";
import DeleteNews from "./DeleteNews";

import AddNews from "./AddNews";
import ModifyItem from "./ModifyItem";
import { Route, Link } from "react-router-dom";
import {
    Row,
    Col,
    DropdownButton,
    Dropdown,
    ButtonGroup,
    Container,
} from "react-bootstrap";
import ModifyNews from "./ModifyNews";
import DeleteCategory from "./DeleteCategory";
import AddCategory from "./AddCategory";
import AdminLogin from "./AdminLogin";
import UserApi from "../../api/userApi";
import RequestControl from "./RequestControl/RequestControl";
import MailControl from "./MailControl/MailControl";
import SupplierPanel from "./Supplıer/Supplier";
import FairControl from "./FairControl/FairControl";
export default function AdminPanel() {
    const [authorized, setAuthorized] = useState(false);

    let direction = "right";
    useEffect(() => {
        isAuthorized();
    }, []);

    const handleAuth = (e) => {
        setAuthorized(e);
    };
    const isAuthorized = () => {
        let token = localStorage.getItem("token");
        UserApi.checkIsAuthorized({ token: token })
            .then((res) => {
                setAuthorized(res);
            })
            .catch((err) => console.log("Error: ", err));
    };

    if (authorized)
        return (
            <Row>
                <Col id="leftbar" md={2} style={{ paddingTop: "2rem" }}>
                    <DropdownButton
                        as={ButtonGroup}
                        key={direction + "-01"}
                        id={`dropdown-button-drop-${direction}`}
                        drop={direction}
                        variant="secondary"
                        title={`Product Options`}
                    >
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/addItem`}
                            eventKey="1"
                        >
                            Add New Product
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/modifyItem`}
                            eventKey="2"
                        >
                            Modify Product
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/deleteItem`}
                            eventKey="3"
                        >
                            Delete Product
                        </Dropdown.Item>
                    </DropdownButton>

                    <DropdownButton
                        as={ButtonGroup}
                        key={direction + "-22"}
                        id={`dropdown-button-drop-${direction}`}
                        drop={direction}
                        variant="secondary"
                        title={`News Options`}
                    >
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/addNews`}
                            eventKey="1"
                        >
                            Add News{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/modifyNews`}
                            eventKey="2"
                        >
                            Modify News
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/deleteNews`}
                            eventKey="3"
                        >
                            Delete News
                        </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                        as={ButtonGroup}
                        key={direction + "-11"}
                        id={`dropdown-button-drop-${direction}`}
                        drop={direction}
                        variant="secondary"
                        title={`Category Options`}
                    >
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/addCategory`}
                            eventKey="1"
                        >
                            Add Category{" "}
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/modifyCategory`}
                            eventKey="2"
                        >
                            Modify Category
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to={`/adminPanel/deleteCategory`}
                            eventKey="3"
                        >
                            Delete Category
                        </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                        as={ButtonGroup}
                        key={direction + "-1"}
                        id={`dropdown-button-drop-${direction}`}
                        drop={direction}
                        variant="secondary"
                        title={`Request Options`}
                    >
                        <Dropdown.Item
                            as={Link}
                            to={"/adminPanel/requestControl"}
                        >
                            Request Control
                        </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                        as={ButtonGroup}
                        key={direction + "-2"}
                        id={`dropdown-button-drop-${direction}`}
                        drop={direction}
                        variant="secondary"
                        title={`Mail Options`}
                    >
                        <Dropdown.Item as={Link} to={"/adminPanel/mailControl"}>
                            Mail Control
                        </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                        as={ButtonGroup}
                        key={direction + "-3"}
                        id={`dropdown-button-drop-${direction}`}
                        drop={direction}
                        variant="secondary"
                        title={`Supplier Options`}
                    >
                        <Dropdown.Item
                            as={Link}
                            to={"/adminPanel/supplierControl"}
                        >
                            Supplier Control
                        </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                        as={ButtonGroup}
                        key={direction + "-4"}
                        id={`dropdown-button-drop-${direction}`}
                        drop={direction}
                        variant="secondary"
                        title={`Fair Options`}
                    >
                        <Dropdown.Item as={Link} to={"/adminPanel/fairControl"}>
                            Fair Control
                        </Dropdown.Item>
                    </DropdownButton>
                </Col>
                <Col md={10}>
                    <Route path="/adminPanel/addItem">
                        <AddItem />
                    </Route>
                    <Route path="/adminPanel/addNews">
                        <AddNews />
                    </Route>
                    <Route path="/adminPanel/addCategory">
                        <AddCategory />
                    </Route>

                    <Route path="/adminPanel/modifyItem">
                        <ModifyItem />
                    </Route>
                    <Route path="/adminPanel/modifyNews">
                        <ModifyNews />
                    </Route>

                    <Route path="/adminPanel/deleteItem">
                        <DeleteItem />
                    </Route>
                    <Route path="/adminPanel/deleteNews">
                        <DeleteNews />
                    </Route>

                    <Route path="/adminPanel/deleteCategory">
                        <DeleteCategory />
                    </Route>
                    <Route path="/adminPanel/requestControl">
                        <RequestControl />
                    </Route>
                    <Route path="/adminPanel/mailControl">
                        <MailControl />
                    </Route>
                    <Route path="/adminPanel/supplierControl">
                        <SupplierPanel />
                    </Route>
                    <Route path="/adminPanel/fairControl">
                        <FairControl />
                    </Route>
                </Col>
            </Row>
        );
    else
        return (
            <Container>
                <AdminLogin authHandler={handleAuth} />
            </Container>
        );
}
