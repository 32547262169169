import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ParentCategoryApi from "../api/parentCategoryApi";
import createLink from "../helpers/CreateLink";

import "./component-css/MainCategories.css";
import Aos from "aos";
import Jumbotron from "./SubComponents/Jumbotron";
import { parent_category_info } from "../constants/Parents";
import { Helmet } from "react-helmet-async";

export default function MainCategories() {
    const [mainCategory, setMainCategory] = useState("");
    const [mainCategoryInfo, setMainCategoryInfo] = useState({});
    const [subcategoryList, setSubcategoryList] = useState();
    const params = useParams();

    useEffect(() => {
        //get selected main category
        handleMainCategory(
            params.categoryname.charAt(0).toUpperCase() +
                params.categoryname.slice(1, params.categoryname.length)
        );
    }, [params.categoryname]);

    useEffect(() => {
        //get the subcategory list of the main category
        ParentCategoryApi.getCategoryByParentCategory(mainCategory)
            .then((res) => {
                handleSubCategory(res.data);
            })
            .catch((err) => console.log("Error: " + err));
        handleMainCategoryInfo(parent_category_info[mainCategory]);
    }, [mainCategory]);

    useEffect(() => {}, [subcategoryList]);

    //handler

    const handleMainCategory = (e) => {
        setMainCategory(e);
    };
    const handleMainCategoryInfo = (e) => {
        setMainCategoryInfo(e);
    };

    const handleSubCategory = (e) => {
        setSubcategoryList(e);
    };

    //Functions
    //
    //create subcategory cards dynamically here
    const createCategoryCards = () => {
        //if subcategory consists of more than one item
        if (Array.isArray(subcategoryList)) {
            return subcategoryList.map(function (item) {
                return (
                    <Link
                        to={createLink("/category/", item.name)}
                        className="col-lg-4 col-md-6 align-items-stretch mb-4"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                        style={{ textDecoration: "none" }}
                    >
                        <div className="icon-box">
                            <h4>{item.name}</h4>

                            {/* <p>{productInfo.name}</p> */}
                        </div>
                    </Link>
                );
            });
        } else {
            return (
                <Link
                    to={createLink("/category?name=", subcategoryList.name)}
                    className="col-lg-4 col-md-6 align-items-stretch mb-4"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                    style={{ textDecoration: "none" }}
                >
                    <div className="icon-box">
                        <h4>{subcategoryList.name}</h4>
                        {/* <p>{productInfo.name}</p> */}
                    </div>
                </Link>
            );
        }
    };

    if (subcategoryList !== undefined)
        return (
            <>
                <Helmet>
                    <link
                        rel="canonical"
                        href={
                            "https://www.foodversy.com/main-categories/" +
                            mainCategory.toLowerCase()
                        }
                    ></link>
                </Helmet>
                <div>
                    <Jumbotron
                        header={
                            mainCategoryInfo && mainCategoryInfo.header
                                ? mainCategoryInfo.header
                                : ""
                        }
                        subheader={
                            mainCategoryInfo &&
                            mainCategoryInfo.subheader !== undefined
                                ? mainCategoryInfo.subheader
                                : ""
                        }
                        content={
                            mainCategoryInfo &&
                            mainCategoryInfo.content !== undefined
                                ? mainCategoryInfo.content
                                : ""
                        }
                        image_url={
                            "https://res.cloudinary.com/da8slr0ic/image/upload/v1630391688/foodsfromturkey/uc9nh5ijlswkt50ch0yh.jpg"
                        }
                    ></Jumbotron>
                    <section id="main-services" className="main-services">
                        <div className="container" data-aos="fade-up">
                            <div className="row">{createCategoryCards()}</div>
                        </div>
                    </section>
                </div>
            </>
        );
    else return <div></div>;
}
