import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ProductApi from "../api/productApi";

//Css Class-Components
import { Container, Col, Row, Spinner } from "react-bootstrap";
import "./component-css/Product.css";
import "./component-css/Certificates.css";

//Our Components
import ImageContainer from "./SubComponents/ImageContainer";

// import ImageSliderProduct from "./Sliders/ImageSliderProduct";
import Request from "./Request";
import SearchLandingNews from "./Search/SearchLandingNews";
import ProductCards from "./ProductCards";

//Flag Component'
import Flag from "react-flagpack";

//Helpers
import ShareButtons from "../helpers/ShareButtons";
import DataTableBase from "./SubComponents/DataTable/DataTable";
import { countries } from "../constants/Countries";
import StringHelper from "../helpers/StringHelper";
import { getIPAddress } from "../helpers/GetIPAddress";
import ViewCountApi from "../api/viewCountApi";
import ViewCount from "./SubComponents/ViewCount";

//Image
import requestInfoPhoto from "../assets/png-transparent-form-others-angle-text-photography.webp";
import shippingPhoto from "../assets/shipping.webp";
import { Globe } from "react-bootstrap-icons";
import useQuery from "../hooks/useQuery";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Product({
    selectedProduct,
    selectedNewsHandler,
    queryString,
}) {
    //#region States

    const [productInfo, setProductInfo] = useState();
    const [productRequests, setProductRequests] = useState([]);
    const [noResult, setNoResult] = useState(true);
    const [ipAddress, setIPAddress] = useState("");
    const [viewCount, setViewCount] = useState(0);
    const location = useLocation();
    //#endregion

    //Url Params
    const params = useParams();

    //References
    const reqFormRef = useRef();

    //#region useEffects

    useEffect(() => {
        ProductApi.getProduct(params.name)
            .then((res) => {
                handleProductInfo(res.data);
                setProductRequests(
                    Array.isArray(res.requests)
                        ? res.requests.reverse()
                        : res.requests
                );
            })
            .catch((err) => {
                console.log("Error: ", err);
            });

        handleIPAddress();
    }, [selectedProduct, params]);

    useEffect(() => {
        if (productInfo === undefined) return;
        ViewCountApi.updateProductViewCount(productInfo._id, {
            ip_address: ipAddress,
        });
    }, [ipAddress]);

    useEffect(() => {
        if (productInfo === undefined) return;
        ViewCountApi.getProductViewCount(productInfo._id)
            .then((res) => {
                setViewCount(res.data.view_count);
            })
            .catch((err) => {
                console.log("Error while fetching view count: " + err);
            });
    }, [productInfo]);

    useEffect(() => {
        productRequests.forEach((item) => {
            const countryInfo = countries.find((x) => x.name === item.country);

            item.code =
                countryInfo && countryInfo.code ? (
                    <Flag
                        code={countryInfo.code}
                        alt={countryInfo.name + " flag"}
                    ></Flag>
                ) : (
                    <Globe size={32} color="#0096FF"></Globe>
                );
        });
    }, [productRequests]);

    //#endregion

    //#region handlers
    const handleNoResult = (e) => {
        setNoResult(e);
    };

    const handleIPAddress = () => {
        getIPAddress().then((res) => {
            setIPAddress(res);
        });
    };

    const handleProductInfo = (e) => {
        setProductInfo(e);
    };

    //#endregion

    //#region column model
    const columns = [
        {
            name: "",
            width: "100px",
            selector: (row) => {
                try {
                    return row.code;
                } catch (err) {
                    return <Globe></Globe>;
                }
            },
        },
        {
            name: "Country",
            selector: (row) => row.country,
            sortable: true,
        },
        {
            name: "Quantity",
            selector: (row) => row.quantity,
        },
        {
            name: "Date (DD/MM/YYYY)",
            selector: (row) => {
                return StringHelper.formatISODateReverse(row.date);
            },
        },
    ];
    //#endregion

    // const productDesc = [{ col1: "Deneme", col2: "Deneme2" }];

    const scrollToReqForm = () => {
        const element = reqFormRef.current;
        const headerOffset = 175;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });
    };
    //#region render

    if (productInfo !== undefined)
        return (
            <Container fluid style={{ marginTop: "2vw" }}>
                <ShareButtons
                    url={window.location.href}
                    shareImage={productInfo.image_url[0]}
                    shareMessageText={
                        "I found this product on www.foodversy.com"
                    }
                    shareTitle={productInfo.name}
                ></ShareButtons>
                <Helmet>
                    <title>
                        {productInfo.meta_info
                            ? productInfo.meta_info.title
                            : productInfo.name + " from Foodversy"}
                    </title>
                    <meta
                        name="description"
                        content={
                            productInfo.meta_info !== undefined
                                ? productInfo.meta_info.description
                                : productInfo.description.substring(
                                      0,
                                      productInfo.description.indexOf(".")
                                  )
                        }
                    />
                    <meta
                        name="keywords"
                        content={StringHelper.generateKeywordsFromTags(
                            productInfo.tags
                        )}
                    ></meta>
                    <link
                        rel="canonical"
                        href={"https://www.foodversy.com" + location.pathname}
                    ></link>
                </Helmet>
                <Container className="row" fluid>
                    <div className="flex-col">
                        <Container>
                            <div className="col-xl-7 col-lg-7 col-md-10">
                                <div className="project-details-image">
                                    <ImageContainer
                                        imgUrl={
                                            productInfo.image_url
                                                ? productInfo.image_url[0]
                                                : [
                                                      "https://res.cloudinary.com/da8slr0ic/image/upload/v1632763625/foodsfromturkey/Logo_mfxc7t.jpg",
                                                  ]
                                        }
                                        maxHeight={"500px"}
                                        height="425px"
                                        alt={productInfo.name}
                                    />
                                    <div className="project-d-info">
                                        <div className="project-d-info-title">
                                            <h5>Product Info</h5>
                                            <ViewCount
                                                viewCount={viewCount}
                                            ></ViewCount>
                                        </div>

                                        <div className="project-info-lists">
                                            <div className="d-info-item ">
                                                <h6>Category</h6>
                                                <p>
                                                    {productInfo.category || ""}
                                                </p>
                                            </div>
                                            <div className="d-info-item">
                                                <h6>Name</h6>
                                                <p>{productInfo.name || ""}</p>
                                            </div>
                                            <div className="d-info-item">
                                                <h6>Origin</h6>
                                                <p>Turkiye</p>
                                            </div>
                                            <div className="d-info-item">
                                                <h6>Status of Product</h6>
                                                <p>{productInfo.status} </p>
                                            </div>
                                        </div>
                                        <div className="project-d-btn">
                                            <button
                                                className="tp-btn-h1 p-d-btn"
                                                onClick={scrollToReqForm}
                                            >
                                                Request Now
                                                <i className="fal fa-long-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>

                    <Row id="tags">
                        {productInfo.tags.map(function (tag, index) {
                            return (
                                <Link
                                    key={"product-tag-" + index}
                                    id="tag"
                                    to={"/search/" + tag}
                                >
                                    {tag}
                                </Link>
                            );
                        })}
                    </Row>
                    <div className="project-d-descriptiopn">
                        <Container fluid>
                            <Row className="description-row">
                                <Col xs={12} className="description-title">
                                    <h1>{productInfo.name}</h1>
                                </Col>
                                <Col xs={12}>
                                    <h2>All the details you need to know</h2>
                                </Col>
                                <Col xs={12}>
                                    <p>{productInfo.description}</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
                <Row style={{ marginLeft: "2rem", marginRight: "2rem" }}>
                    <Col lg={8} style={{ marginRight: "auto" }}>
                        <Row className="floating-section-rows">
                            <Col className="display-request-col">
                                <Container>
                                    <Row>
                                        <Col lg={8}>
                                            <h2>
                                                We Work With Customers <br />
                                                All Over The World
                                            </h2>
                                            <h3>
                                                Offering wide range of logistic
                                                solutions including door to door
                                                delivery
                                            </h3>
                                            <p>
                                                With 27 years of experience in
                                                the sector, Foodversy provides
                                                many logistic solutions for your
                                                deliveries for any agricultural
                                                product that can be found in
                                                Turkey .
                                            </p>
                                        </Col>
                                    </Row>
                                    <DataTableBase
                                        data={productRequests}
                                        columns={columns}
                                        header={
                                            "Requests for " + productInfo.name
                                        }
                                    ></DataTableBase>
                                </Container>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4} className="display-request-img-col">
                        <div className="display-request-img-wrapper">
                            <img
                                src={shippingPhoto}
                                alt="Global losgistics options for most countries"
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: "2rem" }}>
                    <Col lg={4} className="request-form-img-col">
                        <div className="request-form-img-wrapper">
                            <img src={requestInfoPhoto} alt="request-form" />
                        </div>
                    </Col>
                    <Col lg={8} style={{ marginLeft: "auto" }}>
                        <Row ref={reqFormRef}>
                            <Col className="req-form-col">
                                <Row>
                                    <Request
                                        productName={productInfo.name}
                                        image_url={
                                            productInfo.image_url[0] !==
                                            undefined
                                                ? productInfo.image_url[0]
                                                : ""
                                        }
                                    />
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row id="product-mid-row">
                    <Col>
                        <div className="CardsRow">
                            <ProductCards
                                header={"Related Products"}
                                subHeader={
                                    "We have listed the related products that may interest you"
                                }
                                productList={productInfo.related_products}
                            ></ProductCards>
                        </div>

                        <Row id="titlerow">
                            <h1>{noResult === true ? "" : "Related News"}</h1>
                        </Row>

                        <Row className="CardsRow">
                            <SearchLandingNews
                                noResultHandler={handleNoResult}
                                queryString={
                                    productInfo.tags[0] !== undefined
                                        ? productInfo.tags[0]
                                        : productInfo.name
                                }
                                positiveTitle="Related News"
                                selectedNewsHandler={selectedNewsHandler}
                            ></SearchLandingNews>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    else return <Spinner></Spinner>;

    //#endregion
}
