import Api from "./index";

const CountApi = {
    /**
     * Gets all counts
     * @returns
     */
    getAllDbInfo() {
        return Api.getPromise(`/admin/db-info`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting customer infos: " + err);
                return [];
            });
    },
};

export default CountApi;
