import React from 'react'
import {StickyShareButtons} from 'sharethis-reactjs'

export default function ShareButtons ({url,shareImage,shareMessageText,shareTitle}) {

    return(
        <StickyShareButtons
          config={{
            alignment: 'left',    // alignment of buttons (left, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            hide_desktop: false,  // hide buttons on desktop (true, false)
            labels: 'counts',     // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            min_count: 0,         // hide react counts less than min_count (INTEGER)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'linkedin',
              'facebook',
              'twitter',
              'pinterest',
              'email'
            ],
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: true,     // show/hide the total share count (true, false)
            show_mobile: true,    // show/hide the buttons on mobile (true, false)
            show_toggle: true,    // show/hide the toggle buttons (true, false)
            size: 48,             // the size of each button (INTEGER)
            top: 160,             // offset in pixels from the top of the page
 
            // OPTIONAL PARAMETERS
            url: url, // (defaults to current url)
            image: shareImage ,  // (defaults to og:image or twitter:image)
            description: shareMessageText,       // (defaults to og:description or twitter:description)
            title: shareTitle,            // (defaults to og:title or twitter:title)
            message: shareMessageText,     // (only for email sharing)
            subject: shareTitle,  // (only for email sharing)
            username: 'Foodversy' // (only for twitter sharing)
 
          }}
        />
    )
}
