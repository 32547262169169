import React from "react";

//style
import "./Fairs.css";
import "./FairsCarousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//helpers
import { Carousel } from "react-responsive-carousel";
import { createFairCards } from "./Fairs";
import useFairs from "../../hooks/useFairs";
import Header from "../SubComponents/Header";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";

export default function FairsCarousel({ header, subHeader }) {
    const { fairs } = useFairs();

    if (fairs?.length > 0)
        return (
            <>
                <section id="fairs-container" className="fairs-container">
                    <div className="container">
                        <div className="section-title">
                            <Header
                                header={header}
                                subheader={subHeader}
                                icon={faCannabis}
                            ></Header>
                        </div>

                        <Carousel className="fairs-carousel" showThumbs={false}>
                            {createFairCards({
                                allFairs: fairs.slice(-3),
                                type: "carousel",
                            })}
                        </Carousel>
                    </div>
                </section>
            </>
        );
    else return "";
}
