import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
// style
import "./AboutUs.css";
import NumDisplayCard from "../SubComponents/NumDisplayCard/NumDisplayCard";
import { useState } from "react";
import { useEffect } from "react";
import CountApi from "../../api/countApi";

export default function AboutUsComp() {
    const [counts, setCounts] = useState({
        customerCount: 19,
        requestCount: 19,
        supplierCount: 2,
        productCount: 611,
    });

    useEffect(() => {
        CountApi.getAllDbInfo()
            .then((res) => setCounts(res.data))
            .catch((err) => {
                console.log("An error occured during gettin count info " + err);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    Discover Foodversy - Your Trusted Partner in Turkish Food
                    Trade"
                </title>
                <meta
                    name="google-site-verification"
                    content="mnhwhxoX-fHyWoCiV5atQAo1yStpJ11i_njTMKp10hE"
                />
                <link
                    rel="canonical"
                    href={"https://www.foodversy.com/aboutus"}
                ></link>
                <meta
                    name="description"
                    content="Learn about Foodversy's mission, values, and journey in becoming a leading platform for trading premium Turkish foods globally. Understand our commitment to quality and service."
                />
            </Helmet>
            <Container fluid className="about-us-main-container">
                <Row className={"row-l"}>
                    <Col lg={6} style={{ paddingBlock: "2rem" }}>
                        <h1>
                            Foodversy: Empowering Turkish Agriculture for a
                            Sustainable Future
                        </h1>
                        <h3>
                            Revolutionizing the Way Farmers, Suppliers, and
                            Buyers Connect and Transact
                        </h3>
                        <p>
                            Welcome to our Turkish Agricultural Marketplace! We
                            are a pioneering platform that connects farmers,
                            suppliers, and buyers, revolutionizing the way
                            agricultural products are bought and sold in Turkey.
                            Our mission is to empower farmers and promote
                            sustainable agricultural practices while providing
                            convenient access to high-quality, locally sourced
                            produce. With our user-friendly interface and
                            advanced features, we create a seamless experience
                            for both sellers and buyers, facilitating efficient
                            transactions and fostering long-term relationships.
                            We are passionate about supporting the growth of
                            Turkey's agricultural sector and driving economic
                            prosperity for all stakeholders involved. Join us on
                            this exciting journey as we transform the
                            agricultural landscape and contribute to a
                            healthier, more sustainable future.
                        </p>
                    </Col>
                    <Col lg={6}>
                        <div className="img-wrapper">
                            <img
                                src={
                                    "https://res.cloudinary.com/da8slr0ic/image/upload/v1688919332/dan-meyers-IQVFVH0ajag-unsplash_1_1_brqnuj.webp"
                                }
                                alt={"Turkish Agricultural Marketplace"}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="aboutus-cards-row">
                    <Col xs={12}>
                        <h2
                            style={{
                                textAlign: "center",
                                textTransform: "capitalize",
                            }}
                        >
                            Our network expands each day by reliable suppliers
                            and satisfied customers
                        </h2>

                        <p>
                            Our diverse range of agricultural products spans
                            from fresh fruits and vegetables to grains and
                            livestock feed, catering to the needs of clients
                            across various sectors such as restaurants,
                            supermarkets, and food processing companies. We are
                            dedicated to building strong relationships with both
                            our clients and suppliers, ensuring that our clients
                            receive the highest quality products while our
                            suppliers benefit from fair and sustainable
                            partnerships that support their growth and success.
                            Through our rigorous quality control measures and
                            strict adherence to industry standards, we guarantee
                            that our clients receive only the finest
                            agricultural products, sourced from trusted
                            suppliers who share our commitment to excellence and
                            ethical practices.
                        </p>
                    </Col>
                    <Col>
                        <NumDisplayCard
                            label="Products"
                            numVal={counts.productCount}
                        />
                    </Col>

                    <Col>
                        <NumDisplayCard
                            label="Suppliers"
                            numVal={counts.supplierCount}
                        />
                    </Col>
                    <Col>
                        <NumDisplayCard
                            label="Clients"
                            numVal={counts.customerCount}
                        />
                    </Col>
                    <Col>
                        <NumDisplayCard
                            label="Requests"
                            numVal={counts.requestCount}
                        />
                    </Col>
                </Row>
                <Row className={"row-r"}>
                    <Col lg={6}>
                        <h2>
                            Reliable Transportation Solutions for Timely
                            Delivery of Premium Agricultural Goods
                        </h2>
                        <h3>
                            Ensuring Freshness and Quality Every Step of the Way
                        </h3>
                        <p>
                            At Foodversy, we take great pride in our commitment
                            to delivering exceptional transportation solutions
                            for the agricultural goods we offer. We understand
                            the importance of maintaining the freshness,
                            quality, and integrity of these products throughout
                            their journey from farms to markets. Our dedicated
                            transportation team consists of experienced
                            professionals who prioritize efficiency,
                            reliability, and timeliness. Leveraging our
                            extensive network of carriers and strategic
                            partnerships, we ensure that the transportation
                            process is seamless and streamlined. From
                            small-scale local deliveries to nationwide
                            distribution, we have the capability to handle
                            various volumes and meet diverse logistical
                            requirements.
                        </p>
                    </Col>
                    <Col lg={6}>
                        <div className="img-wrapper">
                            <img
                                src={
                                    "https://res.cloudinary.com/da8slr0ic/image/upload/v1688923017/istockphoto-150487537-640x640_onjtmo.webp"
                                }
                                alt={"Logistics solutions"}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
