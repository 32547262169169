import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./Toolbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faPlus,
    faRotateRight,
    faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Toolbar for datatable
 * Add and Delete button handlers are required for rendering them
 * Sorting Row Elements are required to be passed in order to sort by those fields
 * @param {*} title
 * @param {*} handleAdd
 * @param {*} handleDelete
 * @param {*} sortingRowElements
 * @returns
 */
export default function Toolbar({
    title,
    handleAdd,
    handleDelete,
    reload,
    filterRowElements,
}) {
    return (
        <Container fluid className={"toolbar-main-container"}>
            <Row>
                <Col>
                    <h4>{title}</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    {handleAdd ? (
                        <Button
                            id={"toolbar-add-btn"}
                            className={"toolbar-btn"}
                            onClick={handleAdd}
                        >
                            <Row>
                                <Col
                                    xs={4}
                                    style={{
                                        padding: "0",
                                        textAlign: "center",
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Col>
                                <Col
                                    xs={8}
                                    style={{
                                        padding: "0",
                                        textAlign: "center",
                                    }}
                                >
                                    Add
                                </Col>
                            </Row>
                        </Button>
                    ) : (
                        ""
                    )}
                    {handleDelete ? (
                        <Button
                            id={"toolbar-delete-btn"}
                            className={"toolbar-btn"}
                            onClick={handleDelete}
                        >
                            <Row>
                                <Col
                                    xs={4}
                                    style={{
                                        padding: "0",
                                        textAlign: "center",
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Col>
                                <Col
                                    xs={8}
                                    style={{
                                        padding: "0",
                                        textAlign: "center",
                                    }}
                                >
                                    Delete
                                </Col>
                            </Row>
                        </Button>
                    ) : (
                        ""
                    )}
                    {reload ? (
                        <Button
                            id={"toolbar-reload-btn"}
                            className={"toolbar-btn"}
                            onClick={reload}
                        >
                            <Row>
                                <Col
                                    xs={4}
                                    style={{
                                        padding: "0",
                                        textAlign: "center",
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRedoAlt} />
                                </Col>
                                <Col
                                    xs={8}
                                    style={{
                                        padding: "0",
                                        textAlign: "center",
                                    }}
                                >
                                    Reload
                                </Col>
                            </Row>
                        </Button>
                    ) : (
                        ""
                    )}
                </Col>
            </Row>
            <Row>{filterRowElements}</Row>
        </Container>
    );
}
