import "./components/component-css/App.css";
import Home from "./components/HomeComponents/Home";
import RequestForm from "./components/Request";
// import Navigation from "./components/Navbar";
import Product from "./components/Product";
import TestComponent from "./components/TestComponent";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import News from "./components/News";
import Post from "./components/Post";
import Category from "./components/Category";
import SearchLanding from "./components/Search/SearchLanding";
import Footernew from "./components/Footernew";
import Supplier from "./components/Supplier";
import React, { useEffect, useState } from "react";
import ProductApi from "./api/productApi";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "aos/dist/aos.css";
import Aos from "aos";
import "./App.css";
import "./components/component-css/Buttons.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Container } from "react-bootstrap";

import NotFound from "./components/NotFound";
import { Helmet } from "react-helmet-async";
import ScrollToTop from "./helpers/ScrollToTop";
import MainCategories from "./components/MainCategories";
import Navbar from "./components/Navbar/NewNavbar";
import SearchPage from "./components/Search/SearchPage";
import SupplierForm from "./components/SupplierForm/SupplierForm";
import AllProductsPage from "./components/AllProducts";
import AboutUsComp from "./components/AboutUs/AboutUs";
import FairPost from "./components/Partnership/FairPost";
import Fairs from "./components/Partnership/Fairs";
import SitemapGen from "./components/SitemapGen";
import RequestPage from "./components/RequestPage";

require("dotenv").config();

function App() {
    const [products, setProducts] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedNews, setSelectedNews] = useState();
    const [queryString, setQueryString] = useState();
    const [searchbarStatus, setSearchbarStatus] = useState(false);

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    useEffect(() => {
        ProductApi.getProducts()
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => console.log("Error: ", err));
        ProductApi.getPopularProducts()
            .then((res) => {
                setPopularProducts(res.data);
            })
            .catch((err) => console.log("Error Popular Productsxphom: ", err));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    function handleQueryString(e) {
        setQueryString(e);
    }
    function handleSelectedProduct(e) {
        setSelectedProduct(e);
    }

    // function handleSelectedCategory(e) {
    //   setSelectedCategory(e);
    // }

    function handleSelectedNews(e) {
        setSelectedNews(e);
    }

    const handleSearchbar = (status) => {
        setSearchbarStatus(status);
    };
    return (
        <div className="App">
            <Router>
                <ScrollToTop />
                <Helmet>
                    <title>
                        Foodversy: Gateway to Quality Turkish Food Imports
                    </title>
                </Helmet>
                <SearchPage
                    isOpen={searchbarStatus}
                    setIsOpen={handleSearchbar}
                    queryStringHandler={handleQueryString}
                ></SearchPage>
                <Navbar searchBarOpener={handleSearchbar}></Navbar>
                {/* <Navigation
          productsFromApp={products}
          selectedCategoryHandler={handleSelectedCategory}
          queryStringHandler={handleQueryString}
        /> */}
                <Container id="main-container" fluid>
                    <Switch>
                        <Route path="/" exact>
                            <Home
                                selectedProductHandler={handleSelectedProduct}
                                selectedNewsHandler={handleSelectedNews}
                                popularProducts={
                                    popularProducts !== undefined
                                        ? popularProducts
                                        : [{ name: "" }]
                                }
                                products={
                                    products !== undefined
                                        ? products
                                        : [{ name: "" }]
                                }
                            />
                        </Route>

                        <Route path="/home">
                            <Home
                                selectedProductHandler={handleSelectedProduct}
                                selectedNewsHandler={handleSelectedNews}
                                popularProducts={
                                    popularProducts !== undefined
                                        ? popularProducts
                                        : [{ name: "" }]
                                }
                                products={
                                    products !== undefined
                                        ? products
                                        : [{ name: "" }]
                                }
                            />
                        </Route>
                        <Route path="/aboutUs">
                            <AboutUsComp></AboutUsComp>
                        </Route>
                        <Route path="/requestForm">
                            <RequestPage />
                        </Route>
                        <Route path="/product/:name">
                            <Product
                                selectedProduct={selectedProduct}
                                selectedNewsHandler={handleSelectedNews}
                                queryString={queryString}
                            />
                        </Route>
                        <Route path="/main-categories/:categoryname">
                            <MainCategories />
                        </Route>
                        <Route path="/category/:name">
                            <Category />
                        </Route>
                        <Route path="/adminPanel">
                            <AdminPanel />
                        </Route>
                        <Route path="/search/:query">
                            <SearchLanding
                                selectedProductHandler={handleSelectedProduct}
                                selectedNewsHandler={handleSelectedNews}
                                queryString={queryString}
                            />
                        </Route>
                        <Route path="/news">
                            <News selectedNewsHandler={handleSelectedNews} />
                        </Route>
                        <Route path="/post/:title">
                            <Post selectedNews={selectedNews} />
                        </Route>
                        <Route path="/supplier">
                            <Supplier />
                        </Route>

                        <Route path="/supplier-form/:type">
                            <SupplierForm />
                        </Route>
                        <Route path="/all-products">
                            <AllProductsPage />
                        </Route>
                        <Route path="/fairs">
                            <Fairs />
                        </Route>
                        <Route path="/fair/:name">
                            <FairPost />
                        </Route>

                        {/* <Route path="/test_url">
                            <TestComponent />
                            {<SitemapGen></SitemapGen>}
                        </Route> */}
                        <Route component={NotFound} />
                    </Switch>
                </Container>
            </Router>
            <Footernew />
        </div>
    );
}

export default App;
