import "../component-css/AboutUs.css";

import photo from "../../assets/food-background-food-concept-with-various-tasty-fresh-ingredients-cooking-italian-food-ingredients-v.webp";
import photo2 from "../../assets/pngwing.com.webp";

import { Helmet } from "react-helmet-async";

export default function AboutUs({ ref }) {
    return (
        <>
          

            <div>
                <div className="tp-about-area about-area-2">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xxl-4 col-xl-4 col-lg-4">
                                <div className="tp-section-wrap">
                                    <span>
                                        <i className="flaticon-grass"></i>
                                    </span>
                                    <h3 className="tp-section-title">
                                        Trustworthy Platform
                                    </h3>
                                </div>
                            </div>
                            <div className="col-xl-1 col-lg-1 d-none d-lg-block">
                                <span className="line-bar"></span>
                            </div>
                            <div className="col-xxl-7 col-xl-7 col-lg-7 align-items-end">
                                <div className="tp-about-content-1">
                                    <p>
                                        The world will face a type of inflation
                                        that it has not seen for a long time.
                                        Cost increase as a result of the
                                        increase in raw material prices and the
                                        shift in the supply chain from the
                                        cheapest producer to the most reliable
                                        producer.
                                    </p>
                                    <div className="author-info mt-20">
                                        <div className="author-content">
                                            <h5>Furkan Akkurt</h5>
                                            <span>Co-Founder</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tp-features-list-area mb-90">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-xl-7 col-lg-8">
                                <div className="tp-features-list">
                                    <div className="tp-list-item mb-30">
                                        <h5 className="features-title">
                                            Agriculture <br /> & Food Products
                                        </h5>
                                    </div>
                                    <div className="tp-list-item mb-30">
                                        <i className="flaticon-vegetable"></i>
                                        <h5 className="features-title">
                                            Quality Control{" "}
                                        </h5>
                                    </div>
                                    <div className="tp-list-item mb-30">
                                        <h5 className="features-title">
                                            Secure Payment
                                        </h5>
                                    </div>
                                    <div className="tp-list-item mb-30">
                                        <i className="flaticon-house"></i>
                                        <h5 className="features-title">
                                            Warehouse <br /> & Stock
                                        </h5>
                                    </div>
                                    <div className="tp-list-item mb-30">
                                        <i className="flaticon-tractor-1"></i>
                                        <h5 className="features-title">
                                            Professional Tools
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="orgainc-product pt-120 pb-120 h2-gray-bg position-relative">
                    <div className="overlay-bg">
                        <img
                            src={photo2}
                            alt="about foodversy"
                            style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                            }}
                        ></img>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6">
                                <div className="organic-image">
                                    <img
                                        src={photo}
                                        className="img-fluid"
                                        alt="organic agriculture"
                                    />
                                    <div className="organic-meta">
                                        <h5>27</h5>
                                        <span>Years Of Experience</span>
                                        <i className="fal fa-arrow-up"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="organic-product-content pl-80 mt-50">
                                    <div className="tp-section-wrap">
                                        <span>
                                            <i className="flaticon-grass"></i>
                                        </span>
                                        <h3 className="tp-section-title">
                                            Organic Agriculture & Organic Food
                                            Products{" "}
                                        </h3>
                                        <p>
                                            Foodversy platform is strengthening
                                            supply chain resilience, improving
                                            quality of product by giving high
                                            quality service and finally lowering
                                            prices.
                                            <br />
                                            We offer a product catalog that you
                                            will not find anywhere else, while
                                            reducing costs and risk of loss with
                                            ai-based solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
