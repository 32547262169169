import { openUploadWidget } from "./utils/CloudinaryService";

import { Button } from "react-bootstrap";
import styled from "styled-components";

const UploadButton = styled.button`
    border-radius: 0;
    background-color: var(--fixed-primary-btn);
    padding-inline: 1rem;

    border: 1px solid var(--fixed-primary-btn);
    font-family: "Poppins" sans-serif;
    color: white;
    font-weight: 500;
    transition: 0.3s;
    max-width: 150px;
    height: 40px;

    :hover {
        border: 1px solid var(--fixed-color-dark);
        background-color: white !important;
        color: var(--fixed-color-dark) !important;
    }
`;

const ImageUpload = (props) => {
    const action = props.callback
        ? (error, result) => {
              if (!error && result.event === "success") {
                  props.callback(result.info.url);
              }
          }
        : (error, result) => {
              if (!error && result.event === "success") {
                  props.setParentImageList(
                      props.parentImageList
                          ? [...props.parentImageList, result.info.url]
                          : result.info.url
                  );
              }
          };

    const uploadImageWidget = () => {
        let myUploadWidget = openUploadWidget(
            {
                cloudName: process.env.REACT_APP_CLOUDINARY_NAME || "da8slr0ic",
                uploadPreset:
                    process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET ||
                    "lsbcei52",
                folder:
                    process.env.REACT_APP_CLOUDINARY_FOLDERNAME ||
                    "foodsfromturkey",
                maxImageWidth: 1200,
                cropping: true,
                secure: true,
                uploadPreset: "lsbcei52",
                sources: ["local", "url"],
            },
            action
        );
        myUploadWidget.open();
    };

    return (
        <UploadButton
            type="button"
            className="photo-upload-btn"
            onClick={uploadImageWidget}
        >
            Upload Image
        </UploadButton>
    );
};

export default ImageUpload;
