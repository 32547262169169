import createLink from "../../helpers/CreateLink";
import { Row, Col } from "react-bootstrap";
import "./Fairs.css";
import Header from "../SubComponents/Header";
import { faAngleRight, faCannabis } from "@fortawesome/free-solid-svg-icons";
import useFairs from "../../hooks/useFairs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function createFairCards({ allFairs, type = "default" }) {
    let temp = [...allFairs].reverse();
    if (Array.isArray(allFairs))
        return (
            allFairs &&
            temp.map(function (item) {
                return (
                    <a
                        href={createLink(
                            "/fair/",
                            item.name + `?key=${item._id}`
                        )}
                        className="col-lg-12 col-md-12 align-items-stretch mb-4"
                        data-aos="fade-right"
                        data-aos-delay="100"
                        key={item._id}
                        id={`fair-${item._id}`}
                        style={{ textDecoration: "none" }}
                    >
                        <div className="row fairs-icon-box">
                            <div className="fairs-image">
                                <span className="image-span">
                                    {item.image_url !== "" ? (
                                        <img
                                            src={item.image_url}
                                            alt={item.title}
                                            loading="lazy"
                                            width={"100%"}
                                            height={"100%"}
                                            style={{ objectFit: "cover" }}
                                        ></img>
                                    ) : (
                                        ""
                                    )}
                                </span>
                            </div>
                            <div className="content-col col-lg-9">
                                <Row>
                                    <Col xs={12}>
                                        <h3>{item.title}</h3>
                                    </Col>
                                    <Col xs={12}>
                                        <p>{item.card_context}</p>
                                    </Col>
                                </Row>
                            </div>
                            {type === "default" ? (
                                <div
                                    className="col-lg-1 arrow-right"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faAngleRight}
                                        size="4x"
                                    ></FontAwesomeIcon>
                                </div>
                            ) : (
                                console.log(type)
                            )}
                        </div>
                    </a>
                );
            })
        );
    else if (allFairs !== undefined)
        return (
            <a
                href={createLink("/post/", allFairs.title)}
                className="col-lg-4 col-md-6 align-items-stretch mb-4"
                data-aos="zoom-in"
                data-aos-delay="100"
                style={{ textDecoration: "none" }}
            >
                <div className="icon-box">
                    <div className="image">
                        <img
                            src={allFairs.image_url}
                            alt={allFairs.title}
                            width={"100%"}
                            height={"100%"}
                            style={{ objectFit: "cover" }}
                        ></img>
                    </div>
                    <h4>{allFairs.title}</h4>
                    <p>{allFairs.card_context}</p>
                </div>
            </a>
        );
    else return <div></div>;
}

const dummyFairs = [
    {
        _id: 123123,
        title: "Lorem Ipsum",
        image_url: "https://www.xtrafondos.com/wallpapers/es-batman-9331.jpg",
        card_context:
            "Consectetur ullamco ut et sit in est duis ad aliqua culpa dolore excepteur.",
        content: "",
    },
    {
        _id: 123124,
        title: "Lorem Ipsum Dolor",
        image_url: "",
        card_context: "Et est aute nostrud dolor culpa nulla et incididunt.",
        content: "",
    },
];

export default function Fairs({
    header = "Upcoming Fairs",
    subHeader = "You may see the upcoming fairs related to your sector",
}) {
    const { fairs } = useFairs();
    return (
        <>
            <section id="fairs-container" className="fairs-container">
                <div className="container">
                    <Header
                        header={header}
                        subheader={subHeader}
                        icon={faCannabis}
                    ></Header>
                    {createFairCards({ allFairs: fairs, type: "default" })}
                </div>
            </section>
        </>
    );
}

export { createFairCards };
