import React from "react";
import "./component-css/Supplier.css";
import image1 from "../assets/farmers-market.jpg";
import image2 from "../assets/pro-photo.jpg";
function Suplier() {
    return (
        <section className="light sup_body">
            <div className="container py-2">
                <div className="h1 text-center text-dark" id="pageHeaderTitle">
                    Subscription Plans
                </div>

                <article className="postcard light blue">
                    <div className="postcard__img_link">
                        <img
                            className="postcard__img"
                            src={image1}
                            alt="Standart Subscription"
                        />
                    </div>
                    <div className="postcard__text t-dark">
                        <h1 className="postcard__title blue">Standart</h1>
                        <div className="postcard__subtitle small">
                            <i className="fas fa-calendar-alt mr-2"></i>How it
                            works ?
                        </div>
                        <div className="postcard__bar"></div>
                        <div className="postcard__preview-txt">
                            The standart subscription is a free plan which
                            directs related requests to you via email. The
                            information of the request owner will not be shared
                            with you and you will not contact them. You will
                            only be intouch with Foodversy. Any invoice due to a
                            sale will be issued to Foodversy.
                            <br />
                            <br />
                            What are you waiting?
                        </div>
                        <ul className="postcard__tagbox">
                            <li className="tag__item play blue">
                                <a
                                    className="subs-button"
                                    href="/supplier-form/0"
                                >
                                    Become a supplier now!
                                </a>
                            </li>
                        </ul>
                    </div>
                </article>
                <article className="postcard light red">
                    <div className="postcard__img_link">
                        <img
                            className="postcard__img"
                            src={image2}
                            alt="Pro Subscription"
                        />
                    </div>
                    <div className="postcard__text t-dark">
                        <h1 className="postcard__title blue">Pro</h1>
                        <div className="postcard__subtitle small">
                            <i className="fas fa-calendar-alt mr-2"></i>How it
                            works ?
                        </div>
                        <div className="postcard__bar"></div>
                        <div className="postcard__preview-txt">
                            The pro subscription is a chargable plan which
                            directs related requests to you via email. The
                            information of the request owners will be shared
                            with you. Any invoice due to a sale will be issued
                            to Foodversy.
                            <br />
                            <br />
                            What are you waiting?
                        </div>
                        <ul className="postcard__tagbox">
                            <li className="tag__item play red">
                                <a
                                    className="subs-button"
                                    href="/supplier-form/1"
                                >
                                    <i className="fas fa-play mr-2"></i>Become a
                                    supplier now!
                                </a>
                            </li>
                        </ul>
                    </div>
                </article>
            </div>
        </section>
    );
}

export default Suplier;
