import React, { useState } from "react";
import CategoryApi from "../api/categoryApi";
import { useEffect } from "react";

/**
 * Fetches category by given title
 * @param {*} title
 * @returns
 */
export default function useCategory(title) {
    const [category, setCategory] = useState(
        title !== undefined && title !== ""
            ? {
                  name: "",
                  parent_category: "",
                  description: "",
              }
            : []
    );

    const clearString = (str) => {
        var tempArray = str.split("-");
        for (let i = 0; i < tempArray.length; i++) {
            tempArray[i] =
                tempArray[i].charAt(0).toUpperCase() + tempArray[i].slice(1);
        }
        str = tempArray.join(" ");
        return str;
    };

    useEffect(() => {
        const handleCategory = (e) => {
            setCategory(e);
        };
        if (title !== undefined && title !== "")
            CategoryApi.getCategoryByTitle(clearString(title))
                .then((e) => {
                    handleCategory(e.data);
                })
                .catch((e) => {
                    alert("An error occured during getting category info: ", e);
                });
        else {
            CategoryApi.getCategories()
                .then((e) => {
                    handleCategory(e.data);
                })
                .catch((e) => {
                    alert("Error while getting categories: ", e);
                });
        }
    }, [title]);

    return category;
}
