import React, { useState, useEffect, useMemo } from "react";
import {
    Button,
    FormCheck,
    Container,
    Row,
    Col,
    InputGroup,
    FormControl,
} from "react-bootstrap";
import DataTableBase from "../../SubComponents/DataTable/DataTable";
import RequestApi from "../../../api/requestApi";
import DetailModal from "./DetailModal";
import Select from "react-select";
import Toolbar from "../../SubComponents/DataTable/Toolbar";
import ProductApi from "../../../api/productApi";
import { ArrayHelper } from "../../../helpers/ArrayHelper";

/**
 * A toolbar for the data table
 * requires filter data handler to pass values for filtering
 * @param {*} handleFilterData
 * @returns
 */
function ToolbarFilterRow({
    handleFilterData,
    productsToOptions,
    deleteFilterDataField,
}) {
    return (
        <Container fluid>
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col xs={12}>Filter By:</Col>

                <Col lg={3} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Email Address"
                            id="email"
                            aria-label="Email Address"
                            onChange={(e) => {
                                handleFilterData({
                                    id: e.target.id,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </Col>

                <Col xs={12} lg={6}></Col>
                <Col xs={12} lg={6}>
                    <Select
                        options={productsToOptions}
                        placeholder="Product"
                        onChange={(e) => {
                            if (e === null)
                                deleteFilterDataField("product_name");
                            else if (e.value === undefined)
                                deleteFilterDataField("product_name");
                            else
                                handleFilterData({
                                    id: "product_name",
                                    value: e.value.name,
                                });
                        }}
                        isClearable={true}
                    ></Select>
                </Col>
            </Row>
        </Container>
    );
}

export default function RequestControl() {
    //States
    const [products, setProducts] = useState([]);
    const [requests, setRequests] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [filterData, setFilterData] = useState({});
    const [pending, setPending] = React.useState(true);
    const [gridData, setGridData] = useState([]);
    //useEffects
    useEffect(() => {
        RequestApi.getRequests()
            .then((res) => {
                setRequests(res.data);
            })
            .catch((err) => console.log(err));

        ProductApi.getProducts()
            .then((res) => {
                handleProducts(res.data);
            })
            .catch((err) => {
                console.log("An error occured while fetching products:  ", err);
            });
    }, []);

    useEffect(() => {
        prepareGridData();
    }, [requests, filterData]);

    const handleProducts = (e) => {
        setProducts(e);
    };
    const handleIsModalOpen = (e) => {
        setIsModalOpen(e);
    };

    const handleSelectedUser = (e) => {
        setSelectedUser(e);
    };

    //handlers
    /**
     * send a value object to add to filter data
     * @param {*} newValue
     */
    const handleFilterData = (newValue) => {
        setFilterData({ ...filterData, [newValue.id]: newValue.value });
    };

    const deleteFilterDataField = (fieldName) => {
        let temp = filterData;
        delete temp[fieldName];
        setFilterData({ ...temp });
    };

    const prepareGridData = () => {
        const timeout = setTimeout(() => {
            setGridData(ArrayHelper.filterObjectArray(requests, filterData));
            setPending(false);
        }, 100);
        return () => clearTimeout(timeout);
    };
    //Maps products to fill react-select component
    const productsToOptions = useMemo(() => {
        if (products === undefined || products === [])
            //if state did not mount yet, do not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
        });
        return options;
    }, [products]);

    //Create Column Model
    const columns = [
        {
            selector: (row) => row.email,
            name: "Email",
            maxWidth: "250px",
        },
        {
            selector: (row) => row.product_name,
            maxWidth: "200px",
            name: "Product Name",
        },
        {
            selector: (row) => row.message,
            name: "Content",
            maxWidth: "800px",
        },
        {
            maxWidth: "200px",
            selector: (row) => row.country,
            name: "Country",
        },
        {
            maxWidth: "150px",
            selector: (row) => row.is_responded,
            name: "Is Responded?",
        },
    ];

    //row Events for table
    const rowEvents = {
        onDoubleClick: (row, event) => {
            handleSelectedUser(row);
            handleIsModalOpen(true);
        },
    };
    return (
        <Container fluid id="request-container">
            <DetailModal
                user={selectedUser}
                handleSelectedUser={handleSelectedUser}
                isModalOpen={isModalOpen}
                handleIsModalOpen={handleIsModalOpen}
            ></DetailModal>
            <DataTableBase
                toolbar={
                    <Toolbar
                        title={"Request List"}
                        filterRowElements={
                            <ToolbarFilterRow
                                handleFilterData={handleFilterData}
                                deleteFilterDataField={deleteFilterDataField}
                                productsToOptions={productsToOptions}
                            ></ToolbarFilterRow>
                        }
                    ></Toolbar>
                }
                data={gridData}
                columns={columns}
                onRowDoubleClicked={rowEvents.onDoubleClick}
                pointerOnHover="pointer"
                pending={pending}
            ></DataTableBase>
        </Container>
    );
}
