const mail_options = [
    {
        value: 0,
        label: "Introduction Mail",
    },
    {
        value: 1,
        label: "Sea Foods Mail",
    },
    {
        value: 2,
        label: "Fresh Foods Mail",
    },
    {
        value: 3,
        label: "Dried Foods Mail",
    },
    {
        value: 4,
        label: "Herbs Mail",
    },
    {
        value: -1,
        label: "Custom Mail",
    },
];

export default mail_options;
