import Api from './index';

const UserApi = {

    /**
     * Login user
     * @param {*} params { 'username', 'password' }
     * @returns 
     */
    loginUser(params) {
        console.log(params);
        return Api.postPromise(`/users/login`, params).then(res => {
            return res;
        })
        // .catch(err => {
        //     console.log('Error while authorizing: ' + err.message);
        //     return res;
        // });
    },

    /**
     * Check if the user is authorized
     * @param {*} params { 'token' }
     * @returns 
     */
    checkIsAuthorized(params) {
        return Api.postPromise(`/users/auth`, params).then(res => {
            return res;
        })
        // .catch(err => {
        //     console.log('Error while checking auth: ' + err.message);
        //     return res;
        // });
    }
}

export default UserApi;

