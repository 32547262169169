export const ArrayHelper = {
    /**
     * Filters object array based on the values of the given filter object
     * @param {*} filterArray
     * @param {*} filterObject
     * @returns
     */
    filterObjectArray: (filterArray, filterObject) => {
        if (
            filterObject === undefined ||
            Object.keys(filterObject).length === 0
        )
            return filterArray;

        const temp = filterArray.filter(function (item) {
            for (let key in filterObject) {
                if (
                    Array.isArray(item[key]) &&
                    Array.isArray(filterObject[key])
                ) {
                    let existIn = false;

                    for (let filterItem of filterObject[key]) {
                        if (item[key].includes(filterItem._id)) existIn = true;
                    }

                    if (existIn === false) return false;
                } else if (typeof item[key] === "string") {
                    if (
                        !item[key]
                            .toLowerCase()
                            .includes(filterObject[key].toLowerCase())
                    ) {
                        return false;
                    }
                } else if (Array.isArray(item[key]))
                    if (!item[key].includes(filterObject[key].toLowerCase())) {
                        return false;
                    } else if (
                        typeof item[key] === "number" ||
                        typeof item[key] === "boolean"
                    ) {
                        if (!(item[key] === filterObject[key])) {
                            return false;
                        }
                    }
            }
            return true;
        });
        return temp;
    },
};
