import React, { useState, useEffect, useMemo, useCallback } from "react";

//styling and bootstrap components
import "./ModifyModal.css";
import {
    Form,
    Modal,
    ModalBody,
    Button,
    Row,
    Col,
    Container,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import CustomerInfoApi from "../../../api/customerInfoApi";
import Select from "react-select";
import CountrySelector from "../../CountrySelector";
import countryList from "react-select-country-list";
import ProductApi from "../../../api/productApi";

/**
 *
 * @param {*} requires the state for modal
 * @param {*} requires the state for being toggled on or of

 * @returns
 */
export default function ModifyCustomerModal({
    selectedCustomer,
    isModalOpen,
    toggleModal,
    handleSelectedCustomer,
    onSuccess,
}) {
    const [formData, setFormData] = useState({
        company_name: "",
        name: "",
        email_address: "",
        country: "",
        interested_products: [],
    });
    const [id, setID] = useState();
    const [products, setProducts] = useState([]);

    //handler
    const initializeFormData = useCallback(() => {
        let tempObj = { ...selectedCustomer };

        if (
            selectedCustomer !== undefined &&
            Object.keys(selectedCustomer).length > 0
        ) {
            setFormData(tempObj);
            setID(selectedCustomer._id);
        }
    }, [selectedCustomer]);

    //useEffect
    useEffect(() => {
        initializeFormData();
    }, [selectedCustomer, initializeFormData]);

    useEffect(() => {
        ProductApi.getProducts()
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) =>
                alert("An error occured during getting product list: ", err)
            );
    }, []);

    useEffect(() => {}, [formData]);
    const closeModal = () => {
        toggleModal();
        clearFormData();
    };

    //Maps products to fill react-select component
    const productsToOptions = useMemo(() => {
        if (products === undefined || products === [])
            //if state did not mount yet, do not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
        });
        return options;
    }, [products]);

    const handleCustomerInfo = (e) => {
        let tempObj = formData;
        delete tempObj._id;
        delete tempObj.created_at;
        delete tempObj.updated_at;
        delete tempObj.__v;
        e.preventDefault();
        if (
            selectedCustomer !== undefined &&
            selectedCustomer._id !== undefined
        ) {
            CustomerInfoApi.modifyCustomer(id, tempObj)
                .then((res) => {
                    alert(res.message);
                    onSuccess();
                    closeModal();
                })
                .catch((err) => alert("Could not modify: " + err));
        } else {
            CustomerInfoApi.createCustomer(tempObj)
                .then((res) => {
                    alert(res.message);
                    onSuccess();
                    closeModal();
                })
                .catch((err) => alert("Error: " + err));
        }
    };
    const handleFormData = (field, value) => {
        let tempData = formData;
        tempData[field] = value;
        setFormData(tempData);
    };

    const handleSelectedProducts = (e) => {
        let temp = [];
        e.forEach((item) => {
            temp.push(item.value._id);
        });
        
        handleFormData("interested_products", temp);
    };

    const clearFormData = () => {
        setFormData({});
        handleSelectedCustomer({});
    };
    return (
        <Modal
            show={isModalOpen}
            onHide={toggleModal}
            centered
            backdrop="static"
            dialogClassName="modify-modal-main"
        >
            <ModalHeader
                className="btn-wrapper"
                style={{ display: "flex", alignItems: "center" }}
            >
                {selectedCustomer ? "Modify Customer" : "Add Customer"}
                <Button
                    id="close-btn"
                    className="close-btn"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    X
                </Button>
            </ModalHeader>
            <ModalBody>
                <Container className="modify-modal-main-container">
                    <Form onSubmit={(e) => handleCustomerInfo(e)}>
                        <Row className={""}>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="normal"
                                        defaultValue={
                                            selectedCustomer.company_name
                                        }
                                        placeholder="Enter company name"
                                        onChange={(e) => {
                                            handleFormData(
                                                "company_name",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        defaultValue={
                                            selectedCustomer.email_address
                                        }
                                        placeholder="Enter email"
                                        onChange={(e) => {
                                            handleFormData(
                                                "email_address",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Authorized Personnel
                                    </Form.Label>
                                    <Form.Control
                                        defaultValue={selectedCustomer.name}
                                        type="normal"
                                        placeholder="Enter staff name"
                                        onChange={(e) => {
                                            handleFormData(
                                                "name",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <CountrySelector
                                        defaultValue={
                                            selectedCustomer.country
                                                ? {
                                                      value: countryList().getValue(
                                                          selectedCustomer.country
                                                      ),
                                                      label: selectedCustomer.country,
                                                  }
                                                : ""
                                        }
                                        handleCountry={(e) => {
                                            handleFormData("country", e);
                                        }}
                                    ></CountrySelector>
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>Interested Products</Form.Label>
                                    <Select
                                        defaultValue={
                                            selectedCustomer.interested_products
                                                ? productsToOptions.filter(
                                                      (x) => {
                                                          return selectedCustomer.interested_products.includes(
                                                              x.value._id
                                                          );
                                                      }
                                                  )
                                                : ""
                                        }
                                        options={productsToOptions}
                                        isMulti
                                        onChange={(e) =>
                                            handleSelectedProducts(e)
                                        }
                                    ></Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                            <Button
                                id="admin-submit-button"
                                onClick={(e) => handleCustomerInfo(e)}
                            >
                                Add/Modify
                            </Button>
                        </Row>
                    </Form>
                </Container>
            </ModalBody>
        </Modal>
    );
}
