import React from "react";
import RequestForm from "./Request";
import { Helmet } from "react-helmet-async";

export default function RequestPage() {
    return (
        <>
            <Helmet>
                <title>
                    Get Your Custom Quote for Bulk Turkish Foods - Foodversy
                </title>
                <meta
                    name="google-site-verification"
                    content="mnhwhxoX-fHyWoCiV5atQAo1yStpJ11i_njTMKp10hE"
                />
                <link
                    rel="canonical"
                    href={"https://www.foodversy.com/requestForm"}
                ></link>
                <meta
                    name="description"
                    content="Request a personalized quote for your wholesale Turkish food needs. Fill out our simple form and let Foodversy provide you with competitive pricing and tailored solutions."
                />
            </Helmet>
            <RequestForm />
        </>
    );
}
