import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "../component-css/ViewCount.css";
export default function ViewCount({ viewCount }) {
  return (
    <Row className="view-count-wrapper" title={"View count of the page"}>
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
        <p>{viewCount}</p>
      </Col>
    </Row>
  );
}
