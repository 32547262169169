import React from "react";
import { useState } from "react";

//import components
import {
    Button,
    Col,
    Container,
    Form,
    Row,
    Modal,
    ModalBody,
} from "react-bootstrap";

import ImageUpload from "../../../../helpers/PhotoUploader/PhotoUploader";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useEffect } from "react";
import Select from "react-select";

//style
import "./MailCreator.css";
import Panel from "../../../Panel/Panel";

//import mail options
import mail_options from "../../../../constants/MailOptions";

//api
import MailApi from "../../../../api/mailApi";

/**
 * Sends mail based on selected template or via the create mail panel
 * Works as a modal, so it needs to fed with a state to control its visibility
 * @param {selectedCustomer} :[{customer}] Object Array
 * @param {open} bool
 * @param {toggleModal} function
 * @returns
 */
export default function MailCreator({
    open = false,
    toggleModal,
    selectedCustomer,
    handleSelectedCustomer,
}) {
    const options = mail_options;
    const [isModalOpen, setModalOpen] = useState(open);
    const [selectedMailType, setSelectedMailType] = useState(options[0]);
    const [isAvailable, setAvailable] = useState(false);
    const [mailFields, setMailFields] = useState({
        title: "",
        message: "",
        image_url: "",
    });

    useEffect(() => {
        selectedMailType?.value === -1
            ? handleAvailable(true)
            : handleAvailable(false);
    }, [selectedMailType]);

    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    const handleMailFields = (obj) => {
        setMailFields((mailFields) => {
            return { ...mailFields, [obj.name]: obj.value };
        });
    };

    const handleMailType = (obj) => {
        setSelectedMailType(obj);
    };

    const handleAvailable = (e) => {
        setAvailable(e);
    };

    const clearModalState = () => {
        setSelectedMailType();
        setMailFields({ title: "", message: "", image_url: "" });
        handleSelectedCustomer([]);
    };

    const sendIntroMail = () => {
        if (selectedCustomer?.length < 1) {
            alert(
                "No customer is selected to send mail, please select customers to send mail"
            );
            return;
        }

        if (selectedMailType.value !== -1) {
            MailApi.sendIntroMail(
                selectedMailType.value
                    ? {
                          customer_information_array: selectedCustomer,
                          recipient_code: selectedMailType.value,
                      }
                    : {
                          customer_information_array: selectedCustomer,
                          recipient_code: 0,
                      }
            )
                .then((res) => {
                    alert(res.message);
                    clearModalState();
                    toggleModal();
                })
                .catch((err) => {
                    alert(
                        "An error occured during sending the introduction mail: " +
                            err
                    );
                });
        } else if (selectedMailType.value === 4) {
            MailApi.sendHerbsMail({
                customer_information_array: selectedCustomer,
                recipient_code: selectedMailType.value,
            })
                .then((res) => {
                    alert(res.message);
                    clearModalState();
                    toggleModal();
                })
                .catch((err) => {
                    alert(
                        "An error occured during sending the introduction mail: " +
                            err
                    );
                });
        } else {
            MailApi.sendDefaultMail({
                ...mailFields,
                customer_information_array: selectedCustomer,
            })
                .then((res) => {
                    alert(res.message);
                    clearModalState();
                    toggleModal();
                })
                .catch((err) => {
                    alert("An error occured during sending custom mail: ", err);
                });
        }
    };

    return (
        <Modal
            show={isModalOpen}
            onHide={toggleModal}
            centered
            backdrop="static"
            dialogClassName="modify-modal-main"
        >
            <ModalHeader
                className="btn-wrapper"
                style={{ display: "flex", alignItems: "center" }}
            >
                {selectedCustomer ? "Modify Customer" : "Add Customer"}
                <Button
                    id="close-btn"
                    className="close-btn"
                    onClick={() => {
                        toggleModal();
                    }}
                >
                    X
                </Button>
            </ModalHeader>
            <ModalBody>
                <Panel title={"Send Mail"} collapsible={false}>
                    <Row style={{ width: "100%" }}>
                        <Col xs={6}>
                            <Select
                                options={options}
                                defaultValue={options[0]}
                                value={selectedMailType}
                                onChange={handleMailType}
                            ></Select>
                        </Col>
                        <Col xs={12} style={{ padding: "0" }}>
                            <Panel
                                title="Mail Creator Panel"
                                collapsible
                                disabled={!isAvailable}
                                isOpen={isAvailable}
                            >
                                <Container className="mail-creator-wrapper">
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Form>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Title
                                                            </Form.Label>
                                                            <Form.Control
                                                                name="title"
                                                                value={
                                                                    mailFields.title
                                                                }
                                                                onChange={(e) =>
                                                                    handleMailFields(
                                                                        e.target
                                                                    )
                                                                }
                                                                placeholder="Please write a title for your mail"
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Message
                                                            </Form.Label>
                                                            <Form.Control
                                                                rows={10}
                                                                name="message"
                                                                as="textarea"
                                                                value={
                                                                    mailFields.message
                                                                }
                                                                onChange={(e) =>
                                                                    handleMailFields(
                                                                        e.target
                                                                    )
                                                                }
                                                                placeholder="Please write a message for your mail"
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Form.Label>
                                                        Upload Image
                                                    </Form.Label>
                                                </Col>
                                                <Col
                                                    className="image-wrapper"
                                                    xs={12}
                                                >
                                                    <img
                                                        src={
                                                            mailFields.image_url
                                                        }
                                                        alt=""
                                                        style={{
                                                            visibility:
                                                                mailFields.image_url ===
                                                                    undefined ||
                                                                mailFields.image_url ===
                                                                    ""
                                                                    ? "hidden"
                                                                    : "visible",
                                                        }}
                                                    />
                                                </Col>
                                                <Col
                                                    xs={12}
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <ImageUpload
                                                        setParentImageList={(
                                                            e
                                                        ) =>
                                                            handleMailFields({
                                                                name: "image_url",
                                                                value: e,
                                                            })
                                                        }
                                                    ></ImageUpload>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Panel>
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                id="admin-submit-button"
                                style={{
                                    display: "flex",
                                    marginTop: "2rem",
                                    width: "150px",
                                    justifyContent: "center",
                                }}
                                onClick={sendIntroMail}
                            >
                                Send Mail
                            </Button>
                        </Col>
                    </Row>
                </Panel>
            </ModalBody>
        </Modal>
    );
}
