import Api from "./index";

const MediaApi = {
    async uploadToCloud({ url, file }) {
        return Api.putPromise("", file, url, {
            headers: { "Content-Type": file.type },
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(
                    "An error occured while uploading the media to the cloud server: ",
                    err
                );
            });
    },

    async uploadMedia(url, file) {
        return await this.uploadToCloud({ url, file });
    },
};

export default MediaApi;
