import React, { useState, useEffect } from "react";

//styling and bootstrap components
import "../../../component-css/FileInput.css";
import { Form, Modal, ModalBody, Button } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
/**
 *
 * @param {*} requires the state for excel file
 * @param {*} requires the state for being toggled on or of
 * @param {*} requires the handler for mutating isModalOpen
 * @param {*} requires the handler for passing file input to parent
 * @returns
 */
export default function ExcelImportModal({
    excelFile,
    isModalOpen,
    toggleModal,
    handleExcelInput,
    uploadExcel,
}) {
    //const [excelFile, setExcelFile] = useState();

    //handler
    const handleExcel = (e) => {
        //setExcelFile(e);
        handleExcelInput(e);
    };
    return (
        <Modal show={isModalOpen} onHide={toggleModal} centered>
            <ModalHeader>Import Mail Addresses</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        uploadExcel();
                    }}
                >
                    <Form.Group
                        controlId="form-excel-file"
                        style={{ textAlign: "center" }}
                    >
                        <input
                            type="file"
                            name="file"
                            id="file"
                            className="custom-file-inputfield"
                            onChange={(e) => handleExcel(e.target.files)}
                        />
                        <label
                            for="file"
                            className="custom-file-inputfield-label"
                        >
                            {excelFile ? excelFile[0].name : "Choose a file"}
                        </label>
                    </Form.Group>
                    <Form.Group
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Button id="admin-submit-button" type="submit">
                            Upload
                        </Button>
                    </Form.Group>
                </Form>
            </ModalBody>
        </Modal>
    );
}
