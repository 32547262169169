import Api from "./index";

const RequestApi = {
    /**
     * Gets all the requests
     * Allows url params, give in JSON ( { 'country' : 'Malta' } )
     * @returns
     */
    getRequests(params) {
        return Api.getPromise("/requests", params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.error(err);
                return [];
            });
    },

    /**
     * Gets a request by id
     * @param {*} id
     * @returns
     */
    getRequest(id) {
        return Api.getPromise(`/requests/${id}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting a request: " + err.message);
                return [];
            });
    },

    /**
     * Gets latest ten requests
     */
    getLatestRequests() {
        return Api.getPromise(`/requests/latest`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting a request: " + err.message);
                return [];
            });
    },

    /**
     * Creates a request, with the data given in params
     * @param {*} params
     * @returns
     */
    createRequest(params) {
        return Api.postPromise(`/requests`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while creating a request: " + err.message);
                return null;
            });
    },

    /**
     * Create request with the information given in params
     * id is passed in the request param
     * params are fields to be changed, passed in request body
     * @param {*} id
     * @param {*} params
     * @returns
     */
    updateRequest(id, params) {
        return Api.putPromise(`/requests/${id}`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while updating a request: " + err.message);
                return null;
            });
    },

    /**
     * Deletes the request given with the id
     * @param {*} id
     */
    deleteRequest(id) {
        return Api.deletePromise(`/requests/${id}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while deleting a request: " + err);
                return null;
            });
    },
};

export default RequestApi;
