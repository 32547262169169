import Api from './index';

const SearchApi = {

    /**
     * Searches for the products matching query string
     * @param {*} queryString 
     * @returns 
     */
    searchProduct(queryString) {
        return Api.getPromise(`/search/products/${queryString}`).then(res => {
            return res;
        }).catch(err => {
            console.error(err);
            return [];
        });
    },

    /**
     * Searches for categories matching the query string
     * @param {*} queryString 
     * @returns 
     */
    searchCategory(queryString) {
        return Api.getPromise(`/search/categories/${queryString}`).then(res => {
            return res;
        }).catch(err => {
            console.error(err);
            return [];
        });
    },

    /**
     * Searches for news matching the query string
     * @param {*} queryString 
     * @returns 
     */
    searchNews(queryString) {
        return Api.getPromise(`/search/news/${queryString}`).then(res => {
            return res;
        }).catch(err => {
            console.error(err);
            return [];
            });
        },
}
export default SearchApi;