import React, { useEffect, useState, useRef } from "react";
import "../component-css/Request.css";
// import CountrySelector from './CountrySelector';

import {
    Form,
    Container,
    Col,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    Spinner,
} from "react-bootstrap";
// import { addAbortSignal } from 'stream';
import CategoryApi from "../../api/categoryApi";
import ProductApi from "../../api/productApi";
import Select from "react-select";
import RelatedProductSelector from "../SubComponents/RelatedProductSelection";
import "../component-css/Product.css";
import parent_categories from "../../constants/Parents";
import StringHelper from "../../helpers/StringHelper.js";
import TagCreator from "./Subcomponents/TagCreator/TagCreator";
import { ProductStatus } from "../../constants/ProductStatus";
import ImageUpload from "../../helpers/PhotoUploader/PhotoUploader";

export default function AddItem() {
    //States
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState();
    const [checked, setChecked] = useState(1);
    const [selectedProduct, setSelectedProduct] = useState();
    const [relatedList, setRelatedList] = useState([]);
    const [checkedPopular, setCheckedPopular] = useState(false);
    const [categories, setCategories] = useState();
    const [imgList, setImgList] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");

    //Refs
    const nameRef = useRef();
    const categoryRef = useRef();
    const selectRef = useRef();
    const radioRef = useRef();
    const relatedProductsRef = useRef();
    const descriptionRef = useRef();

    //useEffect

    //populates categories at the start
    useEffect(() => {
        CategoryApi.getCategories()
            .then((res) => setCategories(res.data))
            .catch((err) => console.log("Error: ", err));
        ProductApi.getProducts()
            .then((res) => setProducts(res.data))
            .catch((err) => console.log("Error: ", err));
        nameRef.current.focus();
        descriptionRef.current.focus();
    }, []);

    //state handlers
    const handleTags = (newTagList) => {
        setTags(newTagList);
    };

    function selectedProductHandler(e) {
        console.log(e);
        setSelectedProduct(e);
    }

    function handleStatus(e) {
        setSelectedStatus(e);
    }

    //Maps categories to fill react-select component
    const categoriesToOptions = () => {
        if (categories === undefined)
            //if state did not mount yet, does not continue
            return;
        let options = []; //array for react-select
        categories.map(function (category) {
            let lbl =
                category.name.charAt(0).toUpperCase() + category.name.slice(1);
            options = [...options, { value: category, label: lbl }];
            return true;
        });
        return options;
    };

    //Related List Operations
    const addToRelatedList = () => {
        if (selectedProduct && !relatedList.includes(selectedProduct.name))
            setRelatedList([...relatedList, selectedProduct.name]);
    };

    const removeFromRelatedList = (e) => {
        let filteredArray = relatedList.filter((item) => item !== e);
        setRelatedList(filteredArray);
    };

    //Adds product to database
    const addProduct = () => {
        let category;

        if (checked === 2) {
            category = categoryRef.current.value;
            for (let i = 0; i < categories.length; i++) {
                //Checks if entered new category already exists in database
                if (
                    categories[i].name.toLowerCase() === category.toLowerCase()
                ) {
                    alert("Already existing category!!!");
                    return;
                }
            }

            let parent_category = selectRef.current.state.value.value;

            let categoryData = {
                name: StringHelper.clearExtraWhitespace(category),
                parent_category:
                    StringHelper.clearExtraWhitespace(parent_category),
            };
            //inserts into category table
            CategoryApi.createCategory(categoryData)
                .then((res) => console.log(res))
                .catch((err) => console.log("Error: ", err));
        } else category = selectRef.current.state.value.value.name;

        let data = {
            category: category,
            name: StringHelper.clearExtraWhitespace(nameRef.current.value),
            tags: tags,
            related_products: relatedList,
            image_url: imgList,
            isPopular: checkedPopular,
            description: descriptionRef.current.value,
            status: selectedStatus.label,
        };

        //inserts into product table
        ProductApi.createProduct(data)
            .then((res) => {
                if (res !== null) {
                    setLoading(false);
                    alert(res.message);
                    setImgList([]);
                }
            })
            .then(() => {
                window.location.reload(false);
            })
            .catch((err) => console.log("ERROR: ", err));
    };

    const categorySelection = () => {
        //if a new category is going to be entered, creates an input and requests parent category
        if (checked === 2) {
            return (
                <div>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridParentCategory">
                            <Form.Label>Choose Category</Form.Label>
                            <Select
                                options={parent_categories}
                                defaultValue={parent_categories[0]}
                                ref={selectRef}
                            ></Select>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCategoryName">
                            <Form.Label>New Category</Form.Label>
                            <Form.Control
                                placeholder="Category"
                                ref={categoryRef}
                            />
                        </Form.Group>
                    </Form.Row>
                </div>
            );
        }
        //if not, creates a selection box with already existing categories
        else {
            return (
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Choose Category</Form.Label>
                        <Select
                            options={categoriesToOptions()}
                            defaultValue={categoriesToOptions[0]}
                            ref={selectRef}
                        ></Select>
                    </Form.Group>
                </Form.Row>
            );
        }
    };

    const createLabels = () => {
        return (
            <Form.Row id="tags">
                {relatedList.map(function (e) {
                    return (
                        <Button
                            onClick={() => {
                                removeFromRelatedList(e);
                            }}
                            id="tag"
                        >
                            {e}
                        </Button>
                    );
                })}
            </Form.Row>
        );
    };
    return (
        <Container>
            <Form className="req-form">
                <ToggleButtonGroup
                    type="radio"
                    name="options"
                    defaultValue={1}
                    ref={radioRef}
                >
                    <ToggleButton
                        value={1}
                        onChange={(e) => {
                            setChecked(e.currentTarget.value);
                        }}
                    >
                        Existing Category
                    </ToggleButton>
                    <ToggleButton
                        value={2}
                        onChange={(e) => {
                            setChecked(e.currentTarget.value);
                        }}
                    >
                        New Category
                    </ToggleButton>
                </ToggleButtonGroup>
                {categorySelection()}
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            placeholder="Product Name"
                            ref={nameRef}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridStatus">
                        <Form.Label>Status</Form.Label>
                        <Select
                            options={ProductStatus}
                            defaultValue={ProductStatus[0]}
                            value={selectedStatus}
                            onChange={(e) => {
                                handleStatus(e);
                            }}
                        ></Select>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridRelated">
                        <Form.Label>Related Products</Form.Label>
                        <RelatedProductSelector
                            products={products}
                            selectedProductHandler={selectedProductHandler}
                            relatedProductsRef={relatedProductsRef}
                        ></RelatedProductSelector>
                        <Button onClick={() => addToRelatedList()}>Add</Button>

                        {/* <Form.Control   placeholder="Please do not leave space after comma" ref = {relatedProductsRef} /> */}
                    </Form.Group>
                </Form.Row>
                {createLabels()}
                <Form.Row>
                    <TagCreator
                        handleTags={handleTags}
                        existingTags={tags}
                    ></TagCreator>
                    {/* <Form.Group as={Col} controlId="formGridTag">
            <Form.Label>Tags</Form.Label>
            <Form.Control
              placeholder="Please do not leave space after comma"
              ref={tagRef}
            />
          </Form.Group> */}
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridImgUrl">
                        <ImageUpload
                            setParentImageList={setImgList}
                        ></ImageUpload>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Pleaseenter short description"
                            ref={descriptionRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Check
                        type="checkbox"
                        label="Is Popular"
                        checked={checkedPopular}
                        value="1"
                        onChange={(e) =>
                            setCheckedPopular(e.currentTarget.checked)
                        }
                    />
                </Form.Row>
                <Button
                    id="admin-submit-button"
                    onClick={() => {
                        addProduct();
                        setLoading(true);
                    }}
                >
                    {loading ? (
                        <span>
                            <Spinner animation="border" role="status"></Spinner>
                            <h3>Adding Product</h3>
                        </span>
                    ) : (
                        "Add Product"
                    )}
                </Button>
            </Form>
        </Container>
    );
}
