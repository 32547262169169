import Api from "./index";

const ViewCountApi = {
  /**
   * Gets the view count of a product
   * URL param: productId
   * @returns
   */
  getProductViewCount(productId) {
    return Api.getPromise(`/view/products/${productId}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  },

  /**
   * Gets the view count of a news
   * URL param: newsId
   * @returns
   */
  getNewsViewCount(newsId) {
    return Api.getPromise(`/view/news/${newsId}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  },

  /**
   * Updates the view count of the product
   * URL param: productId
   * Body: { ip_address }
   * @param {*} id
   * @param {*} body
   * @returns
   */
  updateProductViewCount(productId, body) {
    return Api.putPromise(`/view/products/${productId}`, body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(
          "Error while updating a product view count: " + err.message
        );
        return null;
      });
  },

  /**
   * Updates the view count of the news
   * URL param: newsId
   * Body: { ip_address }
   * @param {*} id
   * @param {*} body
   * @returns
   */
  updateNewsViewCount(newsId, body) {
    return Api.putPromise(`/view/news/${newsId}`, body)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("Error while updating a news view count: " + err.message);
        return null;
      });
  },
};

export default ViewCountApi;
