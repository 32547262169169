export const SupplierSub = [
    {
        type: 0,
        name: "Standart",
        privileges: [],
    },
    {
        type: 1,
        name: "Pro",
        privileges: [],
    },
];

export default SupplierSub;
