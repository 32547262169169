import React from "react";
import { useState, useEffect, useMemo } from "react";
import RequestApi from "../../api/requestApi";
import DataTableBase from "../SubComponents/DataTable/DataTable";
import { Globe } from "react-bootstrap-icons";
import Flag from "react-flagpack";
import StringHelper from "../../helpers/StringHelper";
import { countries } from "../../constants/Countries";
import { Container } from "react-bootstrap";
import "../component-css/LatestReqs.css";

export default function LatestRequests() {
    const [latestRequests, setLatestRequests] = useState([]);

    useEffect(() => {
        RequestApi.getLatestRequests()
            .then((res) => {
                setLatestRequests(res.data);
            })
            .catch((err) =>
                console.log(
                    "An error occured while getting latest requests: ",
                    err
                )
            );
    }, []);

    useEffect(() => {
        latestRequests.forEach((item) => {
            const countryInfo = countries.find((x) => x.name === item.country);

            item.code =
                countryInfo && countryInfo.code ? (
                    <Flag code={countryInfo.code}></Flag>
                ) : (
                    <Globe size={32} color="#0096FF"></Globe>
                );
        });
    }, [latestRequests]);

    const columns = [
        {
            name: "",
            width: "100px",
            selector: (row) => {
                try {
                    return row.code;
                } catch (err) {
                    return <Globe></Globe>;
                }
            },
        },
        {
            name: "Country",
            selector: (row) => row.country,
            sortable: true,
        },
        {
            name: "Product",
            selector: (row) => row.product_name,
            sortable: true,
        },
        {
            name: "Quantity",
            selector: (row) => row.quantity,
        },
        {
            name: "Date (DD/MM/YYYY)",
            selector: (row) => {
                return StringHelper.formatISODateReverse(row.date);
            },
        },
    ];

    return (
        <>
            <Container className="latest-req-container">
                <h2>Latest Requests</h2>
                <DataTableBase
                    data={latestRequests}
                    columns={columns}
                    header={"Latests Requests"}
                ></DataTableBase>
            </Container>
        </>
    );
}
