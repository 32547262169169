import React, { useEffect, useState, useRef } from "react";
import "../component-css/Request.css";
// import CountrySelector from './CountrySelector';

import { Form, Container, Col, Button, Spinner } from "react-bootstrap";
// import { addAbortSignal } from 'stream';
import CategoryApi from "../../api/categoryApi";
import Select from "react-select";
import parent_categories from "../../constants/Parents";
import StringHelper from "../../helpers/StringHelper";

export default function AddCategory() {
    //States
    const [categories, setCategories] = useState();
    const [loading, setLoading] = useState(false);

    //Refs
    const categoryRef = useRef();
    const selectRef = useRef();

    //useEffect

    //populates categories at the start
    useEffect(() => {
        CategoryApi.getCategories()
            .then((res) => setCategories(res.data))
            .catch((err) => console.log("Error: ", err));
    }, []);

    //Adds category to database
    const addCategory = () => {
        let category;
        setLoading(true);
        category = StringHelper.capitalizeEveryWord(categoryRef.current.value);
        for (let i = 0; i < categories.length; i++) {
            //Checks if entered new category already exists in database
            if (categories[i].name.toLowerCase() === category.toLowerCase()) {
                alert("This category already exists");
                return;
            }
        }

        let parent_category = selectRef.current.state.value.value;

        let categoryData = {
            name: category,
            parent_category: parent_category,
        };
        CategoryApi.createCategory(categoryData)
            .then((res) => {
                alert(res.message);
                setLoading(false);
            })
            .catch((err) => console.log("Error: ", err));
    };

    const categorySelection = () => {
        //if a new category is going to be entered, creates an input and requests parent category
        return (
            <div>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridParentCategory">
                        <Form.Label>Choose Category</Form.Label>
                        <Select
                            options={parent_categories}
                            defaultValue={parent_categories[0]}
                            ref={selectRef}
                        ></Select>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridCategoryName">
                        <Form.Label>New Category</Form.Label>
                        <Form.Control
                            placeholder="Category"
                            ref={categoryRef}
                        />
                    </Form.Group>
                </Form.Row>
            </div>
        );
    };

    return (
        <Container>
            <Form className="req-form">
                {categorySelection()}
                <Button
                    id="admin-submit-button"
                    onClick={() => {
                        addCategory();
                    }}
                >
                    {loading ? (
                        <span>
                            <Spinner animation="border" role="status"></Spinner>
                            <h3>Adding Item</h3>
                        </span>
                    ) : (
                        "Add Category"
                    )}
                </Button>
            </Form>
        </Container>
    );
}
