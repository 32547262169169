export const GenerateID = {
  /**
   * Generates a random id to be used in dynamically generated html elements
   * @returns
   */
  randId() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
  },
};
