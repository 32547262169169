import React, { useState, useEffect } from "react";
import "../component-css/ImageContainer.css";

export default function ImageContainer({
    imgUrl,
    alt,
    objectPosition,
    objectFit,
    height,
    maxHeight,
}) {
    return (
        <div
            className="img-container"
            style={{ height: height, maxHeight: maxHeight }}
        >
            <img
                src={imgUrl}
                alt={alt}
                style={{
                    objectPosition: objectPosition,
                    objectFit: objectFit,
                }}
            ></img>
        </div>
    );
}
