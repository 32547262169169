import "../components/component-css/FooterNew.css";
import {} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faTwitter,
    faLinkedin,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

export default function FooterNew() {
    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12  about-col">
                        <h6>About</h6>
                        <p className="text-justify">
                            Foodversy.com is <i>ONE-STOP SHOP</i> for food and
                            agricultural products. System is developed with 27
                            years of experience, certified producers, updated
                            market informations, unique place for marketplace,
                            quick commerce, reliable supply chain, well
                            organized logistic solutions.
                        </p>
                    </div>

                    <div className="col-xs-6 col-md-3">
                        <h6>Main Categories</h6>
                        <ul className="footer-links">
                            <li>
                                <a href="/category/cereals">Cereals</a>
                            </li>
                            <li>
                                <a href="/category/dried-foods">Dried Foods</a>
                            </li>
                            <li>
                                <a href="/category/nuts">Nuts</a>
                            </li>
                            <li>
                                <a href="/category/oils">Oils</a>
                            </li>
                            <li>
                                <a href="/category/canned-foods">
                                    Canned Foods
                                </a>
                            </li>
                            <li>
                                <a href="/category/fresh-foods">Fresh Foods</a>
                            </li>
                            <li>
                                <a href="/category/frozen-foods">
                                    Frozen Foods
                                </a>
                            </li>
                            <li>
                                <a href="/category/spices-herbs">
                                    Spices Herbs
                                </a>
                            </li>
                            <li>
                                <a href="/category/dairy-foods">Dairy Foods</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6 col-md-3">
                        <h6>Special Categories</h6>
                        <ul className="footer-links">
                            <li>
                                <a href="/category/teas">Teas</a>
                            </li>
                            <li>
                                <a href="/category/bakery">Bakery</a>
                            </li>
                            <li>
                                <a href="/category/organic">Organic</a>
                            </li>
                            <li>
                                <a href="/category/oils">Oils</a>
                            </li>
                            <li>
                                <a href="/category/food-ingredients">
                                    Food Ingredients
                                </a>
                            </li>
                            <li>
                                <a href="/category/beverages">Beverages</a>
                            </li>
                            <li>
                                <a href="/category/eggs">Eggs</a>
                            </li>
                            <li>
                                <a href="/category/confectionary">
                                    Confectionary
                                </a>
                            </li>
                            <li>
                                <a href="/category/herbal-products">
                                    Herbal Products
                                </a>
                            </li>

                            <li>
                                <a href="/category/snacks">Snacks</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-xs-6 col-md-3">
                        <h6>Retail Categories</h6>
                        <ul className="footer-links">
                            <li>
                                <a href="/category/chocolate">Chocolate</a>
                            </li>
                            <li>
                                <a href="/category/bars">Bars</a>
                            </li>
                            <li>
                                <a href="/category/dried-apricot">
                                    Dried Apricot
                                </a>
                            </li>
                            <li>
                                <a href="/category/hazelnut-kernels-natural-raw-retail">
                                    Hazelnut Kernels Natural Raw Retail
                                </a>
                            </li>
                            <li>
                                <a href="/category/cistus">Cistus</a>
                            </li>
                            <li>
                                <a href="/category/fresh-fruits">
                                    Fresh Fruits
                                </a>
                            </li>
                            <li>
                                <a href="/category/fresh-fruits-retail-packs">
                                    Fresh Fruits Retail Packs
                                </a>
                            </li>
                            <li>
                                <a href="/category/hazelnut-spreads-retail">
                                    Hazelnut Spreads Retail
                                </a>
                            </li>
                            <li>
                                <a href="/category/fresh-vegetables-retail-packs">
                                    Fresh Vegetables Retail Packs
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6 col-md-3">
                        <h6>Quick Links</h6>
                        <ul className="footer-links">
                            <li>
                                <a href="/home">Home</a>
                            </li>
                            <li>
                                <a href="/aboutus">About</a>
                            </li>
                            <li>
                                <a href="/main-categories/main">Categories</a>
                            </li>
                            <li>
                                <a href="/news">News</a>
                            </li>
                            <li>
                                <a href="/sitemap.xml">Sitemap</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-6 col-xs-12">
                        <p className="copyright-text">
                            Copyright &copy; 2023 All Rights Reserved by
                            <a href="#"> Socratech</a>.
                        </p>
                    </div>

                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <ul className="social-icons">
                            <li>
                                <a className="facebook" href="#">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="twitter"
                                    href="https://twitter.com/Foodversy"
                                >
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dribbble"
                                    href="https://www.instagram.com/foodversy/"
                                >
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </li>
                            <li>
                                <a className="linkedin" href="#">
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}
