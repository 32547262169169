import Api from "./index";

const FairApi = {
    /**
     * Gets all the fairs
     * @returns
     */
    getFairs(params) {
        return Api.getPromise("/fairs", params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.error(err);
                return [];
            });
    },

    /**
     * Gets a fair by id
     * @param {*} fairID
     * @returns
     */
    getFairById(fairID) {
        return Api.getPromise(`/fairs/${fairID}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while getting a fair: " + err.message);
                return [];
            });
    },

    /**
     * Create fair with the information given in param
     * params: { 'name' : 'Dried Apricot', 'category' : 'Dried Fruits' }
     * !! images should be given in an array, including their absolute, example:
     * image_url = [/Users/fft/Desktop/apricot.png, /Users/fft/Desktop/almond.png]
     * @param {*} params
     * @returns
     */
    createFair(params) {
        return Api.postPromise(`/fairs`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while creating a fair: " + err.message);
                return null;
            });
    },

    /**
     *
     * params: id: id of the fair
     * params: params: { 'id' : <required>, 'name' : <required if url is not provided>, 'url' : <required if name is not provided> }
     * In case a field is not updated, say category, still pass it as a parameter.
     * @param {*} params
     * @returns
     */
    updateFair(id, params) {
        return Api.putPromise(`/fairs/${id}`, params)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while updating a fair: " + err.message);
                return null;
            });
    },

    /**
     * params: id: id of the fair
     * Deletes the fair given with the id
     * @param {*} fairName
     */
    deleteFair(id) {
        return Api.deletePromise(`/fairs/${id}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Error while deleting a fair: " + err);
                return null;
            });
    },
};

export default FairApi;
