import React, { useState, useEffect } from "react";
import "../component-css/Jumbotron.css";
import Aos from "aos";
export default function Jumbotron({
    header,
    subheader,
    content,
    image_url = "",
    image_alt = "farmer photo",
}) {
    const [imgUrl, setImgUrl] = useState(image_url);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const defaultPhoto =
        "https://res.cloudinary.com/da8slr0ic/image/upload/v1645448514/foodsfromturkey/zdxs9v2t1hjvsrfgdhfl.jpg";
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    useEffect(() => {
        handleImageUrl(image_url);
    }, [header, subheader, content, image_url]);

    const handleImageUrl = (e) => {
        setImgUrl(e);
    };

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        // Attach the event listener when the component mounts
        window.addEventListener("resize", handleResize);

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures the effect runs only once during mount and unmount

    if (screenWidth > 1200) {
        return (
            <div
                className="row main-row"
                style={{ marginRight: "0", marginLeft: "0" }}
            >
                <div
                    className="jumbo-main "
                    data-aos="fade-down"
                    data-aos-delay="100"
                >
                    <div className="inside-row row">
                        <div className="text-box col-xl-6">
                            <div className="text-box-wrapper">
                                <h1>{header}</h1>
                                <h3>{subheader}</h3>
                                <p>{content}</p>
                            </div>
                        </div>
                        <div
                            className="img-box col-xl-6"
                            style={{ objectFit: "cover", textAlign: "center" }}
                        >
                            <img
                                src={
                                    imgUrl !== "" || imgUrl
                                        ? imgUrl
                                        : defaultPhoto
                                }
                                alt={image_alt}
                                style={{}}
                                width={"90%"}
                            ></img>
                        </div>
                        <div className="background-div"></div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="row main-row"
                style={{ marginRight: "0", marginLeft: "0" }}
            >
                <div
                    className="jumbo-main  "
                    data-aos="fade-right"
                    data-aos-delay="100"
                >
                    <div
                        className="img-box col-12"
                        style={{ objectFit: "cover" }}
                    >
                        <img
                            src={
                                imgUrl === "" || imgUrl ? defaultPhoto : imgUrl
                            }
                            alt={image_alt}
                            style={{}}
                            width={"100%"}
                        ></img>
                    </div>
                    <div className="row inside-row">
                        <div className="text-box col-12">
                            <h1>{header}</h1>
                            <h3>{subheader}</h3>
                            <p>{content}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
