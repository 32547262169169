import React, { useState } from "react";

export default function useFilters() {
    const [filters, setFilters] = useState({});

    //handlers
    /**
     * send a value object to add to filter data
     * @param {*} newValue
     */
    const handleFilters = (newValue) => {
        var temp = { ...filters };
        if (newValue.name in temp && newValue.value === "")
            delete temp[newValue.name];
        else temp = { ...temp, [newValue.name]: newValue.value };
        setFilters(temp);
    };

    return { filters, setFilters, handleFilters };
}
