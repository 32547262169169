import React, { useState } from "react";

//UI components
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";

//custom components
import DataTableBase from "../../SubComponents/DataTable/DataTable";
import CountrySelector from "../../CountrySelector";
import Toolbar from "../../SubComponents/DataTable/Toolbar";

//helpers and hooks
import useFairs from "../../../hooks/useFairs";
import useFilters from "../../../hooks/useFilter";
import ModifyFairModal from "./ModifyFairModal";
import FairApi from "../../../api/fairApi";
import styled from "styled-components";

const FilterRow = styled(Row)`
    display: flex;
    align-items: center;
`;
const FilterCol = styled(Col)`
    margin-bottom: 0.5rem;
`;

//Table columns
const columns = [
    {
        name: "Name",
        selector: (row) => row.name,
    },
    {
        name: "Date",
        sortable: true,
        selector: (row) => {
            return row.start_date;
        },
    },
    {
        name: "Country",
        selector: (row) => row.country,
    },
    {
        name: "Email",
        selector: (row) => row.email_address,
    },
];

/**
 * A toolbar for the data table
 * requires filter data handler to pass values for filtering
 * @param {*} handleFilterData
 * @returns
 */
function ToolbarFilterRow({ handleFilterData }) {
    return (
        <Container fluid>
            <FilterRow>
                <Col xs={12}>Filter By:</Col>
                <FilterCol lg={3} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Fair Name"
                            name="name"
                            aria-label="Fair name"
                            onChange={(e) => {
                                handleFilterData({
                                    name: e.target.name,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </FilterCol>
                <FilterCol lg={3} md={6}>
                    <InputGroup>
                        <FormControl
                            placeholder="Email Address"
                            name="email_address"
                            aria-label="Email Address"
                            onChange={(e) => {
                                handleFilterData({
                                    name: e.target.name,
                                    value: e.target.value,
                                });
                            }}
                        ></FormControl>
                    </InputGroup>
                </FilterCol>
                <FilterCol lg={3} md={6}>
                    <CountrySelector
                        defaultValue={""}
                        handleCountry={(e) => {
                            handleFilterData({ name: "country", value: e });
                        }}
                        isClearable
                    ></CountrySelector>
                </FilterCol>
            </FilterRow>
        </Container>
    );
}

export default function FairControl() {
    const { filters, handleFilters } = useFilters();
    const { fairs, reload } = useFairs(filters);
    const [selectedFair, setSelectedFair] = useState();
    const [bulkSelect, setBulkSelect] = useState([]);

    const onSelectedRowsChange = (e) => {
        setBulkSelect(e.selectedRows);
    };

    const handleSelectedFair = (e) => {
        setSelectedFair(e);
    };

    //#region Modal
    const [isModalOpen, setIsModelOpen] = useState(false);

    const toggleModal = () => {
        setIsModelOpen((isModalOpen) => !isModalOpen);
    };

    const row_events = {
        onDoubleClick: (row, event) => {
            handleSelectedFair(row);
            toggleModal();
        },
    };

    const deleteSelectedFairs = () => {
        // Implement an endpoint to bulk delete sent array objects
        console.log(bulkSelect);
        for (let i = 0; i < bulkSelect.length; i++)
            FairApi.deleteFair(bulkSelect[i]._id).catch((err) => {
                console.log("Error occured while deleting the fairs: ", err);
            });
    };
    return (
        <>
            <ModifyFairModal
                selectedFair={selectedFair}
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
            ></ModifyFairModal>
            <Container fluid>
                <DataTableBase
                    toolbar={
                        <Toolbar
                            name={"Expo List"}
                            reload={reload}
                            handleAdd={toggleModal}
                            handleDelete={deleteSelectedFairs}
                            filterRowElements={
                                <ToolbarFilterRow
                                    handleFilterData={handleFilters}
                                ></ToolbarFilterRow>
                            }
                        ></Toolbar>
                    }
                    data={fairs}
                    columns={columns}
                    onSelectedRowsChange={onSelectedRowsChange}
                    onRowDoubleClicked={row_events.onDoubleClick}
                    selectableRows
                ></DataTableBase>
            </Container>
        </>
    );
}
