import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ history }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, []);

    return null;
}

/**
 * Give the id of the element to scroll to it smoothly
 * @param {*} id
 */
const scrollToElement = (id, offset) => {
    // Assuming you have a reference to the target element
    const targetElement = document.getElementById(id);
    const headerOffset = 120;
    const elementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition = offset
        ? elementPosition - offset
        : elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
    });
};

export { scrollToElement };
export default withRouter(ScrollToTop);
