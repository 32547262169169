import "../../components/component-css/ContactUs.css";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Header from "../SubComponents/Header";
import React, { useRef, useEffect } from "react";
import MailApi from "../../api/mailApi";
import CustomerInfoApi from "../../api/customerInfoApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";

export default function ContactUs() {
    const mailRef = useRef();

    useEffect(() => {}, []);
    function saveMailAddress() {
        MailApi.saveMail({ address: mailRef.current.value })
            .then((res) => {
                alert(res.message);
                mailRef.current.value = "";
            })
            .catch((err) => console.log("Error: ", err));

        CustomerInfoApi.createCustomer({
            company_name: " ",
            name: " ",
            email_address: mailRef.current.value,
        })
            .then((res) => {
                mailRef.current.value = "";
            })
            .catch((err) => console.log("Error: ", err));
    }

    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">
                <Header
                    header="Contact Us"
                    subheader="We are talented marketers who know the market and have been doing
            this business in Turkey for 27 years. If you want to contact us
            about anything, we will be waiting for you 24 hours a day."
                    icon={faCannabis}
                ></Header>

                <div className="map">
                    <iframe
                        style={{ border: "0", width: "100%", height: "270px" }}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6774.008584933057!2d27.20141177369457!3d38.18804175711128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b958ebbd162351%3A0x145f64fb7d46feac!2sBilimpark!5e0!3m2!1sen!2str!4v1630686750724!5m2!1sen!2str"
                    ></iframe>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-4">
                        <div className="info">
                            <div className="address">
                                <i>
                                    <FontAwesomeIcon
                                        icon={faLocationArrow}
                                        size="1x"
                                    ></FontAwesomeIcon>
                                </i>
                                <h4>Location:</h4>
                                <p>
                                    Socratech Yazılım Teknoloji ve San. A.Ş.
                                    Tekeli Fevzi Çakmak, 10032. Sk. No 2, 35865
                                    İtob Osb/Menderes/İzmir
                                </p>
                            </div>

                            <div className="email">
                                <i>
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        size="1x"
                                    ></FontAwesomeIcon>
                                </i>
                                <h4>Email:</h4>
                                <p>foodversy@gmail.com</p>
                            </div>

                            <div className="phone">
                                <i>
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        size="1x"
                                    ></FontAwesomeIcon>
                                </i>
                                <h4>Call:</h4>
                                <p>+90 (232) 502 32 74</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <Row className="innerRow">
                            <h2> Join Our Mail List </h2>
                        </Row>
                        <Row className="innerRow">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Please enter your email"
                                    aria-label="Please enter your email"
                                    aria-describedby="basic-addon2"
                                    ref={mailRef}
                                />
                                <Button
                                    onClick={saveMailAddress}
                                    id="button-addon2"
                                >
                                    Join
                                </Button>
                            </InputGroup>
                            <Form.Text className="MailList">
                                You can receive latest news and opportunities by
                                joining our mail list
                            </Form.Text>
                        </Row>
                    </div>
                </div>
            </div>
        </section>
    );
}
