import React, { useCallback, useEffect, useState } from "react";
import ProductCards from "./ProductCards";
import "./component-css/Home.css";
import { useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import CategoryApi from "../api/categoryApi";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Jumbotron from "./SubComponents/Jumbotron";
import useCategory from "../hooks/useCategory";
import StringHelper from "../helpers/StringHelper";
export default function Category({ selectedCategory, selectedProductHandler }) {
    const params = useParams();
    const name = params.name;

    const [products, setProducts] = useState([]);
    const category = useCategory(name);
    const location = useLocation();

    useEffect(() => {
        CategoryApi.getProductByCategory(name)
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                console.log("Error: ", err);
            });
    }, [name]);

    return (
        <Container id="HomeContainer" fluid>
            <Helmet>
                <title>{category.name} from Foodversy</title>
                <meta
                    name="description"
                    content={
                        "All Turkish food products under the " +
                        category.name +
                        " category!"
                    }
                />
                <link
                    rel="canonical"
                    href={"https://www.foodversy.com" + location.pathname}
                />
            </Helmet>
            <Container id="Popular" fluid>
                <div>
                    <Jumbotron
                        header={category.name}
                        subheader={`Check out our ${category.name} category`}
                        content={category.description}
                    ></Jumbotron>
                </div>
                <Row id="Cartsrow" style={{ display: "block" }}>
                    <ProductCards productList={products}></ProductCards>
                </Row>
            </Container>
        </Container>
    );
}
