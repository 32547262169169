import React, { useState, useEffect, useCallback } from "react";

//styling and bootstrap components
import "./ModifyModal.css";
import {
    Form,
    Modal,
    ModalBody,
    Button,
    Row,
    Col,
    Container,
} from "react-bootstrap";

//components
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import CountrySelector from "../../CountrySelector";
// import FairApi from "../../../api/FairApi";
import countryList from "react-select-country-list";
import useFormData from "../../../hooks/useFormData";
import styled from "styled-components";
import Panel from "../../Panel/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { GenerateID } from "../../../helpers/GenerateID";
import FairApi from "../../../api/fairApi";
import MediaApi from "../../../api/mediaApi";
import StringHelper from "../../../helpers/StringHelper";

const initialForm = {
    _id: "",
    name: "",
    firm: "",
    email_address: "",
    about: "",
    start_date: "",
    end_date: "",
    country: "",
    image_name: "",
    image_url: "",
    content_type: "",
    card_context: "",
    content: "",
    url: "",
};

const ImageUploadCol = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    & .row {
        gap: 1rem;
        width: 100%;
        height: 100%;
        background-color: var(--fixed-secondary);
        border-radius: 15px;
    }

    & .col-12 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & .img-upload {
        width: 100%;
        object-fit: cover;
        max-width: 100%;
        max-height: 300px;
        border-radius: 10px;
    }
`;

/**
 *
 * @param {*} requires the state for modal
 * @param {*} requires the state for being toggled on or of

 * @returns
 */
export default function ModifyFairModal({
    selectedFair,
    isModalOpen,
    toggleModal,
    handleSelectedFair,
    onSuccess = () => {},
}) {
    const { formData, setFormData, handleFormData } = useFormData(initialForm);
    const [image, setImage] = useState();
    const [imageURL, setImageURL] = useState(formData.image_url);

    //handler
    const initializeFormData = useCallback(() => {
        let tempObj = { ...selectedFair };

        if (
            selectedFair !== undefined &&
            Object.keys(selectedFair).length > 0
        ) {
            setFormData(tempObj);
            setImageURL(selectedFair.image_url);
        }
    }, [selectedFair, setFormData]);

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];

        if (selectedImage) {
            if (imageURL) {
                URL.revokeObjectURL(imageURL); // Clean up the previous image URL
            }

            const tempURL = URL.createObjectURL(selectedImage);
            setImageURL(tempURL);
        }
    };

    // Add new photo name to the formData if there does not exist a photo for the fair
    const onImageAdd = useCallback(() => {
        if (image) {
            handleFormData({
                name: "image_name",
                value: formData.image_name
                    ? formData.image_name
                    : `fair_image_${GenerateID.randId()}.${
                          image.type?.split("/")[1]
                      }`,
            });
            handleFormData({
                name: "content_type",
                value: image.type,
            });
        }
    }, [formData.image_name, image, handleFormData]);

    //useEffect
    useEffect(() => {
        initializeFormData();
    }, [initializeFormData, selectedFair]);

    useEffect(() => {
        onImageAdd();
    }, [onImageAdd]);

    //functions

    const clearFormData = () => {
        setFormData({});
        if (handleSelectedFair !== undefined) handleSelectedFair({});

        setImage();
        setImageURL();
    };

    const closeModal = () => {
        toggleModal();
        clearFormData();
    };

    const submitForm = (e) => {
        console.log(selectedFair);
        selectedFair ? updateFair(e) : createFair(e);
    };

    const createFair = async (e) => {
        e.preventDefault();
        FairApi.createFair(formData)
            .then((res) => {
                console.log(res);

                const upload_url = res.upload_url;

                MediaApi.uploadMedia(upload_url, image)
                    .then((res) => {
                        alert("Successfully created the fair");
                        closeModal();
                    })
                    .catch((err) => {
                        alert("Error on media upload: " + err);
                        alert("Fair created without image");
                    });
            })
            .catch((err) => {
                alert("Error on fair creation: " + err);
            });
    };

    const updateFair = async (e) => {
        e.preventDefault();
        // clearFormData();
        // toggleModal();
        // onSuccess();
    };

    return (
        <Modal
            show={isModalOpen}
            onHide={toggleModal}
            centered
            dialogClassName="modify-modal-main"
            animation={false}
        >
            <ModalHeader
                className="btn-wrapper"
                style={{ display: "flex", alignItems: "center" }}
            >
                {selectedFair ? "Modify Fair" : "Add Fair"}
                <Button
                    id="close-btn"
                    className="close-btn"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    X
                </Button>
            </ModalHeader>
            <ModalBody>
                <Container className="modify-modal-main-container">
                    <Form onSubmit={(e) => submitForm(e)}>
                        <Panel collapsible={false}>
                            <Row className={""}>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="normal"
                                            value={formData?.name}
                                            placeholder="Enter fair name"
                                            onChange={(e) => {
                                                handleFormData({
                                                    name: "name",
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Fair Firm</Form.Label>
                                        <Form.Control
                                            value={formData?.firm}
                                            name="firm"
                                            type="normal"
                                            placeholder="Enter firm name"
                                            onChange={(e) => {
                                                handleFormData({
                                                    name: e.target.name,
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            name="email_address"
                                            type="email"
                                            value={formData?.email_address}
                                            placeholder="Enter email address"
                                            onChange={(e) => {
                                                handleFormData({
                                                    name: e.target.name,
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Country</Form.Label>
                                        <CountrySelector
                                            name="country"
                                            defaultValue={
                                                formData.country
                                                    ? {
                                                          value: countryList().getValue(
                                                              formData.country
                                                          ),
                                                          label: formData.country,
                                                      }
                                                    : ""
                                            }
                                            handleCountry={(e) => {
                                                handleFormData({
                                                    name: "country",
                                                    value: e,
                                                });
                                            }}
                                        ></CountrySelector>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control
                                            name="start_date"
                                            type="date"
                                            value={formData?.start_date}
                                            placeholder="Enter date"
                                            required
                                            onChange={(e) => {
                                                handleFormData({
                                                    name: e.target.name,
                                                    value: StringHelper.formatISODate(
                                                        e.target.value,
                                                        "date",
                                                        "-"
                                                    ),
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control
                                            name="end_date"
                                            type="date"
                                            value={formData?.end_date}
                                            placeholder="Enter date"
                                            onChange={(e) => {
                                                handleFormData({
                                                    name: e.target.name,
                                                    value: StringHelper.formatISODate(
                                                        e.target.value,
                                                        "date",
                                                        "-"
                                                    ),
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>About</Form.Label>
                                        <Form.Control
                                            value={formData?.about}
                                            name="about"
                                            type="normal"
                                            placeholder="Enter what is the fair is about"
                                            onChange={(e) => {
                                                handleFormData({
                                                    name: e.target.name,
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>URL</Form.Label>
                                        <Form.Control
                                            value={formData?.url}
                                            name="url"
                                            type="normal"
                                            placeholder="Enter the fair website url"
                                            onChange={(e) => {
                                                handleFormData({
                                                    name: e.target.name,
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    paddingBlock: "1rem",
                                    marginBlock: "1rem",
                                    borderTop: "2px solid var(--fixed-primary)",
                                    width: "100%",
                                }}
                            >
                                <Col md={6} xs={12}>
                                    <Row>
                                        <Col xs={12} style={{ padding: 0 }}>
                                            <Form.Group>
                                                <Form.Label>
                                                    Card Content
                                                </Form.Label>
                                                <Form.Control
                                                    name="card_context"
                                                    type="normal"
                                                    as={"textarea"}
                                                    rows={3}
                                                    value={
                                                        formData.card_context
                                                    }
                                                    placeholder="Enter card content"
                                                    onChange={(e) => {
                                                        handleFormData({
                                                            name: e.target.name,
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} style={{ padding: 0 }}>
                                            <Form.Group>
                                                <Form.Label>Content</Form.Label>
                                                <Form.Control
                                                    name="content"
                                                    type="normal"
                                                    as={"textarea"}
                                                    rows={3}
                                                    value={formData?.content}
                                                    placeholder="Enter post content"
                                                    onChange={(e) => {
                                                        handleFormData({
                                                            name: e.target.name,
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6} xs={12}>
                                    <ImageUploadCol>
                                        <Row>
                                            <Col
                                                xs={12}
                                                style={{ padding: "1rem" }}
                                            >
                                                {imageURL ? (
                                                    <img
                                                        className="img-upload"
                                                        src={imageURL}
                                                        alt="file-add"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faFileUpload}
                                                        size="7x"
                                                        color="var(--fixed-color-dark)"
                                                    />
                                                )}
                                            </Col>
                                            <Col
                                                xs={12}
                                                style={{ textAlign: "center" }}
                                            >
                                                {/* <ImageUpload
                                                    callback={onImageUpload}
                                                ></ImageUpload> */}

                                                <Form.Group>
                                                    <Form.Control
                                                        name="image"
                                                        type="file"
                                                        placeholder="Upload your image"
                                                        onChange={(e) => {
                                                            setImage(
                                                                e.target
                                                                    .files[0]
                                                            );

                                                            handleImageChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </ImageUploadCol>
                                </Col>
                            </Row>
                        </Panel>
                        <Row
                            style={{
                                justifyContent: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <Button id="admin-submit-button" type="submit">
                                Add/Modify
                            </Button>
                        </Row>
                    </Form>
                </Container>
            </ModalBody>
        </Modal>
    );
}
