import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
    faArrowRight,
    faSearch,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";

import createLink from "../../helpers/CreateLink";
import "../component-css/SearchBar.css";
import { useHistory } from "react-router-dom";
export default function Searchbar({ isOpen, setIsOpen, queryStringHandler }) {
    const [searchText, setSearchText] = useState();
    let searchBoxRef = useRef();
    const [queryString, setQueryString] = useState("");

    let history = useHistory();

    const redirectToSearch = () => {
        let route = createLink("/search/", searchBoxRef.current.value);
        history.push(route);
    };

    return (
        <Col>
            <form
                className="input-box hero"
                onSubmit={() => {
                    redirectToSearch();
                }}
            >
                <input
                    placeholder="Search"
                    className="search-comp search-input"
                    ref={searchBoxRef}
                ></input>
                <Button className="search-btn search-comp" type="submit">
                    <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </Button>
            </form>
        </Col>
    );
}
