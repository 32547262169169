import { Container, Row, Col } from "react-bootstrap";
import "../../components/component-css/Hero.css";
import parent_categories from "../../constants/Parents";
import { Link } from "react-router-dom";

import SearchBar from "../Search/SearchBar";
export default function Hero() {
    const categories = () => {
        return parent_categories.map(function (item, ind) {
            return (
                <Col
                    key={"main-category-" + ind}
                    className="col-xl-4 col-md-4 icon-box"
                    style={{ display: "inherit" }}
                    as={Link}
                    to={"/main-categories/" + item.value.toLowerCase()}
                >
                    <h3>{item.label}</h3>
                </Col>
            );
        });
    };
    return (
        <div id="hero-main">
            <Container
                fluid
                id="hero"
                className="d-flex align-items-center justify-content-center"
            >
                <Row
                    className="main-hero-row"
                    data-aos="fade-up"
                    data-aos-delay="150"
                >
                    <Col className="col-xl-4 col-lg-4 search-col">
                        <span>
                            <h2>Start searching for your needs</h2>
                            <SearchBar></SearchBar>
                        </span>
                    </Col>
                    <Col className="col-xl-8 col-lg-8 header-col">
                        <span>
                            <h1>
                                Welcome to Foodversy
                                <br /> Your Import and Export Partner
                                <span>.</span>
                            </h1>

                            <h2>Discover the World of Global Trade.</h2>
                            <br />
                            <p>
                                We are a group of digital marketeers who focuses
                                on creating a reliable supply for all the
                                exporters around the world
                                <br />
                                Check out our product categories or use the
                                search engine to find what you are looking for
                            </p>
                            <Row
                                style={{
                                    justifyContent: "center",
                                    paddingBlock: "1rem",
                                }}
                            >
                                {categories()}
                            </Row>
                        </span>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
