export default function createLink(route, e) {
    let str = e;
    str = str.replace(/\s+/g, "-").toLowerCase();
    return route + str;
}

export function clearTitle(title) {
    const invalidCharsRegex = /[^a-zA-Z0-9\-_./ ]/g;
    title = title.replace(invalidCharsRegex, "").toLowerCase();
    return title;
}
