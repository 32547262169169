import React, { useEffect, useState } from "react";

import "../component-css/Home.css";

import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Aos from "aos";
import SearchApi from "../../api/searchApi";
import CreateNewsCards from "../Cards/CreateNewsCards";
import Header from "../SubComponents/Header";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";

function SearchLandingNews({
    queryString,
    selectedNewsHandler,
    positiveTitle = "",
    negativeTitle = "",
}) {
    //states
    const [news, setNews] = useState([]);
    const params = useParams();

    //useEffects

    useEffect(() => {
        if (queryString === undefined)
            SearchApi.searchNews(params.query).then((res) => {
                setNews(res.data ? res.data : []);
            });
        else
            SearchApi.searchNews(queryString).then((res) => {
                setNews(res.data ? res.data : []);
            });
    }, [queryString]);

    const selectNews = (e) => {
        selectedNewsHandler(e);
    };

    const createLink = (e) => {
        var str = e;
        str = str.replace(/\s+/g, "-").toLowerCase();
        return "/post/" + str;
    };

    if (news.length > 0) {
        {
            console.log("asdljkasd");
        }
        return (
            <div>
                <Header header={positiveTitle} icon={faCannabis}></Header>
                <CreateNewsCards allNews={news} />
            </div>
        );
    } else {
        return (
            <div>
                <Header header={negativeTitle}></Header>
                <CreateNewsCards allNews={news} />
            </div>
        );
    }
}
export default SearchLandingNews;
