import React, { useState } from "react";
import ProductApi from "../api/productApi";
import CategoryApi from "../api/categoryApi";
import { useEffect } from "react";

/**
 * Fetches products by categoryName if it is provided,
 * Otherwise fetches all products
 * @param {*} categoryName
 * @returns
 */
export default function useProducts(categoryName) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const handleProducts = (e) => {
            setProducts(e);
        };

        if (categoryName === undefined || categoryName === "") {
            ProductApi.getProducts()
                .then((res) => {
                    handleProducts(res.data);
                })
                .catch((err) => {
                    console.log(
                        "An error occurred during fetching products: ",
                        err
                    );
                });
        } else {
            CategoryApi.getProductByCategory(categoryName)
                .then((res) => {
                    handleProducts(res.data);
                })
                .catch((err) => {
                    console.log(
                        "An error occurred during fetching products: ",
                        err
                    );
                });
        }
    }, [categoryName]);

    return products;
}
