import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
    faArrowRight,
    faSearch,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";

import createLink from "../../helpers/CreateLink";
import "../component-css/SearchBar.css";
import { useHistory } from "react-router-dom";
export default function SearchPage({ isOpen, setIsOpen, queryStringHandler }) {
    const [searchText, setSearchText] = useState();
    const [status, setStatus] = useState("closed");
    let searchBoxRef = useRef();
    const [queryString, setQueryString] = useState("");

    let history = useHistory();
    //useEffects
    useEffect(() => {
        if (isOpen) {
            setStatus("open");
        } else setStatus("closed");
    }, [isOpen]);

    const redirectToSearch = () => {
        let route = createLink("/search/", searchBoxRef.current.value);
        history.push(route);
        setStatus("closed");
        setIsOpen(false);
    };
    return (
        <Container fluid className={"searchbar-main-container " + status}>
            <Button
                className="close-btn"
                onClick={() => {
                    setStatus("closed");
                    setIsOpen(false);
                }}
            >
                <FontAwesomeIcon icon={faTimes} size="2x"></FontAwesomeIcon>
            </Button>

            <Row style={{ flexDirection: "column" }}>
                <Col style={{ textAlign: "center" }}>
                    <h2 className="title">
                        Every product that you are interested in is here
                    </h2>
                    <br />
                    <h3 style={{ color: "grey" }}>
                        {" "}
                        Start searching for what you need
                    </h3>
                </Col>
                <Col>
                    <form
                        className="input-box"
                        onSubmit={() => {
                            redirectToSearch();
                        }}
                    >
                        <input
                            placeholder="Search"
                            className="search-comp search-input"
                            ref={searchBoxRef}
                        ></input>
                        <Button
                            className="search-btn search-comp"
                            type="submit"
                        >
                            <FontAwesomeIcon icon={faArrowRight} size="2x" />
                        </Button>
                    </form>
                </Col>
            </Row>
        </Container>
    );
}
