import { faCannabis } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import ProductCard from "./Cards/CreateProductCards";
import "./component-css/Product.css";
import Header from "./SubComponents/Header";

export default function ProductCards({
    header,
    subHeader,
    productList,
    maxCardInRow,
}) {
    useEffect(() => {}, [productList]);

    //Dynamically generates the product cards with a given productList
    //Product list can be an object or string array
    const generateCards = (cardItems) => {
        if (Array.isArray(cardItems) && cardItems.length > 0) {
            if (typeof cardItems[0] === "string") {
                return cardItems.map(function (item, ind) {
                    return (
                        <ProductCard
                            key={"product-" + ind}
                            productName={item}
                            maxCardInRow={maxCardInRow}
                        ></ProductCard>
                    );
                });
            } else
                return cardItems.map(function (item, ind) {
                    return (
                        <ProductCard
                            key={"product-" + ind}
                            productObject={item}
                            maxCardInRow={maxCardInRow}
                        ></ProductCard>
                    );
                });
        }
    };

    if (productList !== undefined && productList.length > 0)
        return (
            <section id="services" className="services">
                <div className="container" style={{ margin: "auto" }}>
                    <div className="row" style={{ justifyContent: "center" }}>
                        <div className="col-12">
                            <Header
                                header={header}
                                subheader={subHeader}
                                icon={faCannabis}
                            ></Header>
                        </div>

                        {generateCards(productList)}
                    </div>
                </div>
            </section>
        );
    else {
        return <div></div>;
    }
}
