const theme = {
    background: {
        default: "white",
        primary: "var(--fixed-primary)",
        primarySoft: "var(--fixed-secondary)",
        primaryLight: "var(--primary-color-light)",
        primaryDark: "#BDDEEC",
    },
    text: {
        primary: "white",
        secondary: "black",
    },
    context: {
        background: "var(--fixed-primary)",
    },
    divider: {
        primary: "gray",
    },
};

export const defaultTheme = {
    table: {
        style: {},
    },
    tableWrapper: {
        style: {
            display: "table",
        },
    },
    responsiveWrapper: {
        style: {},
    },
    header: {
        style: {
            fontSize: "22px",
            color: theme.text.primary,
            backgroundColor: theme.background.primary,
            minHeight: "56px",
            paddingLeft: "16px",
            paddingRight: "8px",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
        },
    },
    subHeader: {
        style: {
            backgroundColor: theme.background.primary,
            minHeight: "52px",
        },
    },
    head: {
        style: {
            borderRadius: "0",
            color: theme.text.primary,
            fontSize: "12px",
            fontWeight: 500,
        },
    },
    headRow: {
        style: {
            borderRadius: "0",
            backgroundColor: theme.background.primary,
            minHeight: "52px",
            borderBottomWidth: "1px",
            borderBottomColor: theme.divider.primary,
            borderBottomStyle: "solid",
        },
        denseStyle: {
            minHeight: "32px",
        },
    },
    headCells: {
        style: {
            borderRadius: "0",
            paddingLeft: "16px",
            paddingRight: "16px",
            "&:hover": {
                color: theme.text.primary,
                backgroundColor: theme.background.primarySoft,
                transitionDuration: "0.25s",
                transitionProperty: "background-color",
                cursor: "move",
            },
        },
        draggingStyle: {
            transitionDuration: "0.5s",
            transitionProperty: "background-color",
            cursor: "move",
        },
    },
    contextMenu: {
        style: {
            borderRadius: "0",
            backgroundColor: theme.context.background,
            fontSize: "18px",
            fontWeight: 400,
            color: theme.context.text,
            paddingLeft: "16px",
            paddingRight: "8px",
            transform: "translate3d(0, -100%, 0)",
            transitionDuration: "125ms",
            transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
            willChange: "transform",
        },
        activeStyle: {
            transform: "translate3d(0, 0, 0)",
        },
    },
    cells: {
        style: {
            paddingLeft: "16px",
            paddingRight: "16px",
            wordBreak: "break-word",
        },
        draggingStyle: {},
    },
    rows: {
        style: {},

        denseStyle: {
            minHeight: "32px",
        },
        selectedHighlightStyle: {
            // use nth-of-type(n) to override other nth selectors
            "&:nth-of-type(n)": {
                color: "white",
                backgroundColor: theme.background.primarySoft,
                borderBottomColor: theme.text.primary,
            },
        },
        highlightOnHoverStyle: {
            "&:nth-of-type(n)": {
                color: theme.text.primary,
                backgroundColor: theme.background.primarySoft,
                transitionDuration: "0.25s",
                transitionProperty: "background-color",
                outlineStyle: "solid",
                outlineWidth: "1px",
            },
        },
        stripedStyle: {
            color: "red",
            backgroundColor: "red",
        },
    },
    pagination: {
        style: {
            color: theme.text.secondary,
            fontSize: "13px",
            minHeight: "56px",
            backgroundColor: theme.background.default,
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: theme.divider.primary,
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
        },
        pageButtonsStyle: {
            borderRadius: "50%",
            height: "40px",
            width: "40px",
            padding: "8px",
            margin: "px",
            cursor: "pointer",
            transition: "0.4s",
            color: theme.background.primarySoft,
            fill: theme.background.primarySoft,
            backgroundColor: "transparent",

            "&:hover:not(:disabled)": {
                backgroundColor: theme.background.primary,
                color: theme.text.primary,
                fill: theme.text.primary,
            },
            "&:focus": {
                outline: "none",
                backgroundColor: theme.background.primaryDark,
            },
        },
    },
};
