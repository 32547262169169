import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function useFormData(initialValue) {
    const [formData, setFormData] = useState(initialValue);

    useEffect(() => {
        initialValue && typeof initialValue === "object"
            ? setFormData(initialValue)
            : setFormData({});
    }, [initialValue]);

    //handlers
    /**
     * send a value object to add to filter data
     * @param {*} newValue
     */
    const handleFormData = useCallback((newValue) => {
        setFormData((formData) => ({
            ...formData,
            [newValue.name]: newValue.value,
        }));
    }, []);

    return { formData, setFormData, handleFormData };
}
