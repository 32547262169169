import React, { useState, useEffect } from "react";
import NewsApi from "../../api/newsApi";
import CreateNewsCards from "../Cards/CreateNewsCards";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../SubComponents/Header";
export default function HomeNews({ title, subheader }) {
    const [news, setNews] = useState();

    //useEffect
    useEffect(() => {
        //get latest 3 news
        NewsApi.getNewsCarousel()
            .then((res) => {
                setNews(res.data);
            })
            .catch((err) => {
                console.log("Error: " + err);
            });
    }, []);
    if (news !== undefined)
        return (
            <div>
                <Header
                    header="News"
                    subheader="It is very difficult to trade in ever-changing world conditions.
            Following the recent news is essential. Here is the
            latest  news we have chosen for you."
                    icon={faCannabis}
                ></Header>

                <CreateNewsCards
                    allNews={[...news].reverse()}
                ></CreateNewsCards>
            </div>
        );
    else return <div></div>;
}
