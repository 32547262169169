import React,{useState} from 'react'
import { useEffect } from 'react'
import CategoryApi from '../api/categoryApi'

export default function useCategories(){
    const [categories,setCategories] = useState([])

    useEffect(()=>{
        const handleCategories = (e) => {
            setCategories(e);
        }
        CategoryApi.getCategories()
        .then(res => {handleCategories(res.data)})
        .catch(err => {console.log("An error occured during fetching the categories ",err)})
    
    },[])
  

    return categories
}