const StringHelper = {
    /*Clears unnecessary whitespace
    @params : str -> string
*/
    clearExtraWhitespace(str) {
        return str.replace(/\s+/g, " ").trim();
    },

    /*Takes a string and the index of the character that will be lowercase
      params : s -> string
               index -> index of char
  */
    lowerCharacter(s, index) {
        if (index === s.length - 1) {
            s = s.substring(0, index) + s[index].toLowerCase();
        } else if (index <= s.length)
            s =
                s.substring(0, index) +
                s[index].toLowerCase() +
                s.substring(index + 1);
        else if (index === 0)
            s = s[index].toLowerCase() + s.substring(index + 1);
        return s;
    },

    /*Takes a string and the index of the character that will be uppercase
      params : s -> string
               index -> index of char
  */
    upperCharacter(s, index) {
        if (index === s.length - 1) {
            s = s.substring(0, index) + s[index].toUpperCase();
        } else if (index <= s.length)
            s =
                s.substring(0, index) +
                s[index].toUpperCase() +
                s.substring(index + 1);
        else if (index === 0)
            s = s[index].toUpperCase() + s.substring(index + 1);
        return s;
    },

    /*Makes every word's first character capitalized in a given string
      params : s -> string
               index -> index of char
    */
    capitalizeEveryWord(s) {
        let temp = s.split(" ");
        let newWord = "";
        for (var i = 0; i < temp.length; i++) {
            var word = temp[i];
            newWord = newWord + " " + this.upperCharacter(word, 0);
        }
        // to get rid of the first space
        return newWord.substring(1, newWord.length);
    },
    generateKeywordsFromTags(tags) {
        let keywords = "";
        if (Array.isArray(tags) && tags.length > 0)
            tags.forEach((tag) => {
                keywords = keywords + this.upperCharacter(tag, 0) + ", ";
            });
        return keywords.trimEnd(" ");
    },
    formatISODateReverse(isoDate, type = "date", divider = "/") {
        //first crop out the T and Z
        let tempDate = isoDate
            .replace(/T/, " ") // replace T with a space
            .replace(/\..+/, ""); // delete the dot and everything after
        //replace - with /
        tempDate = tempDate.replace("-", divider);

        //slice the time portion
        let tempTime = tempDate.substring(11, 16);

        //then format the date to DD/MM/YYYY
        tempDate =
            tempDate.substring(8, 10) +
            divider +
            tempDate.substring(5, 7) +
            divider +
            tempDate.substring(0, 4);

        // if type is defined return whether the date or datetime
        // if not, return date only
        return type
            ? type === "date"
                ? tempDate
                : tempDate + " " + tempTime
            : tempDate;
    },
    formatISODate(isoDate, type = "date", divider = "/") {
        //first crop out the T and Z
        let tempDate = isoDate
            .replace(/T/, " ") // replace T with a space
            .replace(/\..+/, ""); // delete the dot and everything after
        //replace - with /
        tempDate = tempDate.replace("-", divider);

        //slice the time portion
        let tempTime = tempDate.substring(11, 16);

        //then format the date to DD/MM/YYYY
        tempDate =
            tempDate.substring(0, 4) +
            divider +
            tempDate.substring(5, 7) +
            divider +
            tempDate.substring(8, 10);

        // if type is defined return whether the date or datetime
        // if not, return date only
        return type
            ? type === "date"
                ? tempDate
                : tempDate + " " + tempTime
            : tempDate;
    },
    createLink(e) {
        try {
            var str = e;
            str = str.replace(/\s+/g, "-").toLowerCase();
            return str.trim();
        } catch (a) {
            console.log(e);
        }
    },
};

export default StringHelper;
