import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../component-css/Header.css";

import { faCannabis } from "@fortawesome/free-solid-svg-icons";

export default function Header({
    header,
    subheader,
    icon,
    className = "",
    type = "h2",
}) {
    return (
        <div
            className={"header-section-wrap header-text-center " + className}
            style={{
                textAlign: "center",
                marginRight: "15px",
                marginLeft: "15px",
            }}
        >
            <span>
                <i>
                    {icon ? (
                        <FontAwesomeIcon
                            icon={header && header !== "" ? icon : faCannabis}
                        />
                    ) : (
                        <></>
                    )}
                </i>
            </span>
            {type === "h2" ? (
                <>
                    <h2 className="header-section-title">{header}</h2>
                    <h3 className="header-section-subtitle">{subheader}</h3>
                </>
            ) : (
                <>
                    <h1 className="header-section-title">{header}</h1>
                    <h3 className="header-section-subtitle">{subheader}</h3>
                </>
            )}
        </div>
    );
}
