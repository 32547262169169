import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Modal from "react-modal";
import "./DetailModal.css";
export default function DetailModal({
  user,
  handleSelectedUser,
  isModalOpen,
  handleIsModalOpen,
}) {
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    handleIsOpen(isModalOpen);
  }, [isModalOpen]);

  const handleIsOpen = (e) => {
    setIsOpen(e);
    handleIsModalOpen(e);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: "0px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      zIndex: "3",
    },
  };

  useEffect(() => {}, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        handleIsModalOpen(false);
      }}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Container>
        <Row>
          <Col
            xl={12}
            lg={12}
            style={{
              textAlign: "right",
              backgroundColor: "var(--fixed-primary)",
              padding: "0.5rem",
            }}
          >
            <Button
              className="toolbar-button"
              onClick={() => {
                handleIsOpen(false);
              }}
            >
              X
            </Button>
          </Col>
        </Row>

        <Container style={{ padding: "20px" }} id="modal-main-container">
          <Row>
            <Col
              xl={12}
              lg={12}
              style={{ textAlign: "center", marginBottom: "7%" }}
            >
              <h3>{"Request Details"}</h3>
            </Col>
            <Col xl={6} lg={6} style={{ textAlign: "center" }}>
              <Row>
                <Col
                  xl={6}
                  lg={6}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  <p>User Email:</p>
                </Col>
                <Col xl={6} lg={6} style={{ textAlign: "left" }}>
                  <p>{user.email}</p>
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={6} style={{ textAlign: "center" }}>
              <Row>
                <Col
                  xl={6}
                  lg={6}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  <p>Product Name:</p>
                </Col>
                <Col xl={6} lg={6} style={{ textAlign: "left" }}>
                  <p>{user.product_name}</p>
                </Col>
              </Row>
            </Col>
            <Col xl={12} lg={12} style={{ textAlign: "center" }}>
              <Row>
                <Col
                  xl={3}
                  lg={3}
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  <p>Message:</p>
                </Col>
                <Col
                  xl={9}
                  lg={9}
                  style={{
                    textAlign: "justify",

                    paddingRight: "3rem",
                  }}
                >
                  <p>{user.message}</p>
                </Col>
              </Row>
            </Col>
            <Col style={{ marginTop: "7%" }}>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  style={{ justifyContent: "center", textAlign: "right" }}
                >
                  {/* React Chat Engine might be useful for opening a chat with customer */}
                  <Button className="modal-button modal-delete-btn">
                    Delete
                  </Button>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  style={{ justifyContent: "center", textAlign: "left" }}
                >
                  <Button className="modal-button modal-answer-btn">
                    Answer
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </Modal>
  );
}
