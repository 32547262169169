import React, { useEffect, useState, useRef } from "react";
import "../component-css/Request.css";

import { Form, Container, Col, Button } from "react-bootstrap";
import CategoryApi from "../../api/categoryApi";
import ProductApi from "../../api/productApi";
import Select from "react-select";
import RelatedProductSelector from "../SubComponents/RelatedProductSelection";
import "../component-css/Product.css";
import TagCreator from "./Subcomponents/TagCreator/TagCreator";
import { ProductStatus } from "../../constants/ProductStatus";
export default function ModifyItem() {
    //States
    const [checkedPopular, setCheckedPopular] = useState(false);
    const [products, setProducts] = useState();
    const [selectedProduct, setSelectedProduct] = useState({
        name: "",
        category: "",
        relatedProducts: [],
        tags: [],
    });
    const [selectedRelatedProduct, setSelectedRelatedProduct] = useState();
    const [relatedList, setRelatedList] = useState([]);
    const [categories, setCategories] = useState();
    const [imgList, setImgList] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({});

    const [widget, setWidget] = useState(
        window.cloudinary.createUploadWidget(
            {
                cloudName: "da8slr0ic",
                uploadPreset: "lsbcei52",
                folder: "foodsfromturkey",
                cropping: true,
            },
            (error, result) => {
                if (result.event === "success") {
                    alert("Image is added");
                    setImgList((imgList) => [...imgList, result.info.url]);
                }
            }
        )
    );

    //Refs
    const nameRef = useRef();
    const categoryRef = useRef();
    const tagRef = useRef();
    const relatedProductsRef = useRef();
    const descriptionRef = useRef();

    //useEffect
    useEffect(() => {
        CategoryApi.getCategories()
            .then((res) => setCategories(res.data))
            .catch((err) => console.log("Error: ", err));
        ProductApi.getProducts()
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => console.log("Error: ", err));
    }, []);

    useEffect(() => {
        fillAreas();
    }, [selectedProduct.name]);

    function selectedRelatedProductHandler(e) {
        setSelectedRelatedProduct(e);
    }

    //Image Widget
    const uploadImage = () => {
        widget.open();
    };

    const handleTags = (newTagList) => {
        setTags(newTagList);
    };

    function handleStatus(e) {
        setSelectedStatus(e);
    }

    const addToRelatedList = () => {
        if (selectedProduct && !relatedList.includes(selectedProduct.name))
            setRelatedList([...relatedList, selectedRelatedProduct.name]);
    };

    const removeFromRelatedList = (e) => {
        let filteredArray = relatedList.filter((item) => item !== e);
        setRelatedList(filteredArray);
    };

    //Maps categories to fill react-select component
    const categoriesToOptions = () => {
        if (categories === undefined)
            //if state does not mounted yet, does not continue
            return;
        let options = []; //array for react-select
        categories.map(function (category) {
            let lbl =
                category.name.charAt(0).toUpperCase() + category.name.slice(1);
            options = [...options, { value: category.name, label: lbl }];
        });
        return options;
    };

    const productsToOptions = () => {
        if (products === undefined)
            //if state did not mount yet, does not continue
            return;
        let options = []; //array for react-select
        products.map(function (product) {
            let lbl =
                product.name.charAt(0).toUpperCase() + product.name.slice(1);
            options = [...options, { value: product, label: lbl }];
        });
        return options;
    };
    const modifyProduct = () => {
        let img;

        if (imgList.length === 0) {
            img = selectedProduct.image_url;
        } else img = imgList;

        let data = {
            id: selectedProduct._id,
            parent_category: selectedProduct.parent_category,
            category: categoryRef.current.state.value.value,
            name: nameRef.current.value,
            tags: tags,
            related_products: relatedList,
            image_url: img,
            is_popular: checkedPopular,
            description: descriptionRef.current.value,
            status: selectedStatus.label,
        };

        console.log(data);
        //promise'lerde sıkıntı olduğu için id doğru olmasına rağmen bulamıyor
        //promise parametre yollarken {data:{}} seklinde yolluyor Akkurta sor
        ProductApi.updateProduct(data.id, data)
            .then(async (res) => {
                if (res !== null) {
                    await alert("Item is modified");
                    window.location.reload();
                }
            })
            .catch((err) => console.log("Error: ", err));
    };

    //Fills the input fields with the data of the selected product using selectedProduct state
    const fillAreas = () => {
        if (selectedProduct.name === "") return;

        // assign values of refs
        nameRef.current.value = selectedProduct.name;
        let optionList = categoriesToOptions();
        let selectIndex = optionList.findIndex(
            (e) => e.value === selectedProduct.category
        );

        if (selectIndex !== -1)
            //If category exist in database then choose that option on selectbox
            categoryRef.current.select.selectOption(optionList[selectIndex]);
        descriptionRef.current.value = selectedProduct.description || "";
        setCheckedPopular(selectedProduct.is_popular);
        setRelatedList(selectedProduct.related_products);
        handleTags(selectedProduct.tags);

        console.log(
            ProductStatus.find((x) => x.label === selectedProduct.status)
        );
        setSelectedStatus({
            value: selectedProduct.status
                ? ProductStatus.find((x) => x.label === selectedProduct.status)
                      .value
                : 1,
            label: selectedProduct.status ? selectedProduct.status : "Organic",
        });
    };

    const createLabels = () => {
        return (
            <Form.Row id="tags">
                {relatedList.map(function (e) {
                    return (
                        <Button
                            onClick={() => {
                                removeFromRelatedList(e);
                            }}
                            id="tag"
                        >
                            {e}
                        </Button>
                    );
                })}
            </Form.Row>
        );
    };
    return (
        <Container>
            <Form className="req-form">
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridProductsSelect">
                        <Form.Label>Name Select</Form.Label>
                        <Select
                            options={productsToOptions()}
                            onChange={(e) => {
                                setSelectedProduct(e.value);
                            }}
                        />
                    </Form.Group>
                </Form.Row>

                <h1> Properties to Change </h1>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridProducts">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            placeholder="Product Name"
                            ref={nameRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridCategories">
                        <Form.Label>Category</Form.Label>
                        <Select
                            options={categoriesToOptions()}
                            ref={categoryRef}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridStatus">
                        <Form.Label>Status</Form.Label>
                        <Select
                            options={ProductStatus}
                            // defaultValue={ProductStatus[0]}
                            value={selectedStatus}
                            onChange={(e) => {
                                handleStatus(e);
                            }}
                        ></Select>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridRelatedProducts">
                        <Form.Label>Related Products</Form.Label>
                        <RelatedProductSelector
                            products={products}
                            selectedProductHandler={
                                selectedRelatedProductHandler
                            }
                            relatedProductsRef={relatedProductsRef}
                        ></RelatedProductSelector>
                        <Button onClick={() => addToRelatedList()}>Add</Button>
                        {/* <Form.Control  value = {selectedProduct.relatedProducts} placeholder="Please do not leave space after comma" ref = {relatedProductsRef} /> */}
                    </Form.Group>
                </Form.Row>

                {createLabels()}

                <Form.Row>
                    <TagCreator
                        handleTags={handleTags}
                        existingTags={tags}
                    ></TagCreator>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} controlId="formGridImgWidget">
                        <Button onClick={uploadImage}> Upload Image</Button>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Please enter short description"
                            ref={descriptionRef}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Check
                        type="checkbox"
                        label="Is Popular"
                        checked={checkedPopular}
                        value="1"
                        onChange={(e) =>
                            setCheckedPopular(e.currentTarget.checked)
                        }
                    />
                </Form.Row>
                <Button id="admin-submit-button" onClick={modifyProduct}>
                    Modify Item
                </Button>
            </Form>
        </Container>
    );
}
